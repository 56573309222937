import { IconButton } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import { VerbaleDTO } from "../../types/verbale";
import { download } from "../../utils/common";
import ClearCard from "../Card/ClearCard";

export const VerbaleDetailsView = (props: { all: boolean, verbale: VerbaleDTO, setDetailsViewVerbale: (value: VerbaleDTO | undefined) => void }) => {
  return (
    <>
    {props.all? (
    <div className="sm:w-[400px] top-1/3 text-right m-auto">
      <IconButton onClick={() => props.setDetailsViewVerbale(undefined)}>
        <ClearIcon className='text-tecnologia-default' />
      </IconButton>
      <ClearCard title={`Dettagli Verbale`}>
        <div className="flex justify-center">
          <div className="text-lg py-2 pr-2 font-bold text-gray-800">Data:</div>
          <div className="text-lg py-2 text-tecnologia-default">
            {new Date(props.verbale.date).toLocaleDateString()}
          </div>
        </div>
        <div className="flex justify-center">
          <div className="text-lg py-2 pr-2 font-bold text-gray-800">Nome documento:</div>
          <div className="text-lg py-2 text-tecnologia-default underline cursor-pointer" onClick={() => { download(props.verbale.document, props.verbale.name) }}>
            {props.verbale.name}
          </div>
        </div>
        <div className="px-4 pb-4 justify-center">
          <div className="text-lg font-bold text-gray-800">Task associate:</div>
          <div className="h-[150px] rounded-md bg-slate-200 text-lg text-tecnologia-default overflow-auto hide-scrollbar">
            {props.verbale.tasks.map((task, index) => {
              return (
                <div key={index} className="text-tecnologia-default font-bold">
                  {task.name}
                </div>
              );
            })}
          </div>
        </div>
      </ClearCard>
    </div >
    ) : (

      <div className="sm:w-[400px] top-1/3 text-right m-auto">
        <IconButton onClick={() => props.setDetailsViewVerbale(undefined)}>
          <ClearIcon className='text-tecnologia-default' />
        </IconButton>
        <ClearCard title={`Tasks associate:`}>
          <div className="flex justify-center">
            <div className="h-[150px] rounded-md text-lg text-tecnologia-default overflow-auto hide-scrollbar">
              {props.verbale.tasks.map((task, index) => {
                return (
                  <div key={index} className="text-tecnologia-default font-bold">
                    {task.name}
                  </div>
                );
              })}
            </div>
          </div>
        </ClearCard>
      </div >
    )}
    </>
  );
};
