import React, { useState } from "react";
import axios, { AxiosInstance } from "axios";
import Snackbar from "@mui/material/Snackbar";
import Alert, { AlertColor } from "@mui/material/Alert";
import { refresh } from "./auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PATHS } from "../utils/paths";

export const baseURL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:3000";

const instance: AxiosInstance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const AxiosComponent = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity]: [AlertColor, Function] = useState("info");

  instance.interceptors.response.use(
    (res) => {
      // If no data is available on the response throw an error and show the connection error message
      if (
        res.headers&&
        res.headers["content-type"]&&
        res.headers["content-type"]?.indexOf("text/html") &&
        res.headers["content-type"]?.indexOf("text/html") !== -1
      ) {
        setOpen(true);
        setMessage("Errore di connessione");
        setSeverity("error");
        //throw new Error();
      }
      if (
        res.data&&
        res.data?.message
        ) {
        setOpen(true);
        setSeverity("success");
        setMessage(res.data?.message);
      }
      return res;
    },
    (err) => {
      const prevRequest = err?.config;
      if (err?.response?.status === 401 && !prevRequest?.sent) {
        const ref =
          localStorage.getItem("refresh") || searchParams.get("jwt") || "";
        if (ref) {
          return refresh(ref)
            .catch(() => {
              // In case of errors in the refresh clear the tokens and reload the app
              localStorage.removeItem("refresh");
              localStorage.removeItem("token");
              window.location.reload();
            })
            .then((res: any) => {
              
              if (!res.data || !res.data.authorization) {
                // In case of errors in the refresh clear the tokens and reload the app
                localStorage.removeItem("refresh");
                localStorage.removeItem("token");
                window.location.reload();
              }
              
              instance.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${res.data.authorization}`;
              localStorage.setItem("token", res.data.authorization);
              localStorage.setItem("refresh", res.data.refresh);
              return instance.request({
                ...prevRequest,
                headers: {
                  ...prevRequest.headers,
                  Authorization: `Bearer ${res.data.authorization}`,
                },
                sent: true,
              });
            });
        } else {
          navigate(PATHS.login);
        }
      } else {
        setOpen(true);
        const msg = err?.response?.data.message || "Errore di connessione";
        setMessage(msg);
        if (err?.response?.status.toString().startsWith("4")) {
          setSeverity("warning");
          setOpen(true);
        } else {
          setSeverity("error");
          setOpen(true);

        }
      }
      return Promise.resolve(err);
    }
  );
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={2000}
      onClose={() => setOpen(false)}
    >
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  );
};

export default instance;
