import { useMediaQuery } from "@mui/material";
import { useState } from "react";
import { deleteVerbale } from "../../models/verbale";
import { VerbaleDTO } from "../../types/verbale";
import { VerbaleCard } from "./VerbaleCard";
import { VerbaleDetailsView } from "./VerbaleDetailsView";


export const VerbaliList = (props: {
  verbali: VerbaleDTO[];
  assignId?: string;
  setVerbali: (value: VerbaleDTO[]) => void;
  detailsViewVerbale: VerbaleDTO | undefined;
  setDetailsViewVerbale: (value: VerbaleDTO | undefined) => void;
  openEdit: Function;
  editDate: Function;
  editIdVerbale: Function;
  edit: boolean;
}) => {
  const handleDelete = async (verbaleId: string) => {
    try {
      await deleteVerbale(verbaleId);
    } catch (e) {
      return;
    }

    const newVerbali = props.verbali.filter((e) => e.id !== verbaleId);
    props.setVerbali(newVerbali);
  };

  const handleEdit = async (date: Date, id: string) => {
    props.openEdit(true);
    props.editDate(date);
    props.editIdVerbale(id);
  };

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const mediaQuery2: boolean = useMediaQuery('(max-width: 728px)')

  return (
    <>
      {
        !props.detailsViewVerbale && <div className="px-4 rounded-3xl mb-4 w-[60%] m-auto">

        <div className="flex w-full">
          <div className="pl-[10px] text-lgpr-2 font-bold text-start w-[110px] text-edilizia-default">
            Data
          </div>
            {!mediaQuery2 && <div className="pl-[10px] justify-start w-[110px] truncate text-lg font-bold text-start pr-2 text-edilizia-default">
            Nome
          </div>}
            {!mediaQuery2 && <div className="pl-[10px] flex-grow justify-start truncate text-lg font-bold text-start text-edilizia-default" >
            Tasks
          </div>}
        </div>        </div>
      }
      {!!props.detailsViewVerbale ? (
        <VerbaleDetailsView all={mediaQuery2} verbale={props.detailsViewVerbale} setDetailsViewVerbale={props.setDetailsViewVerbale} />
      ) : (
        <div className="p-4 h-[320px] overflow-scroll hide-scrollbar">

          {props.verbali.map((verbale, i) => (
            <VerbaleCard
              key={i}
              verbale={verbale}
              assignId={props.assignId}
              setVerbali={props.setVerbali}
              setDetailsViewVerbale={props.setDetailsViewVerbale}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              idVerbale={verbale.id}
              edit={props.edit}
            />
          ))}
        </div>
      )}
    </>
  );
};
