import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import AssignmentTable from "../../components/Assigment/AssignmentTableStudent";
import { listAssignmentsPerStudent } from "../../models/assignment";
import { AssignmentDTO } from "../../types/assignment";
import { Button, IconButton } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import { useNavigate } from 'react-router-dom';
import { PATHS } from "../../utils/paths";
import { authContext } from "../../context/auth";

const HomePageStudent = () => {
  const [assignments, setAssignments] = useState<AssignmentDTO[]>([]);
  const [assignment, setAssignment] = useState<AssignmentDTO>();
  const [assignmentOpen, setOpenAssignment] = useState<boolean>(false);


  async function getAssignments() {
    try {
      const ass: any = await listAssignmentsPerStudent();
      const assDTO: any[] = [];

      ass.data.forEach((e: any) => {
        assDTO.push({
          id: e.id,
          name: e.name,
          description: e.description,
          expiration: e.expiration,
          createdAt: e.createdAt,
          deliveredAt: e.deliveredAt,
          professorId : e.professor.id,
        });
      });
      console.log(ass)
      setAssignments(assDTO);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getAssignments();
  }, []);
  return (
    <>
      {!assignmentOpen && (
        <div>
          <div className="text-2xl font-bold text-tecnologia-default p-8 m-auto">
            LISTA ESERCITAZIONI
          </div>
          <AssignmentTable
            assignments={assignments}
            setOpenAssignment={setOpenAssignment}
            setSelectedAssignment={setAssignment}
          ></AssignmentTable>
        </div>
      )}

      {assignmentOpen && assignment && (
        <StudentAssignmentView assignment={assignment} setAddingAssignment={setOpenAssignment} />
      )}
    </>
  );
};

export default HomePageStudent;

type StudentAssignmentViewProps = {
  assignment: AssignmentDTO;
  setAddingAssignment: (value: boolean) => void;
};
const StudentAssignmentView = (props: StudentAssignmentViewProps) => {
  const [assignment] = useState<AssignmentDTO>(props.assignment);
  const { user } = useContext(authContext);


  const navigate = useNavigate();
  return (
    <div className="w-[100%] relative text-center">
      <div className="absolute right-0">
        <IconButton onClick={() => props.setAddingAssignment(false)}>
          <ClearIcon className="text-tecnologia-default" />
        </IconButton>
      </div>
      <div className="p-[40px]">
        <div className="mb-16">
          <div
            className={`text-edilizia-default font-bold text-center m-auto`}
            style={{
              width: "60%",
              fontSize: "30px",
              padding: "5px",
              outline: "2px solid transparent",
            }}
          >
            {assignment.name}
          </div>
        </div>
        <div>
          <label
            htmlFor="custeomTextArea"
            className="text-edilizia-default block"
          >
            Descrizione:
          </label>
          <div
            className="text-black outline-none bg-[rgba(0,0,0,0.05)] block m-auto text-left"
            style={{
              width: "80%",
              fontSize: "16px",
              borderRadius: "5px",
              padding: "10px",
              height: "200px",
              resize: "none",
            }}
          >
            {assignment.description}
          </div>
        </div>
        <div>
          <div className="text-edilizia-default font-bold text-2xl mt-[60px] mb-[10px]">
            Scadenza della consegna
          </div>
          <div className="text-tecnologia-default font-bold text-2xl">
            {!!assignment.expiration ? dayjs(assignment.expiration).format("DD/MM/YYYY") : "Nessuna scadenza"}
          </div>
        </div>
      </div>
      <div className="text-center p-4 mt-[50px]">
        <Button
          className="mx-2 bg-gradient-to-r from-accademia-default via-tecnologia-default to-edilizia-default text-white font-bold disabled:from-accademia-disabled disabled:via-tecnologia-disabled disabled:to-edilizia-disabled"
          size="medium"
          color="info"
          variant="contained"
          onClick={() => {
            const pathRole = "/" + user?.role;
            navigate(pathRole + PATHS.viewProfAssignment + "/" + assignment.id + "/" + assignment.professorId)
          }
          }
        >
          Vedi l'esercitazione del professore
        </Button>
      </div>
      <div className="text-center p-4 mt-[50px]">
        <Button
          className="mx-2 bg-gradient-to-r from-accademia-default via-tecnologia-default to-edilizia-default text-white font-bold disabled:from-accademia-disabled disabled:via-tecnologia-disabled disabled:to-edilizia-disabled"
          size="medium"
          color="info"
          variant="contained"
          onClick={() => {
            const pathRole = "/" + user?.role;
            navigate(pathRole + PATHS.makeAssignment + "/" + assignment.id)
          }
          }
        >
          Svolgi l'esercitazione
        </Button>
      </div>
    </div>
  );
};
