import { UserUpdateDTO } from "../types/user";
import api from "./api";

export const listProfessors = (
    page = 1,
    terminated = false,
    disabled = false,
    q = ''
) => {
    return api.get('/professor', { params: { page, terminated, disabled, q } });
};

export const getProfessor = (id: string | string[] | undefined) => {
    return api.get(`/professor/${id}`);
}

export const updateProfessor = ({
    id,
    updatedProfessor,
  }: {
    id: string;
    updatedProfessor: Partial<UserUpdateDTO>;
  }) => {
    return api.put(`/professor/${id}`, updatedProfessor);
  };

export const createProfessor = (newProfessor: UserUpdateDTO) => {
    return api.post('/professor', newProfessor);
}

export const deleteProfessor = (id: string) => {
    return api.delete(`/professor/${id}`);
}
