import { PageOptions } from '../types/api';
import { UserUpdateDTO } from '../types/user';
import api from './api';

// Lists the students with optional search
export const listStudents = (pageOptions?: PageOptions) => {
  return api.get('/student', { params: pageOptions });
};

// Gets a single student by id
export const getStudent = (id: string) => {
  return api.get(`/student/${id}`);
};

// Creates a new student
export const createStudent = (data: UserUpdateDTO) => {
  return api.post('/student', data);
};

// Updates a student
export const updateStudent = ({
  id,
  updatedStudent,
}: {
  id: string;
  updatedStudent: Partial<UserUpdateDTO>;
}) => {
  return api.put(`/student/${id}`, updatedStudent);
};

// Deletes a student
export const deleteStudent = (id: string) => {
  return api.delete(`/student/${id}`);
};
