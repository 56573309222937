import { useContext, useEffect, useState } from "react";
import {
  getSingleStudentPerAssignment,
  listStudentsPerAssignment,
} from "../../../models/assignment_composition";
import { UserDTO } from "../../../types/user";
import AssignmentStudentsTable from "../../../components/Assigment/AssignmentStudentsTable";
import {
  AssignmentCompositionDTO,
  AssignmentDTO,
} from "../../../types/assignment";
import { getAssignment } from "../../../models/assignment";
import { useNavigate, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { PATHS } from "../../../utils/paths";
import { authContext } from "../../../context/auth";

const AssignmentStudentsPage = () => {
  const [students, setStudents] = useState<any[]>([]);
  const [name, setName] = useState<string>();
  const [expiration, setExpiration] = useState<Date>();
  const { user } = useContext(authContext);

  const navigateBack = useNavigate();
  useEffect(() => {
    
  })


  const { assignId } = useParams();
  

  async function getStudents() {
    if (!assignId)
      return;
    try {
      const studentsRes = await listStudentsPerAssignment(assignId);
      const studentsDTO: any[] = [];
      // Use map instead of forEach to get an array of promises
      await Promise.all(studentsRes.data.data.map(async (e: any) => {
        const res: any = await getSingleStudentPerAssignment({
          assignmentId: assignId,
          studentId: e.id,
        });

        const assignmentComposition: AssignmentCompositionDTO = res.data[0].assignmentComposition;
        if (assignmentComposition) {
          const deliveredAt: Date | null = assignmentComposition.deliveredAt
            ? new Date(assignmentComposition.deliveredAt)
            : null;
          studentsDTO.push({
            id: e.id,
            username: e.username,
            deliveredAt: deliveredAt,
            assignmentId: assignmentComposition.assignmentId,
          });
        }
      }));

      // After all promises resolve, set the students
      setStudents(studentsDTO);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getStudents();
  }, [assignId]);

  useEffect(() => {
    if (assignId)
      getAssignment(assignId)
        .then(response => {
          setName(response.data.name);
          response.data.expiration && setExpiration(new Date(response.data.expiration));
        })
        .catch(e => {
          console.log(e);
        })
  }, [assignId]);

  return (
    <div>
      <IconButton className='text-edilizia-default' onClick={() =>{
                  const pathRole = "/" + user?.role;
                 navigateBack(pathRole + PATHS.home)}}>
        <ArrowCircleLeftOutlinedIcon></ArrowCircleLeftOutlinedIcon>
      </IconButton>
      <div className="text-2xl font-bold text-tecnologia-default p-8 m-auto">
        STUDENTI DELL'ESERCITAZIONE: {name} -{" "}
        {expiration && expiration.toLocaleDateString()}
      </div>
      <AssignmentStudentsTable students={students} />
    </div>
  );
};

export default AssignmentStudentsPage;
