import { TaskCreateDTO, TaskUpdateDTO } from "../types/task";
import api from "./api";

export const listTasks = (
    assignmentId: string,
    page = 1,
    q = ''
) => {
    return api.get('/task', { params: {assignmentId, page, q } });
}

export const listTasksByStudent = (
    assignmentId: string,
    studentId: string,
    page = 1,
    q = ''
) => {
    return api.get('/task', { params: { assignmentId, studentId, page, q } });
}

export const getTask = (id: string | string[] | undefined) => {
    return api.get(`/task/${id}`);
}

export const updateTask = (id: string, newTask: TaskUpdateDTO) => {
    return api.put(`/task/${id}`, newTask);
}

export const createTask = (newTask: TaskCreateDTO) => {
    return api.post('/task', newTask);
}

export const deleteTask = (id: string) => {
    return api.delete(`/task/${id}`);
}
