import { image } from "html2canvas/dist/types/css/types/image";
import {
  FilterData,
  GanttColumnProps,
  GanttDependenciesProps,
  GanttEndImageProps,
  GanttExpandFiltersProps,
  GanttFilterProps,
  GanttFunctionalProps,
  GanttNowProps,
  GanttStyleProps,
  GanttTasksProps,
  TaskDataProps,
  status,
} from "../types/types";
import {
  getLastEndIndex,
  getTextSize,
  removeFromArray,
  calculateFillStyle,
  changeResolution,
  getSenseTiming,
} from "./GeneralFunctions";
import { Height, Task } from "@mui/icons-material";
import { drawText } from "./DrawGeneralFunctions";

/**
 * disegna il testo del filtro per data
 * @return {void}
 * @param { CanvasRenderingContext2D } ctx - context 2d del canvas
 * @param { FilterData } filterData - dati attuali del filtro per data
 * @param { number } timing - ogni quanto disegnare la linea
 * @param { GanttStyleProps } filterStyle - stile delle cdel filtro per data
 * @param { number } graphicWidth - larghezza del grafico
 * @param { number } maxWidth - larghezza massima tra gli id dei task e l'header
 * @param { GanttStyleProps } columnStyle - stile delle colonne degli id e degli stati dei tasks
 * @param { number } statusWidth - larghezza massima tra gli stati dei task e l'header
 */
export function drawFilterText(
  ctx: CanvasRenderingContext2D,
  filterData: FilterData,
  timing: number,
  filterStyle: GanttFilterProps,
  graphicWidth: number,
  maxWidth: number,
  columnStyle: GanttColumnProps,
  statusWidth: number,
): void {
  if (!ctx) return;

  let range = filterData.actualEnd.getTime() - filterData.actualStart.getTime();
  let actualRange = 0;

  ctx.fillStyle = filterStyle.filterFontColor;
  if (range > timing)
    while (actualRange + timing < range) {
      // Draw vertical lines

      let text = new Date(filterData.actualStart.getTime() + actualRange).toLocaleString("it-IT", {
        timeZone: "Europe/Rome",
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
      let textArray = text.split(",");
      let date = textArray[0].trim();
      let hour = textArray[1].trim();

      let text1Size = getTextSize(ctx, date);
      let adjustX1 =
        (timing / 2 / range) * graphicWidth -
        text1Size.textWidth / 2 +
        (maxWidth + 2 * columnStyle.distanceBetweenText + statusWidth);
      let temp1 = adjustX1;

      temp1 += (actualRange / range) * graphicWidth;
      drawText(ctx, date, temp1, filterStyle.filterSliderDistance + text1Size.textHeight);

      let text2Size = getTextSize(ctx, hour);

      let adjustX2 =
        (timing / 2 / range) * graphicWidth -
        text2Size.textWidth / 2 +
        (maxWidth + 2 * columnStyle.distanceBetweenText + statusWidth);
      let temp2 = adjustX2;
      temp2 += (actualRange / range) * graphicWidth;
      drawText(
        ctx,
        hour,
        temp2,
        filterStyle.filterSliderDistance +
          text1Size.textHeight +
          text2Size.textHeight +
          filterStyle.filterDateDistance,
      ); //TOCONTROL
      actualRange += timing;
    }
  else {
    let lineX = 0; //lineX2;

    // Draw vertical lines

    let text = new Date(filterData.actualStart.getTime()).toLocaleString("it-IT", {
      timeZone: "Europe/Rome",
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });
    let textArray = text.split(",");
    let date = textArray[0].trim();
    let hour = textArray[1].trim();

    let text1Size = getTextSize(ctx, date);
    let adjustX1 =
      graphicWidth / 2 -
      text1Size.textWidth / 2 +
      (maxWidth + 2 * columnStyle.distanceBetweenText + statusWidth);
    let temp1 = lineX + adjustX1;

    ctx.fillText(date, temp1, filterStyle.filterSliderDistance + text1Size.textHeight); // Adjust the Y coordinate as needed

    let text2Size = getTextSize(ctx, hour);
    let adjustX2 =
      graphicWidth / 2 -
      text2Size.textWidth / 2 +
      (maxWidth + 2 * columnStyle.distanceBetweenText + statusWidth);
    let temp2 = lineX + adjustX2;
    ctx.fillText(
      hour,
      temp2,
      filterStyle.filterSliderDistance +
        text1Size.textHeight +
        text2Size.textHeight +
        filterStyle.filterDateDistance,
    ); // Adjust the Y coordinate as needed
  }
}

/**
 * disegna il titolo delle colonne con i pulsanti per il filtro per task e status
 * @return { expandFilterTask: { x: number, y: number, height: number }, expandFilterStatus: { x: number, y: number, height: number } }
 * @param { CanvasRenderingContext2D } ctx - context 2d del canvas
 * @param { GanttStyleProps } columnStyle - stile delle colonne degli id e degli stati dei tasks
 * @param { GanttStyleProps } expandFiltersStyle - stile del filtro per task e status
 * @param { GanttStyleProps } filterStyle - stile delle cdel filtro per data
 * @param { number } maxWidth - larghezza massima tra gli id dei task e l'header
 * @param { number } statusWidth - larghezza massima tra gli stati dei task e l'header
 */
export function drawColumnsHeadersText(
  ctx: CanvasRenderingContext2D,
  columnStyle: GanttColumnProps,
  expandFiltersStyle: GanttExpandFiltersProps,
  filterStyle: GanttFilterProps,
  maxWidth: number,
  statusWidth: number,
): {
  expandFilterTask: { x: number; y: number; height: number };
  expandFilterStatus: { x: number; y: number; height: number };
} {
  if (!ctx)
    return {
      expandFilterTask: { x: 0, y: 0, height: 0 },
      expandFilterStatus: { x: 0, y: 0, height: 0 },
    };

  const distanceFromStart = columnStyle.distanceBetweenText / 2; // Adjust as needed
  let textStyle =
    columnStyle.columnHeaderFontSize + "px " + columnStyle.columnHeaderFontStyle + " bold";

  let text1 = "ID TASK";
  let text1Size = getTextSize(ctx, text1, textStyle);
  const text1X =
    distanceFromStart +
    (maxWidth - (text1Size.textWidth + expandFiltersStyle.expandFilterSize)) / 2;
  drawText(
    ctx,
    text1,
    text1X,
    filterStyle.filterGanttDistance + text1Size.textHeight,
    textStyle,
    columnStyle.columnHeaderFontColor,
  );

  ctx.strokeStyle = expandFiltersStyle.expandFilterColor;
  ctx.lineWidth = expandFiltersStyle.expandFilterLineWidth;
  // Draw the "expand more" icon for tasks
  ctx.beginPath();
  ctx.moveTo(
    text1X + text1Size.textWidth + expandFiltersStyle.expandFilterTextDistance,
    filterStyle.filterGanttDistance +
      text1Size.textHeight / 2 -
      expandFiltersStyle.expandFilterLineWidth / 2,
  ); // Start point
  ctx.lineTo(
    text1X +
      text1Size.textWidth +
      expandFiltersStyle.expandFilterSize / 2 +
      expandFiltersStyle.expandFilterTextDistance,
    filterStyle.filterGanttDistance +
      text1Size.textHeight -
      expandFiltersStyle.expandFilterLineWidth / 2,
  ); // Top-right point
  ctx.lineTo(
    text1X +
      text1Size.textWidth +
      expandFiltersStyle.expandFilterSize +
      expandFiltersStyle.expandFilterTextDistance,
    filterStyle.filterGanttDistance +
      text1Size.textHeight / 2 -
      expandFiltersStyle.expandFilterLineWidth / 2,
  ); // Bottom-right point
  ctx.stroke();

  //setExpandFilterTask({ x: text1X + text1Size.textWidth + expandFiltersStyle.expandFilterTextDistance, y: filterStyle.filterGanttDistance + text1Size.textHeight / 2 - expandFiltersStyle.expandFilterLineWidth / 2, height: text1Size.textHeight / 2 + expandFiltersStyle.expandFilterLineWidth / 2 });
  let expandFilterTask = {
    x: text1X + text1Size.textWidth + expandFiltersStyle.expandFilterTextDistance,
    y:
      filterStyle.filterGanttDistance +
      text1Size.textHeight / 2 -
      expandFiltersStyle.expandFilterLineWidth / 2,
    height: text1Size.textHeight / 2 + expandFiltersStyle.expandFilterLineWidth / 2,
  };

  let text2 = "STATUS";
  let text2Size = getTextSize(ctx, text2);
  const text2X =
    text1X +
    text1Size.textWidth +
    (maxWidth - text1Size.textWidth) / 2 +
    columnStyle.distanceBetweenText +
    (statusWidth - text2Size.textWidth) / 2; //;
  drawText(ctx, text2, text2X, filterStyle.filterGanttDistance + text2Size.textHeight);

  // Draw the "expand more" icon for status
  ctx.beginPath();
  ctx.moveTo(
    text2X + text2Size.textWidth + expandFiltersStyle.expandFilterTextDistance,
    filterStyle.filterGanttDistance +
      text1Size.textHeight / 2 -
      expandFiltersStyle.expandFilterLineWidth / 2,
  ); // Start point
  ctx.lineTo(
    text2X +
      text2Size.textWidth +
      expandFiltersStyle.expandFilterSize / 2 +
      expandFiltersStyle.expandFilterTextDistance,
    filterStyle.filterGanttDistance +
      text1Size.textHeight -
      expandFiltersStyle.expandFilterLineWidth / 2,
  ); // Top-right point
  ctx.lineTo(
    text2X +
      text2Size.textWidth +
      expandFiltersStyle.expandFilterSize +
      expandFiltersStyle.expandFilterTextDistance,
    filterStyle.filterGanttDistance +
      text1Size.textHeight / 2 -
      expandFiltersStyle.expandFilterLineWidth / 2,
  ); // Bottom-right point
  ctx.stroke();

  //setExpandFilterStatus({ x: text2X + text2Size.textWidth + expandFiltersStyle.expandFilterTextDistance, y: filterStyle.filterGanttDistance + text1Size.textHeight / 2 - expandFiltersStyle.expandFilterLineWidth / 2, height: text1Size.textHeight / 2 + expandFiltersStyle.expandFilterLineWidth / 2 });
  let expandFilterStatus = {
    x: text2X + text2Size.textWidth + expandFiltersStyle.expandFilterTextDistance,
    y:
      filterStyle.filterGanttDistance +
      text1Size.textHeight / 2 -
      expandFiltersStyle.expandFilterLineWidth / 2,
    height: text1Size.textHeight / 2 + expandFiltersStyle.expandFilterLineWidth / 2,
  };

  return { expandFilterTask, expandFilterStatus };
}
