import { UserUpdateDTO } from '../types/user';
import api from './api';

export const listUsers = async (
  page = 1,
  terminated = false,
  disabled = false,
  q = ''
) => {
  return await api.get('/user', { params: { page, terminated, disabled, q } });
};
export const getUser = async (id: string | string[] | undefined) => {
  return await api.get(`/user/${id}`);
};
export const updateUser = async (id: string, newUser: UserUpdateDTO) => {
  return await api.put(`/user/${id}`, newUser);
};
export const getMe = async () => {
  return await api.get('/user/me');
};
