import FileOpenIcon from '@mui/icons-material/FileOpen';
import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import { download } from '../../utils/common';

interface TaxBoxProps {
    name: string;
    document: any;
    documentName: string;
    show: boolean;
    x: number;
    y: number;
}

const TooltipInformationGantt: React.FC<TaxBoxProps> = ({ name, document, documentName, show, x, y, }) => {
    const [hovered, setHovered] = useState(false);


    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    const boxStyle: React.CSSProperties = {
        position: 'absolute',
        top: `${y}px`,
        left: `${x}px`,
        width: '250px', // Increased to accommodate the triangle
        height: '200px',
        display: show || hovered ? 'block' : 'none',
        zIndex: 3,
    };

    return (
        <div
            style={boxStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className='bg-white border-2 border-edilizia-default rounded-lg relative' // Add relative positioning
        >
            <div className='text-center text-tecnologia-default text-lg pb-1 font-bold'>{name}</div>
            <div className='w-full text-center'>
                <IconButton className="text-accademia-default m-auto top-10 " onClick={() => {download(document, documentName) }}>
                    <FileOpenIcon fontSize='large' className='lg'>
                    </FileOpenIcon>
                </IconButton>
            </div>
        </div>
    );
};

export default TooltipInformationGantt;
