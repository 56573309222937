import { image } from "html2canvas/dist/types/css/types/image";
import {
  FilterData,
  GanttColumnProps,
  GanttDependenciesProps,
  GanttEndImageProps,
  GanttExpandFiltersProps,
  GanttFilterProps,
  GanttFunctionalProps,
  GanttNowProps,
  GanttStyleProps,
  GanttTasksProps,
  TaskDataProps,
  line,
  status,
} from "../types/types";
import {
  getLastEndIndex,
  getTextSize,
  removeFromArray,
  calculateFillStyle,
  changeResolution,
  getSenseTiming,
} from "./GeneralFunctions";
import { Height, Task } from "@mui/icons-material";
import { drawStraightLine } from "./DrawGeneralFunctions";

/**
 * disegna le linee delle dipendenze
 * @return {void}
 * @param { CanvasRenderingContext2D } ctx - context 2d del canvas
 * @param { { startX: number, startY: number, endX: number, endY: number }[] } notClicked - array con coordinate, altezza e larghezza dei task non cliccati
 * @param { { startX: number, startY: number, endX: number, endY: number }[] } clicked - array con coordinate, altezza e larghezza dei task cliccati
 * @param { GanttDependenciesProps } dependenciesStyle - stile delle dipendenze
 * @param { GanttStyleProps } ganttStyle - stile generico del gantt
 * @param { GanttTasksProps } tasksStyle - stile dei tasks
 */
export function drawDependenciesLines(
  ctx: CanvasRenderingContext2D,
  notClicked: { startX: number; startY: number; endX: number; endY: number }[],
  clicked: { startX: number; startY: number; endX: number; endY: number }[],
  dependenciesStyle: GanttDependenciesProps,
  ganttStyle: GanttStyleProps,
  tasksStyle: GanttTasksProps,
): void {
  ctx.fillStyle = dependenciesStyle.dependenciesColor;
  ctx.strokeStyle = dependenciesStyle.dependenciesColor;
  ctx.lineWidth = dependenciesStyle.dependenciesWidth;
  notClicked.forEach((element) => {
    // write task dependencies
    ctx.beginPath();
    ctx.moveTo(element.startX, element.startY);
    ctx.lineTo(element.startX, element.endY + dependenciesStyle.dependenciesWidth / 2);
    ctx.moveTo(element.startX, element.endY);
    ctx.lineTo(element.endX - dependenciesStyle.dependenciesWidth / 2, element.endY);
    ctx.stroke();

    // Calculate the angle of the line
    const angle = Math.atan2(0, 45);

    // Define the arrowhead length and width
    const arrowheadLength = dependenciesStyle.dependenciesWidth * 6;

    // Calculate the coordinates of the arrowhead points
    const arrowheadX1 =
      element.endX + ganttStyle.lineWidth - arrowheadLength * Math.cos(angle - Math.PI / 6);
    const arrowheadY1 =
      element.endY + ganttStyle.lineWidth - arrowheadLength * Math.sin(angle - Math.PI / 6);
    const arrowheadX2 =
      element.endX + ganttStyle.lineWidth - arrowheadLength * Math.cos(angle + Math.PI / 6);
    const arrowheadY2 =
      element.endY - ganttStyle.lineWidth - arrowheadLength * Math.sin(angle + Math.PI / 6);

    if (element.endX - element.startX >= arrowheadLength) {
      // Draw the arrowhead
      ctx.beginPath();
      ctx.moveTo(element.endX, element.endY);
      ctx.lineTo(arrowheadX1, arrowheadY1);
      ctx.lineTo(arrowheadX2, arrowheadY2);
      ctx.closePath();
      ctx.fill();
      //ctx.stroke();
    }
  });

  ctx.fillStyle = tasksStyle.tasksColor.selected;
  ctx.strokeStyle = tasksStyle.tasksColor.selected;
  ctx.lineWidth = (dependenciesStyle.dependenciesWidth * 3) / 2;
  clicked.forEach((element) => {
    // write task dependencies
    ctx.beginPath();
    ctx.moveTo(element.startX, element.startY);
    ctx.lineTo(element.startX, element.endY + dependenciesStyle.dependenciesWidth / 2);
    ctx.moveTo(element.startX, element.endY);
    ctx.lineTo(element.endX - dependenciesStyle.dependenciesWidth / 2, element.endY);
    ctx.stroke();

    // Calculate the angle of the line
    const angle = Math.atan2(0, 45);

    // Define the arrowhead length and width
    const arrowheadLength = dependenciesStyle.dependenciesWidth * 6;

    // Calculate the coordinates of the agrarrowhead points
    const arrowheadX1 =
      element.endX + ganttStyle.lineWidth - arrowheadLength * Math.cos(angle - Math.PI / 6);
    const arrowheadY1 =
      element.endY + ganttStyle.lineWidth - arrowheadLength * Math.sin(angle - Math.PI / 6);
    const arrowheadX2 =
      element.endX + ganttStyle.lineWidth - arrowheadLength * Math.cos(angle + Math.PI / 6);
    const arrowheadY2 =
      element.endY - ganttStyle.lineWidth - arrowheadLength * Math.sin(angle + Math.PI / 6);

    if (element.endX - element.startX >= arrowheadLength) {
      // Draw the arrowhead
      ctx.beginPath();
      ctx.moveTo(element.endX, element.endY);
      ctx.lineTo(arrowheadX1, arrowheadY1);
      ctx.lineTo(arrowheadX2, arrowheadY2);
      ctx.closePath();

      // Fill the arrowhead
      ctx.fill();
    }

    //ctx.stroke();
  });
}

/**
 * disegna le dipendenze
 * @return {void}
 * @param { CanvasRenderingContext2D } ctx - context 2d del canvas
 * @param { FilterData } filterData - dati attuali del filtro per data
 * @param {  { [key: string]: TaskDataProps } } tasksByRunbookId - array associativo [id: task] con tutti i tasks
 * @param { TaskDataProps[] } activeTasks - array con i tasks dopo essere stati filtrati
 * @param { number } graphicWidth - larghezza del grafico
 * @param { GanttStyleProps } ganttStyle - stile generico del gantt
 * @param { GanttTasksProps } tasksStyle - stile dei tasks
 * @param { GanttDependenciesProps } dependenciesStyle - stile delle dipendenze
 * @param { {[key: string]: boolean} } clickedEffective - array di flags che indica quale task tra gli effettivi è stato cliccato
 * @param { {[key: string]: boolean} } clickedOriginal - array di flags che indica quale task tra i virtuali è stato cliccato
 */
export function drawDependencies(
  ctx: CanvasRenderingContext2D,
  filterData: FilterData,
  tasksByRunbookId: { [key: string]: TaskDataProps },
  activeTasks: TaskDataProps[],
  ganttStyle: GanttStyleProps,
  graphicWidth: number,
  tasksStyle: GanttTasksProps,
  dependenciesStyle: GanttDependenciesProps,
  clickedEffective: { [key: string]: boolean },
  clickedOriginal: { [key: string]: boolean },
): void {
  //console.log(filterData, tasksByRunbookId,  activeTasks, ganttStyle, graphicWidth, tasksStyle, dependenciesStyle, clickedEffective, clickedOriginal)

  let range = filterData.actualEnd.getTime() - filterData.actualStart.getTime();
  const clicked: { startX: number; startY: number; endX: number; endY: number }[] = [];
  const notClicked: { startX: number; startY: number; endX: number; endY: number }[] = [];
  for (const key in tasksByRunbookId) {
    var endTask = tasksByRunbookId[key];
    var depIndex = activeTasks.indexOf(endTask);

    let endEffectiveLength = endTask.effectiveEnd.getTime() - endTask.effectiveStart.getTime();
    let endEffectiveStart = endTask.effectiveStart.getTime() - filterData.actualStart.getTime();

    let originalEnd = endTask.originalStart.getTime() - filterData.actualStart.getTime();
    //effective task
    let endEffectiveWidth = (endEffectiveLength / range) * graphicWidth;

    let endOriginalLength = endTask.originalEnd.getTime() - endTask.originalStart.getTime();

    //assumed task
    let endOriginalWidth = (endOriginalLength / range) * graphicWidth;

    const endY = ganttStyle.lineHeight / 2 + ganttStyle.lineHeight * depIndex;
    let endX = 0;
    if (tasksStyle.effectiveTasks) {
      if (endEffectiveWidth > 0) endX = (endEffectiveStart / range) * graphicWidth; //lineX2;
      else endX = (endEffectiveStart / range) * graphicWidth - tasksStyle.tasksHeight / 2; //lineX2;
    } else if (tasksStyle.originalTasks && !tasksStyle.effectiveTasks) {
      if (endOriginalWidth > 0)
        endX =
          (originalEnd / range) * graphicWidth -
          dependenciesStyle.dependenciesWidth / 2; //lineX2;kkk
      else endX = (originalEnd / range) * graphicWidth - tasksStyle.tasksHeight / 2; //lineX2;
    }

    for (const dependencyId of endTask.dependencies) {
      const startTask = tasksByRunbookId[dependencyId];
      if (startTask && endTask) {
        let startIndex = activeTasks.indexOf(startTask);

        let startOriginalLength =
          startTask.originalEnd.getTime() - startTask.originalStart.getTime();
        let originalStart = startTask.originalStart.getTime() - filterData.actualStart.getTime();

        let startEffectiveLength =
          startTask.effectiveEnd.getTime() - startTask.effectiveStart.getTime();
        let startEffectiveStart =
          startTask.effectiveStart.getTime() - filterData.actualStart.getTime();

        let rectangleY =
          +tasksStyle.tasksHeight / 2 +
          ganttStyle.lineHeight / 2 +
          ganttStyle.lineHeight * startIndex;
        //effective task
        let startEffectiveX = +((startEffectiveStart / range) * graphicWidth); //lineX2;
        let startEffectiveWidth = (startEffectiveLength / range) * graphicWidth;

        //assumed task
        let startOriginalX = (originalStart / range) * graphicWidth; //lineX2;
        let startOriginalWidth = (startOriginalLength / range) * graphicWidth;
        var startX;
        var startY;
        if (tasksStyle.effectiveTasks) {
          startX = startEffectiveX + startEffectiveWidth / 2;
          startY = startEffectiveWidth > 0 ? rectangleY : rectangleY + 5;
        } else if (tasksStyle.originalTasks && !tasksStyle.effectiveTasks) {
          startX = startOriginalX + startOriginalWidth / 2;
          startY = startOriginalWidth > 0 ? rectangleY : rectangleY + 5;
        } else return;

        if (tasksStyle.effectiveTasks) {
          if (clickedEffective[startTask.runbookId] || clickedEffective[endTask.runbookId]) {
            clicked.push({ startX: startX, startY: startY, endX: endX, endY: endY });
          } else {
            notClicked.push({ startX: startX, startY: startY, endX: endX, endY: endY });
          }
        } else if (tasksStyle.originalTasks && !tasksStyle.effectiveTasks) {
          if (clickedOriginal[startTask.runbookId] || clickedOriginal[endTask.runbookId]) {
            clicked.push({ startX: startX, startY: startY, endX: endX, endY: endY });
          } else {
            notClicked.push({ startX: startX, startY: startY, endX: endX, endY: endY });
          }
        }
      }
    }
  }
  drawDependenciesLines(ctx, notClicked, clicked, dependenciesStyle, ganttStyle, tasksStyle);
}

/**
 * disegna la linea che indica la data attuale
 * @return {void}
 * @param { CanvasRenderingContext2D } ctx - context 2d del canvas
 * @param { FilterData } filterData - dati attuali del filtro per data
 * @param { GanttNowProps } nowStyle - stile della linea e del triangolo che indica la data attuale
 * @param { number } now - numero che indica in millisecondi la data attuale
 * @param { number } graphicWidth - larghezza del grafico
 * @param { number } ch - altezza del grafico
 */
export function drawNowLine(
  ctx: CanvasRenderingContext2D,
  filterData: FilterData,
  nowStyle: GanttNowProps,
  now: number,
  graphicWidth: number,
  ch: number,
): void {
  let range = filterData.actualEnd.getTime() - filterData.actualStart.getTime();
  //ctx.lineWidth = ganttStyle.lineWidth; // Set line width
  if (
    (now - filterData.actualEnd.getTime()) / range <= 0 &&
    (now - filterData.actualStart.getTime()) / range >= 0
  ) {
    //&& (filterData.actualEnd.getTime() - now) > -300) {
    let x = ((now - filterData.actualStart.getTime()) / range) * graphicWidth; //lineX2;
    ctx.setLineDash([]);
    // Draw now line
    drawStraightLine(ctx, x, 0, ch, line.vertical, nowStyle.nowLineWidth, nowStyle.nowLineColor);
  }
}

/**
 * disegna l'immagine dell'ultimo task
 * @return {void}
 * @param { CanvasRenderingContext2D } ctx - context 2d del canvas
 * @param { TaskDataProps[] } tasks - array con tutti i tasks
 * @param {  { [key: string]: TaskDataProps } } tasksByRunbookId - array associativo [id: task] con tutti i tasks
 * @param { TaskDataProps[] } activeTasks - array con i tasks dopo essere stati filtrati
 * @param { FilterData } filterData - dati attuali del filtro per data
 * @param { GanttTasksProps } tasksStyle - stile dei tasks
 * @param { number } graphicWidth - larghezza del grafico
 * @param { GanttStyleProps } ganttStyle - stile generico del gantt
 * @param { GanttEndImageProps } endImageStyle - stile dell' immagine finale
 * @param { HTMLImageElement } image - html dell'immagine finale
 */
export function drawLastEndImage(
  ctx: CanvasRenderingContext2D,
  tasks: TaskDataProps[],
  tasksByRunbookId: { [key: string]: TaskDataProps },
  activeTasks: TaskDataProps[],
  filterData: FilterData,
  tasksStyle: GanttTasksProps,
  graphicWidth: number,
  ganttStyle: GanttStyleProps,
  endImageStyle: GanttEndImageProps,
  image: HTMLImageElement,
): void {
  if (!ctx) return;
  let lastEnd = getLastEndIndex(
    tasksStyle.effectiveTasks,
    tasksStyle.originalTasks,
    tasks,
    tasksByRunbookId,
    activeTasks,
  );
  if (lastEnd === -1) {
    return;
  }

  //
  let range = filterData.actualEnd.getTime() - filterData.actualStart.getTime();
  //effective task
  let effectiveStart =
    activeTasks[lastEnd].effectiveStart.getTime() - filterData.actualStart.getTime();
  let effectiveEnd = activeTasks[lastEnd].effectiveEnd.getTime() - filterData.actualStart.getTime();
  let effectiveLength =
    activeTasks[lastEnd].effectiveEnd.getTime() - activeTasks[lastEnd].effectiveStart.getTime();
  let rectangleEffectiveX = (effectiveStart / range) * graphicWidth; //lineX2;
  let rectangleEffectiveWidth = (effectiveLength / range) * graphicWidth;

  //assumed task
  let originalStart =
    activeTasks[lastEnd].originalStart.getTime() - filterData.actualStart.getTime();
  let originalEnd = activeTasks[lastEnd].originalEnd.getTime() - filterData.actualStart.getTime();
  let originalLength =
    activeTasks[lastEnd].originalEnd.getTime() - activeTasks[lastEnd].originalStart.getTime();
  let rectangleVirtualX = (originalStart / range) * graphicWidth; //lineX2;
  let rectangleOriginalWidth = (originalLength / range) * graphicWidth;
  //let index = lastEnd

  if (
    Math.max(
      activeTasks[lastEnd].originalEnd.getTime(),
      activeTasks[lastEnd].effectiveEnd.getTime(),
    ) >= filterData.actualStart.getTime() &&
    Math.min(
      activeTasks[lastEnd].originalStart.getTime(),
      activeTasks[lastEnd].effectiveStart.getTime(),
    ) <= filterData.actualEnd.getTime()
  ) {
    if (
      Math.max(
        rectangleEffectiveWidth + rectangleEffectiveX,
        rectangleOriginalWidth + rectangleVirtualX,
      ) +
        tasksStyle.tasksHeight / 2 >
      graphicWidth
    ) {
      ctx.drawImage(
        image,
        graphicWidth,
        ganttStyle.lineHeight / 2 +
          ganttStyle.lineHeight * lastEnd -
          endImageStyle.endImageHeight / 2,
        endImageStyle.endImageWidth,
        endImageStyle.endImageHeight,
      );
    } else if (effectiveEnd > 0 || originalEnd > 0) {
      if (tasksStyle.effectiveTasks && tasksStyle.originalTasks) {
        if (
          activeTasks[lastEnd].effectiveEnd.getTime() >= activeTasks[lastEnd].originalEnd.getTime()
        ) {
          if (rectangleEffectiveWidth > 0) {
            ctx.drawImage(
              image,
              rectangleEffectiveWidth + rectangleEffectiveX,
              +ganttStyle.lineHeight / 2 +
                ganttStyle.lineHeight * lastEnd -
                endImageStyle.endImageHeight / 2,
              endImageStyle.endImageWidth,
              endImageStyle.endImageHeight,
            );
          } else {
            ctx.drawImage(
              image,
              rectangleEffectiveX + tasksStyle.tasksHeight / 2,
              +ganttStyle.lineHeight / 2 +
                ganttStyle.lineHeight * lastEnd -
                endImageStyle.endImageHeight / 2,
              endImageStyle.endImageWidth,
              endImageStyle.endImageHeight,
            );
          }
        } else {
          if (rectangleOriginalWidth > 0) {
            ctx.drawImage(
              image,
              rectangleOriginalWidth + rectangleVirtualX,
              +ganttStyle.lineHeight / 2 +
                ganttStyle.lineHeight * lastEnd -
                endImageStyle.endImageHeight / 2,
              endImageStyle.endImageWidth,
              endImageStyle.endImageHeight,
            );
          } else {
            ctx.drawImage(
              image,
              rectangleVirtualX + tasksStyle.tasksHeight / 2,
              +ganttStyle.lineHeight / 2 +
                ganttStyle.lineHeight * lastEnd -
                endImageStyle.endImageHeight / 2,
              endImageStyle.endImageWidth,
              endImageStyle.endImageHeight,
            );
          }
        }
      } else if (tasksStyle.effectiveTasks) {
        if (rectangleEffectiveWidth > 0) {
          ctx.drawImage(
            image,
            rectangleEffectiveWidth + rectangleEffectiveX,
            +ganttStyle.lineHeight / 2 +
              ganttStyle.lineHeight * lastEnd -
              endImageStyle.endImageHeight / 2,
            endImageStyle.endImageWidth,
            endImageStyle.endImageHeight,
          );
        } else {
          ctx.drawImage(
            image,
            rectangleEffectiveX + tasksStyle.tasksHeight / 2,
            +ganttStyle.lineHeight / 2 +
              ganttStyle.lineHeight * lastEnd -
              endImageStyle.endImageHeight / 2,
            endImageStyle.endImageWidth,
            endImageStyle.endImageHeight,
          );
        }
      } else if (tasksStyle.originalTasks) {
        if (rectangleOriginalWidth > 0) {
          ctx.drawImage(
            image,
            rectangleOriginalWidth + rectangleVirtualX,
            +ganttStyle.lineHeight / 2 +
              ganttStyle.lineHeight * lastEnd -
              endImageStyle.endImageHeight / 2,
            endImageStyle.endImageWidth,
            endImageStyle.endImageHeight,
          );
        } else {
          ctx.drawImage(
            image,
            rectangleVirtualX + tasksStyle.tasksHeight / 2,
            +ganttStyle.lineHeight / 2 +
              ganttStyle.lineHeight * lastEnd -
              endImageStyle.endImageHeight / 2,
            endImageStyle.endImageWidth,
            endImageStyle.endImageHeight,
          );
        }
      }
    }
  }
  // }
}

/**
 * disegna le linee del filtro per data
 * @return {void}
 * @param { CanvasRenderingContext2D } ctx - context 2d del canvas
 * @param { FilterData } filterData - dati attuali del filtro per data
 * @param { number } timing - ogni quanto disegnare la linea
 * @param { GanttStyleProps } columnStyle - stile delle colonne degli id e degli stati dei tasks
 * @param { GanttStyleProps } ganttStyle - stile generico del gantt
 * @param { number } graphicWidth - larghezza del grafico
 * @param { number } maxWidth - larghezza massima tra gli id dei task e l'header
 * @param { number } statusWidth - larghezza massima tra gli stati dei task e l'header
 */
export function drawFilterLine(
  ctx: CanvasRenderingContext2D,
  filterData: FilterData,
  timing: number,
  aTaskLength: number,
  columnStyle: GanttColumnProps,
  ganttStyle: GanttStyleProps,
  graphicWidth: number,
  maxWidth: number,
  statusWidth: number,
): void {
  if (!ctx) return;
  let range = filterData.actualEnd.getTime() - filterData.actualStart.getTime();
  ctx.setLineDash([5, 5]);

  let actualRange = maxWidth + 2 * columnStyle.distanceBetweenText + statusWidth;
  while (actualRange + timing < range) {
    let temp = 0;
    actualRange += timing;
    temp += (actualRange / range) * graphicWidth;

    drawStraightLine(
      ctx,
      temp,
      0,
      aTaskLength * ganttStyle.lineHeight,
      line.vertical,
      ganttStyle.lineWidth,
      ganttStyle.strokeColor,
    );
  }

  ctx.setLineDash([]);
}

/**
 * disegna un task
 * @return {{ r: { x: number, y: number, width: number, height: number }|undefined, v: { x: number, y: number, width: number, height: number }|undefined} } ritorna le coordinate con altezza e larghezza del task, divisi in quelli virtuali e quelli effettivi
 * @param { CanvasRenderingContext2D } ctx - context 2d del canvas
 * @param { FilterData } filterData - dati attuali del filtro per data
 * @param { TaskDataProps } task - task
 * @param { boolean } clicked - valore booleano che indica se il task è stato cliccato
 * @param { GanttTasksProps } tasksStyle - stile dei tasks
 * @param { number } graphicWidth - larghezza del grafico
 * @param { GanttStyleProps } ganttStyle - stile generico del gantt
 * @param { GanttFunctionalProps } functional - stile generico delle funzioni da chiamare
 */
export function drawTask(
  ctx: CanvasRenderingContext2D,
  filterData: FilterData,
  task: TaskDataProps,
  activeTasks: TaskDataProps[],
  clicked: boolean,
  tasksStyle: GanttTasksProps,
  graphicWidth: number,
  ganttStyle: GanttStyleProps,
  functional: GanttFunctionalProps,
): {
  r: { x: number; y: number; width: number; height: number } | undefined;
  v: { x: number; y: number; width: number; height: number } | undefined;
} {
  let range = filterData.actualEnd.getTime() - filterData.actualStart.getTime();

  let originalLength = task.originalEnd.getTime() - task.originalStart.getTime();
  let originalStart = task.originalStart.getTime() - filterData.actualStart.getTime();

  let effectiveLength = task.effectiveEnd.getTime() - task.effectiveStart.getTime();
  let effectiveStart = task.effectiveStart.getTime() - filterData.actualStart.getTime();

  let aTask = activeTasks.indexOf(task);
  //console.log(aTask)
  let rectangleY =
    -tasksStyle.tasksHeight / 2 + ganttStyle.lineHeight / 2 + ganttStyle.lineHeight * aTask;
  let milestoneY = ganttStyle.lineHeight / 2 + ganttStyle.lineHeight * aTask;
  //effective task
  let rectangleEffectiveX = (effectiveStart / range) * graphicWidth; //lineX2;
  let rectangleEffectiveWidth = (effectiveLength / range) * graphicWidth;

  //effective task
  let rectangleVirtualX = (originalStart / range) * graphicWidth; //lineX2;
  let rectangleOriginalWidth = (originalLength / range) * graphicWidth;
  let v;
  let r;

  if (tasksStyle.originalTasks) {
    if (!tasksStyle.effectiveTasks && clicked) ctx.fillStyle = tasksStyle.tasksColor.selected;
    else if (!tasksStyle.effectiveTasks && functional.criticalPath)
      ctx.fillStyle = tasksStyle.tasksColor.criticalPath;
    else ctx.fillStyle = tasksStyle.tasksColor.originalTime;
    if (rectangleOriginalWidth > 0) {
      ctx.beginPath();
      ctx.roundRect(
        rectangleVirtualX,
        rectangleY,
        rectangleOriginalWidth,
        tasksStyle.tasksHeight,
        tasksStyle.taskCorner,
      );
      ctx.fill();
      v = {
        x: rectangleVirtualX,
        y: rectangleY,
        width: rectangleOriginalWidth,
        height: tasksStyle.tasksHeight,
      };
    } else {
      // Draw a milestone (diamond shape)
      let size = tasksStyle.tasksHeight / 2;
      ctx.beginPath();
      ctx.moveTo(rectangleVirtualX, milestoneY - size - 5); // Top point
      ctx.lineTo(rectangleVirtualX + size, milestoneY); // Right point
      ctx.lineTo(rectangleVirtualX, milestoneY + size + 5); // Bottom point
      ctx.lineTo(rectangleVirtualX - size, milestoneY); // Left point
      ctx.closePath();
      ctx.fill();
      v = {
        x: rectangleVirtualX - size,
        y: milestoneY - size - 5,
        width: 2 * size,
        height: 2 * size + 10,
      };
    }
  }

  if (tasksStyle.effectiveTasks) {
    if (clicked) ctx.fillStyle = tasksStyle.tasksColor.selected;
    else if (functional.criticalPath) ctx.fillStyle = tasksStyle.tasksColor.criticalPath;
    else ctx.fillStyle = tasksStyle.tasksColor.effectiveTime;
    if (rectangleEffectiveWidth > 0) {
      ctx.beginPath();
      ctx.roundRect(
        rectangleEffectiveX,
        rectangleY,
        rectangleEffectiveWidth,
        tasksStyle.tasksHeight,
        tasksStyle.taskCorner,
      );
      ctx.fill();
      r = {
        x: rectangleEffectiveX,
        y: rectangleY,
        width: rectangleEffectiveWidth,
        height: tasksStyle.tasksHeight,
      };
    } else {
      // Draw a milestone (diamond shape)
      let size = tasksStyle.tasksHeight / 2;
      ctx.beginPath();
      ctx.moveTo(rectangleEffectiveX, milestoneY - size - 5); // Top point
      ctx.lineTo(rectangleEffectiveX + size, milestoneY); // Right point
      ctx.lineTo(rectangleEffectiveX, milestoneY + size + 5); // Bottom point
      ctx.lineTo(rectangleEffectiveX - size, milestoneY); // Left point
      ctx.closePath();
      ctx.fill();
      r = {
        x: rectangleEffectiveX - size,
        y: milestoneY - size - 5,
        width: 2 * size,
        height: 2 * size + 10,
      };
    }
  }
  return { r, v };
}

/**
 * disegna il bordo inferiore del rettangolo che contiene il task
 * @return {void}
 * @param { HTMLCanvasElement } canvas - reference al canvas
 * @param { CanvasRenderingContext2D } ctx - context 2d del canvas
 * @param { TaskDataProps[] } activeTasks - array con i tasks dopo essere stati filtrati
 * @param { GanttStyleProps } ganttStyle - stile generico del gantt
 * @param { GanttEndImageProps } endImageStyle - stile dell' immagine finale
 * @param { number } graphicWidth - larghezza del grafico
 * @param { number } nowTextWidth - larghezza del testo "now"
 * @param { GanttNowProps } nowStyle - stile della linea e del triangolo che indica la data attuale
 */
export function drawBottomBorderTask(
  canvas: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D,
  activeTasks: TaskDataProps[],
  ganttStyle: GanttStyleProps,
  endImageStyle: GanttEndImageProps,
  graphicWidth: number,
  nowTextWidth: number,
  nowStyle: GanttNowProps,
): void {
  if (!canvas) return;
  if (!ctx) return;
  let aTask = 0;

  for (const task of activeTasks) {
    if (aTask & 0x01) var bgColor = ganttStyle.bgColor2;
    else var bgColor = ganttStyle.bgColor1;

    // Set the background color based on the calculated bgColor
    ctx.fillStyle = bgColor;

    ctx.fillRect(0, +ganttStyle.lineHeight * aTask, graphicWidth, ganttStyle.lineHeight);

    // Draw horizontal lines at the start and end
    const lineY2 = ganttStyle.lineHeight + ganttStyle.lineHeight * aTask; // Y position of the second horizontal line

    ctx.strokeStyle = ganttStyle.strokeColor; // Set line color

    ctx.setLineDash([]);

    ctx.setLineDash([5, 5]);
    let lineWidth =
      canvas.width -
      Math.max(endImageStyle.endImageWidth, Math.max(nowStyle.nowTriangleWidth, nowTextWidth) / 2);
    drawStraightLine(
      ctx,
      0,
      lineY2,
      lineWidth,
      line.horizontal,
      ganttStyle.lineWidth,
      ganttStyle.strokeColor,
    );
    // Draw the horizontal line
    aTask++;
  }

  ctx.setLineDash([]);
}
/**
 * disegna l'immagine dell'ultimo task
 * @return {void}
 * @param { HTMLCanvasElement } canvas - reference al canvas
 * @param { CanvasRenderingContext2D } ctx - context 2d del canvas
 * @param { TaskDataProps[] } tasks - array con tutti i tasks
 * @param { number} width - larghezza in pixel del canvas
 * @param { FilterData } filterData - dati attuali del filtro per data
 * @param {  { [key: string]: TaskDataProps } } tasksByRunbookId - array associativo [id: task] con tutti i tasks
 * @param { TaskDataProps[] } activeTasks - array con i tasks dopo essere stati filtrati
 * @param { GanttStyleProps } ganttStyle - stile generico del gantt
 * @param { number } graphicWidth - larghezza del grafico
 * @param { GanttTasksProps } tasksStyle - stile dei tasks
 * @param { GanttDependenciesProps } dependenciesStyle - stile delle dipendenze
 * @param { {[key: string]: boolean} } clickedEffective - array di flags che indica quale task tra gli effettivi è stato cliccato
 * @param { {[key: string]: boolean} } clickedOriginal - array di flags che indica quale task tra i virtuali è stato cliccato
 * @param { GanttFunctionalProps } functional - stile generico delle funzioni da chiamare
 * @param { GanttEndImageProps } endImageStyle - stile dell' immagine finale
 * @param { HTMLImageElement } image - html dell'immagine finale
 * @param { boolean } imageLoaded - booleano che indica se l'immagine è stata caricata
 */
export function drawTasks(
  canvas: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D,
  tasks: TaskDataProps[],
  width: number,
  filterData: FilterData,
  tasksByRunbookId: { [key: string]: TaskDataProps },
  activeTasks: TaskDataProps[],
  ganttStyle: GanttStyleProps,
  graphicWidth: number,
  tasksStyle: GanttTasksProps,
  dependenciesStyle: GanttDependenciesProps,
  clickedEffective: { [key: string]: boolean },
  clickedOriginal: { [key: string]: boolean },
  functional: GanttFunctionalProps,
  endImageStyle: GanttEndImageProps,
  image: HTMLImageElement,
  imageLoaded: boolean,
): void {
  //array tastk by runbook id
  let rRectangles: { x: number; y: number; width: number; height: number }[] = [];
  let vRectangles: { x: number; y: number; width: number; height: number }[] = [];
  let black: TaskDataProps[] = [];
  let original: TaskDataProps[] = [];
  let critical: TaskDataProps[] = [];
  //let aTask = 0;

  if (!canvas) return;
  if (!ctx) return;
  for (const task of activeTasks) {
    // Define the milestone coordinates and size
    //var y =  + rectangleY; // Y-coordinate of the milestone

    if (clickedOriginal[task.runbookId] || clickedEffective[task.runbookId]) {
      black.push(task);
      removeFromArray(original, task);
      removeFromArray(critical, task);
      task.dependencies.forEach((e: string) => {
        black.push(tasksByRunbookId[e]);
        removeFromArray(original, tasksByRunbookId[e]);
        removeFromArray(critical, tasksByRunbookId[e]);
      });
    } else if (black.includes(task)) {
      //black.push(task)
      removeFromArray(original, task);
      removeFromArray(critical, task);
    } else if (functional.criticalPath && task.criticalPath) {
      critical.push(task);
      removeFromArray(original, task);
    } else {
      original.push(task);
    }

    task.dependencies.forEach((e: string) => {
      if (clickedOriginal[e] || clickedEffective[e]) {
        black.push(task);
        removeFromArray(original, task);
        removeFromArray(critical, task);
      }
    });
    //aTask++;
  }

  //console.log(black);
  //console.log(original);

  original.forEach((e: TaskDataProps) => {
    let { r, v } = drawTask(
      ctx,
      filterData,
      e,
      activeTasks,
      false,
      tasksStyle,
      graphicWidth,
      ganttStyle,
      functional,
    );

    r && rRectangles.push(r);
    v && vRectangles.push(v);
  });
  critical.forEach((e: TaskDataProps) => {
    let { r, v } = drawTask(
      ctx,
      filterData,
      e,
      activeTasks,
      false,
      tasksStyle,
      graphicWidth,
      ganttStyle,
      functional,
    );

    r && rRectangles.push(r);
    v && vRectangles.push(v);
  });
  black.forEach((e: TaskDataProps) => {
    if (e) {
      let { r, v } = drawTask(
        ctx,
        filterData,
        e,
        activeTasks,
        true,
        tasksStyle,
        graphicWidth,
        ganttStyle,
        functional,
      );

      r && rRectangles.push(r);
      v && vRectangles.push(v);
    }
  });
  drawDependencies(
    ctx,
    filterData,
    tasksByRunbookId,
    activeTasks,
    ganttStyle,
    graphicWidth,
    tasksStyle,
    dependenciesStyle,
    clickedEffective,
    clickedOriginal,
  );

  //rRectangles.sort((a, b) => a.y - b.y);
  //vRectangles.sort((a, b) => a.y - b.y);
  //daw graphic end
  ctx.fillStyle = "white";
  ctx.fillRect(graphicWidth, 0, width - graphicWidth, ganttStyle.lineHeight * activeTasks.length);
  if (imageLoaded) {
    drawLastEndImage(
      ctx,
      tasks,
      tasksByRunbookId,
      activeTasks,
      filterData,
      tasksStyle,
      graphicWidth,
      ganttStyle,
      endImageStyle,
      image,
    );
  }
}
