import {
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useContext, useEffect, useState } from "react";
import { Colors } from "../../constants/colors";
import { useNavigate } from "react-router-dom";
import { authContext } from "../../context/auth";
import { PATHS } from "../../utils/paths";

type AssignmentStudentsTableProps = {
  students?: any[];
};

const rowsPerPageOptions = [5, 10, 25];
const rowHeight = 48; // Adjust this value according to your table row height

type Order = "asc" | "desc";


const orderBy = (data: any[], property: any, order: Order) => {
  return order === "asc"
    ? [...data].sort((a, b) => (a[property] > b[property] ? 1 : -1))
    : [...data].sort((a, b) => (a[property] < b[property] ? 1 : -1));
};

const AssignmentStudentsTable = (props: AssignmentStudentsTableProps) => {
  const {students} = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [orderByColumn, setOrderByColumn] = useState<string>("username");
  const [order, setOrder] = useState<Order>("asc");

  const { user } = useContext(authContext);
  const navigate = useNavigate();
  
  const md = useMediaQuery("(min-width:700px)");

  const totalRowCount = students?.length || 0;
  const currentPageRowCount =
    page === Math.floor(totalRowCount / rowsPerPage)
      ? totalRowCount % rowsPerPage || rowsPerPage
      : rowsPerPage;

  const emptyRowCount = Math.max(rowsPerPage - currentPageRowCount, 0);
  const emptyRowsHeight = `${emptyRowCount * rowHeight}px`;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property: any, order: Order) => {
    setOrder(order);
    setOrderByColumn(property);
  };

  return (
    <div className="relative w-[80%] m-auto shadow-lg ">
      <TableContainer className="hide-scrollbar rounded-md">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className="bg-edilizia-default text-white font-bold"
                align="center"
                onClick={() => handleRequestSort("username", order)}
              >
                NOME
              </TableCell>
              <TableCell
                className="bg-edilizia-default text-white font-bold w-[30px]"
                align="center"
                onClick={() => handleRequestSort("deliveredAt", order)}
              >
                CONSEGNATA
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? orderBy(students || [], orderByColumn, order).slice(
                  page * rowsPerPage,
                  page * rowsPerPage + currentPageRowCount
                )
              : students
            )?.map((students, index) => (
              <TableRow
                key={"students" + index}
                className="hover:bg-[rgba(0,0,0,0.1)] hover:cursor-pointer"
                onClick={() => {
                  const pathRole = "/" + user?.role;
                  navigate(pathRole + PATHS.viewStudentsAssignment + "/" + students.assignmentId + "/" + students.id)

            }}
              >
                <TableCell align="center">{students.username}</TableCell>
                <TableCell align="center" className={`${(students.deliveredAt && students.expiration && (students.deliveredAt && new Date(students.deliveredAt).getTime()) > new Date(students.expiration).getTime()) ? `text-red-500` : ``}`}>{students.deliveredAt?students.deliveredAt.toLocaleDateString():"No, in corso"}</TableCell>
              </TableRow>
            ))}
            <TableRow style={{ height: emptyRowsHeight }}>
              <TableCell colSpan={3} />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div className="bg-edilizia-default rounded-b-md flex justify-between items-center px-4">
        <TablePagination
          className="p-0 m-0 hide-scrollbar inline-block"
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={totalRowCount}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={md ? `Righe` : ``}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={
            !md
              ? {
                  ".MuiTablePagination-toolbar": {
                    margin: 0,
                    padding: 0,
                    color: "white",
                  },
                  ".MuiInputBase-root": {
                    margin: 0,
                    padding: 0,
                  },
                  "&& .MuiTablePagination-actions": {
                    margin: 0,
                    padding: 0,
                    color: "white",
                  },
                  "&& .MuiTablePagination-displayedRows": {
                    display: "none",
                  },
                }
              : {}
          }
          style={{
            backgroundColor: Colors.edilizia.default,
            color: "white",
          }}
          SelectProps={{
            style: {
              color: "white",
              border: "none", // Remove border
            },
            MenuProps: {
              style: {
                color: "black",
              },
            },
          }}
        />
        <Select
          className="p-0 m-0 bg-edilizia-default inline-block items-right"
          value={orderByColumn + "-" + order}
          onChange={(e) => {
            const args = e.target.value.split("-");
            const selectedColumn = args[0] as any;
            // Toggle order if the same column is selected; otherwise, set to 'asc'
            const newOrder = args[1] as Order;
            handleRequestSort(selectedColumn, newOrder);
          }}
          inputProps={{ 'aria-label': 'Order By' }}
          sx={{
            boxShadow: 'none',
            color: "white",
            ".MuiOutlinedInput-notchedOutline": { border: 0 },
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
            {
              border: 0,
            },
            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: 0,
            },
          }} // Remove border
        >
          <MenuItem value="username-desc">Nome &#x21E9;</MenuItem>
          <MenuItem value="username-asc">Nome &#x21E7;</MenuItem>
          <MenuItem value="deliveredAt-desc">Consegnato &#x21E9;</MenuItem>
          <MenuItem value="deliveredAt-asc">Consegnato &#x21E7;</MenuItem>
        </Select>
      </div>
    </div>
  );
};

export default AssignmentStudentsTable;
