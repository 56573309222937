export const PATHS = {
  login: "/auth/login",
  home: "/home",
  professor: "/professor",
  professors: "/professors",
  admin: "/admin",
  student: "/student",
  students: "/students",
  assignmentStudents: "/assignment/students",
  makeAssignment: "/make-assignment",
  viewProfAssignment: "/prof-assignment",
  viewStudentsAssignment: "/students-assignment",
};
