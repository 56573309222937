

export type status = {
  color?: string;
  icon?: string;
};

export enum line {
  horizontal,
  vertical,
}

/**
 * @param {string} runbookId unique idenfier of the specific runbook
 * @param {string} monitorId unique idenfier of monitor
 * @param {string} description a description of the task
 * @param {number} assumedDuration how long should the task take
 * @param {number} effectiveDuration how long did the task actually take
 * @param {number} startDelay task start delay
 * @param {number} executionDelay delay in task execution
 * @param {number} entityDelay ---------TODO-----------
 * @param {string} note ---------TODO-----------
 * @param {string} macroStep ---------TODO-----------
 * @param {string} step ---------TODO-----------
 * @param {date} createdAt when the task was created
 * @param {date} updatedAt last time the task was updated
 * @param {string} contactPersonId  ---------TODO-----------
 * @param {date} originalStart the assumed start of the task
 * @param {date} originalEnd the assumed end of the task
 * @param {date} effectiveStart the start of the task
 * @param {date} effectiveEnd the end of the task
 * @param {status} status the status of the task: complete, run, await or ready
 * @param {number} position ---------TODO-----------
 * @param {string} owner the person responsible to complete the task
 */
export type TaskDataProps = {
  runbookId: string;
  originalStart: Date;
  originalEnd: Date;
  effectiveStart: Date;
  effectiveEnd: Date;
  status: string;
  dependencies: string[];
  criticalPath: boolean;
};

/** 
* @param {string} fontSize the font size for the two column of the gantt
* @param {string} lineWidth the width of the two column
* @param {string} distanceBetweenText the distance between the two column
* @param {number} lineHeight the height of each line
* @param {number} bgColor1 the color of the even lines (0, 2, ...)
* @param {number} bgColor2 the color of the odd lines (1, 3, ...)
* @param {number} statusColor the colors of each status: 
    statusColor:
        {
            complete: string;
            run: string;
            await: string;
            ready: string;
        }
* @param {number} tasksColor the colors of the gantt task: 
    tasksColor: {
        originalTime: string;
        effectiveTime: string;
        selected: string;
        criticalPath: string;
    }
*/

export type GanttStyleProps = {
  fontSize: number;
  fontStyle: string;
  lineWidth: number;
  strokeColor: string;
  lineHeight: number;
  bgColor1: string;
  bgColor2: string;
  status: { [key: string]: status };

  /*
    statusColor: {
        complete: string;
        run: string;
        await: string;
        ready: string;
    };*/
  width: string | number;
  height: string | number;
};

export type GanttTasksProps = {
  tasksHeight: number;
  taskCorner: number;
  onclickColor: string;
  tasksColor: {
    originalTime: string;
    effectiveTime: string;
    selected: string;
    criticalPath: string;
  };

  originalTasks: boolean;
  effectiveTasks: boolean;
};
export type GanttFilterProps = {
  filterMax: number;
  filterFontSize: number;
  filterFontColor: string;
  filterFontStyle: string;
  filterSliderDistance: number;
  filterGanttDistance: number;
  filterDateDistance: number;
  filterMarks: boolean | [{ value: number; keys?: string }];
  filterHeight: number;
  filterThumbSize: number;
  filterStep?: number;
  filterThumbColor?: string;
  filterTrackColor?: string;
  filterRailColor?: string;
  filterTextN: number;
  filterLineN: number;
  filterTextValues: number[];
  filterLineValues: number[];
};
export type GanttNowProps = {
  nowLineWidth: number;
  nowLineColor: string;
  nowTriangleHeight: number;
  nowTriangleWidth: number;
  nowText: string;
  nowTextMarginTop: number;
  nowTextMarginBottom: number;
  nowFontSize: number;
  nowFontStyle: string;
  nowTimeout?: number;
  nowTime?: number;
};
export type GanttLegendProps = {
  legendCircleRadius: number;
  legendCircleStrockeWidth: number;
  legendCircleStrockeColor: string;
  legendTextColor: string;
  legendText1: string;
  legendText2: string;
  legendFontStyle: string;
  legendFontSize: number;
  legendMarginTop: number;
};
export type GanttEndImageProps = {
  endImageURL: string;
  endImageHeight: number;
  endImageWidth: number;
};
export type GanttColumnProps = {
  columnHeaderFontStyle: string;
  columnHeaderFontSize: number;
  columnHeaderFontColor: string;
  distanceBetweenText: number;
};
export type GanttDependenciesProps = {
  dependenciesWidth: number;
  dependenciesColor: string;
};
export type GanttExpandFiltersProps = {
  expandFilterSize: number;
  expandFilterColor: string;
  expandFilterLineWidth: number;
  expandFilterTextDistance: number;
};
export type GanttFunctionalProps = {
  bloccoRiquadri: boolean;

  criticalPath: boolean;

  socket: boolean;

  MouseInTooltip: (
    id: string,
    x: number,
    y: number,
    width: number,
    height: number,
    scrollPosition: number,
    yStart: number,
  ) => void;
  MouseOutTooltip: () => void;
};

export type CalculatedStyleProps = {
  legendHeight: number;
};
export type FilterData = {
  start: Date;
  end: Date;
  actualStart: Date;
  actualEnd: Date;
  minRange: number;
  //minGranulation: number;
  //lineInterval: number;
};

export type ExcellTaskDataProps = {
  runbookId: string;
  originalStart: Date;
  originalEnd: Date;
  effectiveStart: Date;
  effectiveEnd: Date;
  status: string;
  dependencies: string;
  criticalPath: boolean;
};
