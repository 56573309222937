import { DownloadOutlined, DeleteOutline } from "@mui/icons-material";
import { Button, IconButton, Menu, MenuItem, useMediaQuery } from "@mui/material";
import { VerbaleDTO } from "../../types/verbale";
import { download } from "../../utils/common";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useEffect, useState } from "react";

export const VerbaleCard = (props: {
  verbale: VerbaleDTO;
  assignId?: string;
  setVerbali: (value: VerbaleDTO[]) => void;
  setDetailsViewVerbale: (value: VerbaleDTO | undefined) => void;
  handleDelete: (id: string) => void;
  handleEdit: Function;
  idVerbale: string;
  edit: boolean;
}) => {

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const mediaQuery1: boolean = useMediaQuery('(max-width: 480px)')
  const mediaQuery2: boolean = useMediaQuery('(max-width: 728px)')

  useEffect(() => {
    if(!mediaQuery1) setMenuAnchorEl(null);
  }, [mediaQuery1])

  return (
    <div className="p-2 px-4 border border-edilizia-default rounded-3xl mb-4 w-[60%] m-auto">

      {mediaQuery1 && props.edit ? (
      <div className="flex justify-between">
        <div className="text-lg py-2 font-bold text-start w-full text-tecnologia-default hover:cursor-pointer" onClick={() => props.setDetailsViewVerbale(props.verbale)}>
          {new Date(props.verbale.date).toLocaleDateString()}
        </div>

          <IconButton
            onClick={(e) => {
              setMenuAnchorEl(e.currentTarget)
            }}
          >
            <MoreVertIcon className="text-edilizia-default"></MoreVertIcon>
          </IconButton>

          <Menu
            className="absolute"
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={() => setMenuAnchorEl(null)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
          <MenuItem onClick={() => { download(props.verbale.document, props.verbale.name) }}>
            Scarica
          </MenuItem>
          <MenuItem onClick={e => props.handleEdit(props.verbale.date, props.verbale.id)}
          >
            Modifica
            </MenuItem>
            <MenuItem
            onClick={() => {
              props.handleDelete(props.verbale.id);
            }}
          >
            Elimina
            </MenuItem>

      </Menu>
      </div>

      ) : mediaQuery2 ? ( 
      <div className="flex justify-between">
        <div className="text-lg py-2 font-bold text-start w-full text-tecnologia-default hover:cursor-pointer" onClick={() => props.setDetailsViewVerbale(props.verbale)}>
          {new Date(props.verbale.date).toLocaleDateString()}
        </div>
        <div className="flex">
          <IconButton
            onClick={() => { download(props.verbale.document, props.verbale.name) }}
          >
            <DownloadOutlined className="text-edilizia-default"></DownloadOutlined>
          </IconButton>
           {props.edit &&<IconButton onClick={e => props.handleEdit(props.verbale.date, props.verbale.id)}
          >
            <EditOutlinedIcon className="text-edilizia-default"></EditOutlinedIcon>
          </IconButton>}
              {props.edit &&<IconButton
            onClick={() => {
              props.handleDelete(props.verbale.id);
            }}
          >
            <DeleteOutline className="text-edilizia-default"></DeleteOutline>
          </IconButton>}
        </div>
      </div> 
        ) : (
        <div className="flex w-full">
          <div className="text-lg py-2 pr-2 font-bold text-start w-[110px] text-tecnologia-default">
            {new Date(props.verbale.date).toLocaleDateString()}
          </div>
          <div className="justify-start w-[110px] truncate text-lg py-2 font-bold text-start pr-2 underline text-tecnologia-default hover:cursor-pointer" onClick={() => download(props.verbale.document, props.verbale.name)}>
            {props.verbale.name}
          </div>
          <div className="flex-grow justify-start truncate text-lg py-2 font-bold text-start text-tecnologia-default hover:cursor-pointer" onClick={() => props.setDetailsViewVerbale(props.verbale)}>
            {props.verbale.tasks.map((task, i) => {return props.verbale.tasks.length === i+1 ? task.name : task.name + ", "})}
          </div>
          <div className="flex ml-auto"> {/* Utilize ml-auto to move the icon buttons to the end */}
          {props.edit &&  <IconButton onClick={e => props.handleEdit(props.verbale.date, props.verbale.id)}>
              <EditOutlinedIcon className="text-edilizia-default" />
            </IconButton>
            }
            {props.edit && <IconButton onClick={() => {
              props.handleDelete(props.verbale.id);
            }}>
              <DeleteOutline className="text-edilizia-default" />
            </IconButton>}
          </div>
        </div>

      )}
    </div>
  );
};
