import { image } from "html2canvas/dist/types/css/types/image";
import {
  FilterData,
  GanttColumnProps,
  GanttDependenciesProps,
  GanttEndImageProps,
  GanttExpandFiltersProps,
  GanttFilterProps,
  GanttFunctionalProps,
  GanttNowProps,
  GanttStyleProps,
  GanttTasksProps,
  TaskDataProps,
  line,
  status,
} from "../types/types";
import {
  getLastEndIndex,
  getTextSize,
  removeFromArray,
  calculateFillStyle,
  changeResolution,
  getSenseTiming,
} from "./GeneralFunctions";
import { Height, Task } from "@mui/icons-material";
import { drawText, drawStraightLine } from "./DrawGeneralFunctions";

/**
 * disegna le colonne e ritorna le informazioni su quella dei task per il tooltip
 * @return { {id: string; x: number; y: number; width: number; height: number; }[] }
 * @param { CanvasRenderingContext2D } ctx - context 2d del canvas
 * @param { GanttStyleProps } ganttStyle - stile generico del gantt
 * @param { GanttStyleProps } columnStyle - stile delle colonne degli id e degli stati dei tasks
 * @param { TaskDataProps[] } activeTasks - array con i tasks dopo essere stati filtrati
 * @param { number } maxWidth - larghezza massima tra gli id dei task e l'header
 * @param { number } statusWidth - larghezza massima tra gli stati dei task e l'header
 */
export function drawColumns(
  canvas: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D,
  ganttStyle: GanttStyleProps,
  columnStyle: GanttColumnProps,
  activeTasks: TaskDataProps[],
  maxWidth: number,
  statusWidth: number,
): { id: string; x: number; y: number; width: number; height: number }[] {
  ctx.setLineDash([5, 5]);
  const c: { id: string; x: number; y: number; width: number; height: number }[] = [];

  if (!canvas) return c;
  if (!ctx) return c;
  let aTask = 0;
  for (const task of activeTasks) {
    if (aTask & 0x01) var bgColor = ganttStyle.bgColor2;
    else var bgColor = ganttStyle.bgColor1;

    // Set the background color based on the calculated bgColor
    ctx.fillStyle = bgColor;
    ctx.fillRect(0, ganttStyle.lineHeight * aTask, canvas.width, ganttStyle.lineHeight);
    ctx.closePath();
    // Draw horizontal lines at the start and end
    const lineY2 = ganttStyle.lineHeight + ganttStyle.lineHeight * aTask; // Y position of the second horizontal line

    ctx.strokeStyle = ganttStyle.strokeColor; // Set line color

    const distanceFromStart = columnStyle.distanceBetweenText / 2; // Adjust as needed

    let text1 = task.runbookId;

    // Draw text fields
    let text1Size = getTextSize(ctx, text1, ganttStyle.fontSize + "px " + ganttStyle.fontStyle);
    let text1Width = text1Size.textWidth;
    let text1Height = text1Size.textHeight;

    // Calculate the adjusted Y coordinate for text
    let text1AdjustedY = text1Height / 2 + ganttStyle.lineHeight / 2;
    let text1Y = ganttStyle.lineHeight * aTask + text1AdjustedY;
    const text1X = distanceFromStart + (maxWidth - text1Width) / 2;
    drawText(
      ctx,
      text1,
      text1X,
      text1Y,
      ganttStyle.fontSize + "px " + ganttStyle.fontStyle,
      "black",
    );

    c.push({
      id: task.runbookId,
      x: 0,
      y: ganttStyle.lineHeight * aTask,
      width: canvas.width - statusWidth - columnStyle.distanceBetweenText,
      height: ganttStyle.lineHeight,
    });

    let text2 = task.status;
    let text2Size = getTextSize(
      ctx,
      text2,
      ganttStyle.fontSize + "px " + ganttStyle.fontStyle + " bold",
    );
    let text2Width = text2Size.textWidth;
    let text2Height = text2Size.textHeight;

    let text2AdjustedY = text2Height / 2 + ganttStyle.lineHeight / 2;

    let text2Y = ganttStyle.lineHeight * aTask + text2AdjustedY;
    // Calculate text X positions with constant distances
    const text2X =
      text1X +
      text1Width +
      (maxWidth - text1Width) / 2 +
      columnStyle.distanceBetweenText +
      (statusWidth - text2Width) / 2; //;

    drawText(ctx, text2, text2X, text2Y, undefined, calculateFillStyle(text2, ganttStyle.status));

    // Draw the horizontal line
    let lineWidth = maxWidth + 2 * columnStyle.distanceBetweenText + statusWidth;
    drawStraightLine(
      ctx,
      0,
      lineY2,
      lineWidth,
      line.horizontal,
      ganttStyle.lineWidth,
      ganttStyle.strokeColor,
    );

    aTask++;
  }

  ctx.setLineDash([]);
  //setColumnTasks(c);
  return c;
}
