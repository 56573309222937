import React, { useContext, useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { authContext } from "../../context/auth";
import PersonIcon from "@mui/icons-material/Person";
import {
  PATHS,
} from "../../utils/paths";
import { RoleNames } from "../../utils/roles";
import LoginIcon from '@mui/icons-material/Login';

import Logo from '../../assets/images/logo/logo.png'


const CustomNavbar = () => {
  const { user, logout } = useContext(authContext);
  const navigate = useNavigate();

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const navigateLogin = () => {
    navigate(PATHS.login);
  };
  const navigateStudents = (basePath: string) => {
    navigate(basePath + PATHS.students);
  };

  const navigateProfessors = () => {
    navigate(PATHS.admin + PATHS.professors);
  };

  return (
    <div >
      <AppBar
        className="static top-0 bg-white align-middle "
      >
        <Toolbar>
          <div className="text-2xl p-2">
            <img src={Logo} width={"150px"} alt="Logo"></img>
          </div>
          <Box
            className="absolute right-8  align-middle"
          >
            {user ? (
              <IconButton
                className="my-auto"
                onClick={(event) => setMenuAnchorEl(event.currentTarget)}
              >
                <PersonIcon fontSize="large" className="text-black" />
              </IconButton>
            ) : (
              <IconButton
                className="my-auto"
                onClick={navigateLogin}
              >
                <LoginIcon className="text-black" fontSize="large" />
              </IconButton>
            )}
          </Box>

        </Toolbar>
      </AppBar>

      <Menu
        className="absolute"
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {(user?.role === RoleNames.PROFESSOR || user?.role === RoleNames.ADMIN) && (
          <MenuItem onClick={() => {
            navigateStudents(user?.role === RoleNames.PROFESSOR ? PATHS.professor : PATHS.admin);
          }}>
            Studenti
          </MenuItem>
        )}

        {(user?.role === RoleNames.ADMIN) && (
          <MenuItem onClick={() => {
            navigateProfessors();
          }}>
            Professori
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            navigateLogin();
            logout();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export default CustomNavbar;
