import { image } from "html2canvas/dist/types/css/types/image";
import {
  FilterData,
  GanttColumnProps,
  GanttDependenciesProps,
  GanttEndImageProps,
  GanttExpandFiltersProps,
  GanttFilterProps,
  GanttFunctionalProps,
  GanttNowProps,
  GanttStyleProps,
  GanttTasksProps,
  TaskDataProps,
  line,
  status,
} from "../types/types";
import {
  getLastEndIndex,
  getTextSize,
  removeFromArray,
  calculateFillStyle,
  changeResolution,
  getSenseTiming,
} from "./GeneralFunctions";
import { Height, Task } from "@mui/icons-material";

/**
 * disegna un testo in un canvas
 * @return {void}
 * @param { CanvasRenderingContext2D } ctx - context 2d del canvas
 * @param { string } text - testo da disegnare nel canvas
 * @param { number } x - coordinata sull'asse delle x del testo
 * @param { number } y - coordinata sull'asse delle y del testo
 * @param { string } style - stile del testo
 * @param { string } color - colore del testo
 */
export function drawText(
  ctx: CanvasRenderingContext2D,
  text: string,
  x: number,
  y: number,
  style?: string,
  color?: string,
): void {
  if (color) ctx.fillStyle = color;
  if (style) ctx.font = style;
  ctx.fillText(text, x, y); // Adjust the Y coordinate as needed
}

/**
 * disegna un segmento di una linea retta in un canvas
 * @return {void}
 * @param { CanvasRenderingContext2D } ctx - context 2d del canvas
 * @param { number } x - coordinata sull'asse delle x dell'inizio della linea
 * @param { number } y - coordinata sull'asse delle y dell'inizio della linea
 * @param { number } width - lunghezza della linea
 * @param { number } c - orientamento della linea (orizzontale o verticale)
 * @param { number } strokeWidth - larghezza della linea
 * @param { string } style - stile dela linea
 */
export function drawStraightLine(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  c: line,
  strokeWidth?: number,
  style?: string,
): void {
  ctx.beginPath();
  if (strokeWidth) ctx.lineWidth = strokeWidth; // Set line width
  if (style) ctx.strokeStyle = style; // Set line color
  switch (c) {
    case line.horizontal:
      ctx.moveTo(x, y);
      ctx.lineTo(x + width, y);
      break;
    case line.vertical:
      ctx.moveTo(x, y);
      ctx.lineTo(x, y + width);
      break;
  }
  ctx.stroke();
  ctx.closePath();
}
