import { PageOptions } from "../types/api";
import { AssignmentCreateDTO, AssignmentUpdateDTO } from "../types/assignment";
import api from "./api";

export const listAssignments = (pageOptions?: PageOptions) => {
  return api.get("/assignment", { params: pageOptions });
};

export const listAssignmentsPerStudent = (pageOptions?: PageOptions) => {
  return api.get("/assignment-composition/assignments", {
    params: pageOptions,
  });
};

export const getAssignment = (id: string) => {
  return api.get(`/assignment/${id}`);
};

export const createAssignment = (newAssignment: AssignmentCreateDTO) => {
  return api.post("/assignment", newAssignment);
};

export const updateAssignment = ({
  id,
  updatedAssignment,
}: {
  id: string;
  updatedAssignment: Partial<AssignmentUpdateDTO>;
}) => {
  return api.put(`/assignment/${id}`, updatedAssignment);
};

export const deleteAssignment = (id: string) => {
  return api.delete(`/assignment/${id}`);
};
