import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Container, IconButton, InputBase } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ClearCard from '../../components/Card/ClearCard';
import CustomTextField from '../../components/TextField/CustomTextField';
import { authContext } from '../../context/auth';
import { createStudent, deleteStudent, listStudents, updateStudent } from '../../models/student';
import { UserUpdateDTO } from '../../types/user';
import { PATHS } from '../../utils/paths';
import { RoleNames } from '../../utils/roles';

export interface Student {
  id: string;
  name: string;
}

const AddStudentForm: React.FC<{ onSubmit: () => void, studentToEdit?: Student }> = ({ onSubmit, studentToEdit }) => {
  const [username, setUsername] = useState<string>(studentToEdit ? studentToEdit.name : '');
  const [password, setPassword] = useState<string>('');
  const [fakePassword, setFakePassword] = useState<boolean>(true);
  const [errorLog] = useState<boolean>(false);

  const handleAddStudent = async () => {
    const newStudent: UserUpdateDTO = {
      username,
      password,
      role: RoleNames.STUDENT,
    };
    try {
      await createStudent(newStudent);
      onSubmit();
    } catch (e) {
      console.log(e);
    }
  };
  const handleEditStudent = async () => {
    if (!studentToEdit)
      return;
    const updatedStudent: Partial<UserUpdateDTO> = password === "fakepassword" || password === "" ?
      { username }
      :
      { username, password }
    try {
      await updateStudent({
        id: studentToEdit?.id,
        updatedStudent
      });
      onSubmit();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container className="w-[80%] sm:w-[400px] relative">
      <ClearCard title={studentToEdit ? "Modifica Studente" : "Aggiungi Studente"}>
        <form onSubmit={(e) => {
          e.preventDefault();
          studentToEdit ? handleEditStudent() : handleAddStudent();
        }}>
          <div className='absolute top-[-40px] right-[-15px]'>
            <IconButton onClick={onSubmit}>
              <ClearIcon className='text-tecnologia-default' />
            </IconButton>
          </div>
          <div className="p-[40px]">
            <div className="mb-16">
              <CustomTextField
                title={"Nome"}
                error={errorLog}
                value={username}
                setValue={setUsername}
                type={'username'}
              />
            </div>
            <div>
              <CustomTextField
                title={"Password"}
                error={errorLog}
                type={'password'}
                setValue={(e) => {
                  if (fakePassword)
                    setPassword("");
                  else
                    setPassword(e);
                  setFakePassword(false);
                }}
                value={studentToEdit && fakePassword ? "fakepassword" : password}
              />
            </div>
          </div>
          <div className="text-center p-4">
            <Button
              className="bg-gradient-to-r from-accademia-default via-tecnologia-default to-edilizia-default text-white font-bold disabled:from-accademia-disabled disabled:via-tecnologia-disabled disabled:to-edilizia-disabled"
              size="medium"
              color="info"
              variant="contained"
              disabled={!studentToEdit && !(username !== "" && password !== "")}
              type="submit"
            >
              {studentToEdit ? "Modifica" : "Crea"}
            </Button>
          </div>
        </form>
      </ClearCard>
    </Container>
  );
};

const DeleteStudent: React.FC<{ onSubmit: () => void, studentToDelete: Student }> = ({ onSubmit, studentToDelete }) => {
  if (!studentToDelete)
    return;
  const handleDeleteStudent = async () => {
    try {
      await deleteStudent(studentToDelete?.id);
      onSubmit();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container className="w-[80%] sm:w-[400px] relative">
      <ClearCard title={`Eliminare lo studente: ${studentToDelete.name}?`}>
        <div className='absolute top-[-40px] right-[-15px]'>
          <IconButton onClick={onSubmit}>
            <ClearIcon className='text-tecnologia-default' />
          </IconButton>
        </div>
        <div className="text-center p-4">
          <Button
            className="bg-gradient-to-r from-accademia-default via-tecnologia-default to-edilizia-default text-white font-bold disabled:from-accademia-disabled disabled:via-tecnologia-disabled disabled:to-edilizia-disabled"
            size="medium"
            color="info"
            variant="contained"
            disabled={false}
            onClick={handleDeleteStudent}
          >
            {"Elimina"}
          </Button>
        </div>
      </ClearCard>
    </Container>
  );
};

const StudentsPage: React.FC = () => {
  const [students, setStudents] = useState<Student[]>([]);
  const [filteredStudents, setFilteredStudents] = useState<Student[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [addingStudent, setAddingStudent] = useState<boolean>(false);
  const [editingStudent, setEditingStudent] = useState<Student | undefined>();
  const [deletingStudent, setDeletingStudent] = useState<Student | undefined>();


  const { user } = useContext(authContext);

  const navigateBack = useNavigate();
  const fetchStudents = async () => {
    try {
      const response = await listStudents();
      setStudents(response.data.data.map((student: any) => ({ id: student.id, name: student.username })));
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };

  useEffect(() => {
    fetchStudents();
  }, []);

  useEffect(() => {
    setFilteredStudents(students.filter(student =>
      student.name.toLowerCase().includes(searchTerm.toLowerCase())));
  }, [students, searchTerm]);

  const handleEditStudent = (student: Student) => {
    setEditingStudent(student);
    setAddingStudent(true);
  };

  const handleDeleteStudent = (student: Student) => {
    setDeletingStudent(student);
  };

  return (
    <>
      <IconButton className='text-edilizia-default' onClick={() => { user?.role === RoleNames.PROFESSOR ? navigateBack(PATHS.professor + PATHS.home) : navigateBack(PATHS.admin + PATHS.home) }}>
        <ArrowCircleLeftOutlinedIcon></ArrowCircleLeftOutlinedIcon>
      </IconButton>

      <div className={`m-auto text-center w-[100%] ${addingStudent || deletingStudent ? "opacity-[20%]" : ""}`}>
        <div className='text-2xl font-bold text-tecnologia-default p-8 m-auto'>
          LISTA DEGLI STUDENTI
        </div>
        {/*
                <InputBase
                    className={`bg-edilizia-default my-4 w-[80%] text-white px-8 py-1 shadow-lg ${addingStudent || deletingStudent ? "disabled" : ""}`}
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search"
                    inputProps={{ 'aria-label': 'search' }}
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                />
                */}
        <InputBase
          className={`border-edilizia-default text-edilizia-default border-2 rounded-full my-4 w-[80%] px-8 py-1 shadow-lg ${addingStudent || deletingStudent ? "disabled" : ""}`}
          sx={{ ml: 1, flex: 1 }}
          placeholder="Cerca"
          inputProps={{ 'aria-label': 'search' }}
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />

        <div className='m-auto text-center'>
          <div className='w-[40%] m-auto shadow-lg h-[50vh] overflow-scroll hide-scrollbar'>

            {filteredStudents.map((student, index) => (
              <div className={`py-4 flex justify-between items-center ${addingStudent || deletingStudent ? "" : "hover:cursor-pointer hover:bg-[rgba(0,0,0,0.1)]"} `} key={index}>
                <div className='sm:ml-10 text-edilizia-default inline-block'>
                  {student.name}
                </div>
                <div className='flex items-center sm:mr-5'>
                  <IconButton className='sm:mr-5' onClick={() => handleEditStudent(student)}>
                    <EditIcon className='text-edilizia-default'></EditIcon>
                  </IconButton>
                  <IconButton onClick={() => handleDeleteStudent(student)}>
                    <DeleteIcon className='text-edilizia-default'></DeleteIcon>
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
        </div>

        <Button
          className={`my-8 bg-gradient-to-r from-accademia-default  ${addingStudent || deletingStudent ? " disabled" : ""} via-tecnologia-default to-edilizia-default text-white font-bold disabled:from-accademia-disabled disabled:via-tecnologia-disabled disabled:to-edilizia-disabled`}
          size="medium"
          color="info"
          variant="contained"
          sx={{ borderRadius: 0 }}
          onClick={() => setAddingStudent(true)}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              setAddingStudent(true);
            }
          }}
        >
          AGGIUNGI STUDENTE
        </Button>

      </div>
      {addingStudent && (
        <div className="p-4 absolute top-1/4 h-screen w-screen m-auto shadow-lg">
          <AddStudentForm
            onSubmit={() => {
              fetchStudents();
              setAddingStudent(false);
              setEditingStudent(undefined);
            }}
            studentToEdit={editingStudent}
          />
        </div>
      )}
      {deletingStudent && (
        <div className="p-4 absolute top-1/4 h-screen w-screen m-auto shadow-lg">
          <DeleteStudent
            onSubmit={() => {
              fetchStudents();
              setDeletingStudent(undefined);
            }}
            studentToDelete={deletingStudent} />
        </div>
      )}
    </>
  );

};

export default StudentsPage;
