import React, { useContext, useEffect } from "react";
import CustomNavbar from "../NavBar/CustomNavbar";
import { Navigate, Route, Routes } from "react-router-dom";
import { PATHS } from "../../utils/paths";
import { authContext } from "../../context/auth";
import { getMe } from "../../models/user";
import Login from "../../pages/Auth";
import { RoleNames } from "../../utils/roles";
import HomePageProfessor from "../../pages/Professor";
import HomePageStudent from "../../pages/Student";
import StudentsPage from "../../pages/Students";
import MakeAssignment from "../../pages/MakeAssignment";
import ProfessorsPage from "../../pages/Professors";
import AssignmentStudentsPage from "../../pages/Assignment/AssignmentStudents";
import ViewAssignment from "../../pages/ViewAssignment";

function RoutesCustom() {
  const { user, setUser, token } = useContext(authContext);

  useEffect(() => {
    const lToken = window.localStorage.getItem("token");
    const lRefresh = window.localStorage.getItem("refresh");
    if (!user && lToken && lRefresh) {
      getMe().then((res) => {
        setUser(res.data);
      });
    }
  }, []);

  return (
    <div>
      <CustomNavbar />
      <Routes>
        {!user && !token ? (
          <Route path="/" element={<Navigate to={PATHS.login} />} />
        ) : user?.role === RoleNames.ADMIN ? (
          <Route
            path="/"
            element={<Navigate to={PATHS.admin + PATHS.home} />}
          />
        ) : user?.role === RoleNames.PROFESSOR ? (
          <Route
            path="/"
            element={<Navigate to={PATHS.professor + PATHS.home} />}
          />
        ) : user?.role === RoleNames.STUDENT ? (
          <Route path="/" element={<Navigate to={PATHS.login} />} />
        ) : (
          <>
            {/* TODO: better mode to handle */}
            ERROR ROLE
          </>
        )}

        {/* <Route path="/" element={<Navigate to={PATHS.login} />} /> */}
      <Route path={PATHS.login} element={<Login />} />

        {user && (
          <>
            {user?.role === RoleNames.ADMIN && (
              <>
                <Route
                  path={PATHS.admin + PATHS.home}
                  element={<HomePageProfessor />}
                />
                <Route
                  path={PATHS.admin + PATHS.students}
                  element={<StudentsPage />}
                />
                <Route
                  path={PATHS.admin + PATHS.professors}
                  element={<ProfessorsPage />}
                />

                <Route
                  path={PATHS.admin + PATHS.makeAssignment + "/:assignId"}
                  element={<MakeAssignment />}/>
                <Route
                  path={PATHS.admin + PATHS. assignmentStudents + "/:assignId" }
                  element={
                    <AssignmentStudentsPage/>
                  }
                />
                <Route
                  path={PATHS.admin + PATHS.viewStudentsAssignment + "/:assignId/:userId"}
                  element={<ViewAssignment />}
                />
              </>
            )}

            {user?.role === RoleNames.PROFESSOR && (
              <>
                <Route
                  path={PATHS.professor + PATHS.home}
                  element={<HomePageProfessor />}
                />
                <Route
                  path={PATHS.professor + PATHS.students}
                  element={<StudentsPage />}
                />
                <Route
                  path={PATHS.professor + PATHS.makeAssignment + "/:assignId"}
                  element={<MakeAssignment />}
                />
                <Route
                  path={PATHS.professor + PATHS.makeAssignment + "/:assignId"}
                  element={<MakeAssignment />} />
                <Route
                  path={PATHS.professor + PATHS. assignmentStudents + "/:assignId"}
                  element={
                    <AssignmentStudentsPage/>
                  }
                />
                <Route
                  path={PATHS.professor + PATHS.viewStudentsAssignment + "/:assignId/:userId"}
                  element={<ViewAssignment />}
                />
              </>
            )}

            {user?.role === RoleNames.STUDENT && (
              <>
                <Route
                  path={PATHS.student + PATHS.home}
                  element={<HomePageStudent />}
                />
                <Route
                  path={PATHS.student + PATHS.makeAssignment + "/:assignId"}
                  element={<MakeAssignment />}
                />
                <Route
                  path={PATHS.student + PATHS.viewProfAssignment + "/:assignId/:userId"}
                  element={<ViewAssignment />}
                />
              </>
            )}
          </>
        )}
      </Routes>
    </div>
  );
}

export default RoutesCustom;
