import React, { useState, ChangeEvent } from "react";
import * as XLSX from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";
import { IconButton } from "@mui/material";

interface ExcelDownloadProps {
  jsonData: any[];
  fileName?: string;
  color: string;
}
const generateExcelFromJson = (jsonData: any[], fileName: string) => {
  let jsonDataExcell = [];
  if (jsonData.length > 0) {
    jsonDataExcell = jsonData.map((e: any) => {
      return {
        runbookId: e.name ? e.name : e.runbookId,
        originalStart: e.originalStart,
        originalEnd: e.originalEnd,
        effectiveStart: e.effectiveStart,
        effectiveEnd: e.effectiveEnd,
        status: e.status,
        dependencies: e.dependencies ? e.dependencies.toString():"",
        macroTask: e.macroTask, // Add macroTask column
        note: e.note, // Add note column
      };
    });
  } else {
    // Create an empty object with keys matching the expected column names
    jsonDataExcell.push({
      runbookId: "",
      originalStart: "",
      originalEnd: "",
      effectiveStart: "",
      effectiveEnd: "",
      status: "",
      dependencies: [],
      macroTask: "", // Add macroTask column
      note: "", // Add note column
    });
  }

  const worksheet = XLSX.utils.json_to_sheet(jsonDataExcell);

  // Set the data type for each column (including string arrays)
  worksheet["!cols"] =
    jsonData.length > 0 ? Object.keys(jsonData[0]).map((_) => ({ wch: 20 })) : [];

  // Remove the criticalPath column if it exists
  delete worksheet["!cols"];

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const link = document.createElement("a");
  link.href = URL.createObjectURL(data);
  link.download = fileName;
  link.click();
};


const ExportExcel: React.FC<ExcelDownloadProps> = ({ jsonData, fileName, color }) => {
  const handleDownload = () => {
    generateExcelFromJson(jsonData, (fileName || "download") + ".xlsx");
  };



  return (
    <div>
      <DownloadIcon
        style={{ cursor: "pointer", color: color }}
        onClick={handleDownload}
      ></DownloadIcon>
    </div>
  );
};

export default ExportExcel;
