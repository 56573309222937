
type ColorsType = {
    [key: string]: { [key: string]: string }
}

export const Colors: ColorsType = {
    "accademia": {
        "default": "#f8b334",
        "disabled": "rgba(248, 179, 52, 0.5)"
    },
    "tecnologia": {
        "default": "#97be0e",
        "disabled": "rgba(151, 190, 14, 0.5)"
    },
    "edilizia": {
        "default": "#019edf",
        "disabled": "rgba(1, 158, 223, 0.5)"
    }
}
