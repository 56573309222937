import { Buffer } from "buffer";

const base64ToFile = (dataurl: string, filename: string) => {
  var arr = dataurl.split(',');
  var mime = arr[0].match(/:(.*?);/)?.[1] || '';
  var bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

const download = (verbaleDocument: any, filename: string) => {
  const buffer = Buffer.from(verbaleDocument.data);
  const base64String = buffer.toString();
  const file = base64ToFile(base64String, filename);
  const url = window.URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  link.click();
}

export { base64ToFile, download }
