import React, { useContext, useState } from "react";
import { Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Select, MenuItem, TableFooter, IconButton } from "@mui/material";
import { AssignmentDTO } from "../../types/assignment";
import { Colors } from "../../constants/colors";
import { ArrowDropUp, ArrowDropDown, Edit } from "@mui/icons-material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../utils/paths";
import { authContext } from "../../context/auth";
import { RoleNames } from "../../utils/roles";

type AssignmentTableProps = {
    assignments?: AssignmentDTO[];
    setAssignment: (assignment: AssignmentDTO) => void;
    setEditing: (toEdit: boolean) => void;
};

const rowsPerPageOptions = [5, 10, 25];
const rowHeight = 73; // Adjust this value according to your table row height


type Order = 'asc' | 'desc';

const orderBy = (data: AssignmentDTO[], property: keyof AssignmentDTO, order: Order) => {
    const ordered = order === 'asc'
        ? [...data].sort((a, b) => (new Date(a[property]) > new Date(b[property]) ? 1 : -1))
        : [...data].sort((a, b) => (new Date(a[property]) < new Date(b[property]) ? 1 : -1));
    return ordered;
};

const AssignmentTableProfessor = (props: AssignmentTableProps) => {
    const { assignments, setAssignment, setEditing } = props;


    const { user } = useContext(authContext);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [orderByColumn, setOrderByColumn] = useState<keyof AssignmentDTO>('createdAt');
    const [order, setOrder] = useState<Order>('desc');

    const md = useMediaQuery('(min-width:700px)');

    const totalRowCount = assignments?.length || 0;

    const currentPageRowCount = totalRowCount > 0 ? ((page === Math.floor(totalRowCount / rowsPerPage)) ? (totalRowCount % rowsPerPage || rowsPerPage) : rowsPerPage) : 0;

    const emptyRowCount = Math.max(rowsPerPage - currentPageRowCount, 0);
    const emptyRowsHeight = `${emptyRowCount * rowHeight}px`;

    const navigate = useNavigate();


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (property: keyof AssignmentDTO, order: Order) => {
        setOrder(order);
        setOrderByColumn(property);
    };

    return (
        <div className="relative w-[80%] m-auto shadow-lg ">
            <TableContainer className="hide-scrollbar rounded-t-md">
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell className="bg-edilizia-default text-white font-bold" align="center" >
                                ESERCITAZIONE
                            </TableCell>
                            <TableCell className="bg-edilizia-default text-white font-bold w-[30px]" align="center">
                                CREAZIONE
                            </TableCell>
                            <TableCell className="bg-edilizia-default text-white font-bold w-[30px]" align="center">
                                SCADENZA
                            </TableCell>
                            <TableCell className="bg-edilizia-default text-white font-bold w-[30px]" align="center">
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(orderByColumn && order && rowsPerPage > 0
                            ? orderBy(assignments || [], orderByColumn, order).slice(page * rowsPerPage, page * rowsPerPage + currentPageRowCount)
                            : []
                        )?.map((assignment, index) => (
                            <TableRow key={"assignment" + index}  className="hover:bg-[rgba(0,0,0,0.1)] hover:cursor-pointer">
                                <TableCell align="center" onClick={() => {
                                    user?.role === RoleNames.PROFESSOR ? navigate(PATHS.professor + PATHS.assignmentStudents + "/" + assignment.id) : navigate(PATHS.admin + PATHS.assignmentStudents + "/" + assignment.id)
                                }}>{assignment.name}</TableCell>
                                <TableCell align="center" onClick={() => {
                                    user?.role === RoleNames.PROFESSOR ? navigate(PATHS.professor + PATHS.assignmentStudents + "/" + assignment.id) : navigate(PATHS.admin + PATHS.assignmentStudents + "/" + assignment.id)
                                }}>{new Date(assignment.createdAt).toLocaleDateString()}</TableCell>
                                <TableCell align="center" onClick={() => {
                                    user?.role === RoleNames.PROFESSOR ? navigate(PATHS.professor + PATHS.assignmentStudents + "/" + assignment.id) : navigate(PATHS.admin + PATHS.assignmentStudents + "/" + assignment.id)
                                }}>{assignment.expiration ? new Date(assignment.expiration).toLocaleDateString() : ""}</TableCell>
                                <TableCell align="center">
                                    <IconButton className="text-tecnologia-default" onClick={() => { setAssignment(assignment); setEditing(true); }}>
                                        <Edit />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        {parseInt(emptyRowsHeight) > 0 &&
                            <TableRow style={{ height: emptyRowsHeight }}>
                                <TableCell colSpan={4} />
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="bg-edilizia-default rounded-b-md flex justify-between items-center px-4">
                <TablePagination
                    className="p-0 m-0 hide-scrollbar inline-block"
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={totalRowCount}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage={md ? `Righe` : ``}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={!md ? {
                        '.MuiTablePagination-toolbar': {
                            margin: 0,
                            padding: 0,
                            color: 'white',
                        },
                        '.MuiInputBase-root': {
                            margin: 0,
                            padding: 0,
                        },
                        '&& .MuiTablePagination-actions': {
                            margin: 0,
                            padding: 0,
                            color: 'white',
                        },
                        '&& .MuiTablePagination-displayedRows': {
                            display: "none"
                        },
                    } : {
                    }}
                    style={{
                        backgroundColor: Colors.edilizia.default,
                        color: 'white',
                    }}
                    SelectProps={{
                        style: {
                            color: 'white',
                            border: 'none', // Remove border
                        },
                        MenuProps: {
                            style: {
                                color: 'black'
                            }
                        }
                    }}
                />
                <Select
                    className="p-0 m-0 bg-edilizia-default inline-block items-right"
                    value={orderByColumn + "-" + order}
                    onChange={(e) => {
                        const args = e.target.value.split("-");
                        const selectedColumn = args[0] as keyof AssignmentDTO;
                        // Toggle order if the same column is selected; otherwise, set to 'asc'
                        const newOrder = args[1] as Order;
                        handleRequestSort(selectedColumn, newOrder);
                    }}
                    inputProps={{ 'aria-label': 'Order By' }}
                    sx={{
                        boxShadow: 'none',
                        color: "white",
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                            border: 0,
                        },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                            border: 0,
                        },
                    }} // Remove border
                >
                    <MenuItem value="createdAt-desc">Creazione &#x21E9;</MenuItem>
                    <MenuItem value="createdAt-asc">Creazione &#x21E7;</MenuItem>
                    <MenuItem value="expiration-desc">Scadenza &#x21E9;</MenuItem>
                    <MenuItem value="expiration-asc">Scadenza &#x21E7;</MenuItem>
                </Select>


            </div>

        </div>
    );
};

export default AssignmentTableProfessor;
