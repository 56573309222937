import { authContext } from "../context/auth";
import api from "./api";

const login = (body: any) => {
  return api.post("/auth/login", body).then((res: any) => {
    api.defaults.headers.common["Authorization"] = `Bearer ${res.data.token}`;
    localStorage.setItem("token", res.data.authorization);
    localStorage.setItem("refresh", res.data.refresh);
    return res;
  });
};

const refresh = (refresh: string) => {
  return api.post("/auth/refresh", { token: refresh });
};

const login_secondary = (assignmentId: string) => {
  return api
    .post("/auth/secondary-login-student?assignmentId=" + assignmentId)
    .then((res: any) => {
      api.defaults.headers.common["Authorization"] = `Bearer ${res.data.token}`;

      localStorage.setItem("token", res.data.authorization);
      localStorage.setItem("refresh", res.data.refresh);
      return res;
    })
};

const refresh_secondary = (refresh: string, assignmentId: string) => {
  return api.post(
    "/auth/secondary-refresh-student?assignmentId=" + assignmentId,
    { token: refresh }
  );
};

export { login, refresh, login_secondary, refresh_secondary };
