import { VerbaleUpdateDTO, VerbaleCreateDTO, VerbaleDTO } from "../types/verbale";
import api from "./api";

export const listVerbali = (
    page = 1,
    status = '',
    q = ''
) => {
    return api.get('/verbale', { params: { page, status, q } });
}

export const listVerbaliByAssignmentAndUser = async (
    assignmentId: string,
    userId: string,
    page = 1,
    status = '',
    q = ''
): Promise<VerbaleDTO[]> => {
    return (await api.get(`/verbale/by-assignment-and-user/${assignmentId}/${userId}`, { params: { page, status, q } })).data;
}

export const getVerbale = async (id: string) => {
    return await api.get(`/verbale/${id}`);
}

export const updateVerbale = async (id: string, newVerbale: VerbaleUpdateDTO) => {
    return await api.put(`/verbale/${id}`, newVerbale);
}

export const createVerbale = async (newVerbale: VerbaleCreateDTO) => {
    return await api.post('/verbale', newVerbale);
}

export const deleteVerbale = async (id: string) => {
    return await api.delete(`/verbale/${id}`);
}
