import React, { useEffect, useState } from "react";
import { TextField, Box, Button, makeStyles, styled } from "@mui/material";
import { Colors } from "../../constants/colors";

type CustomTextFieldProps = {
  title?: string;
  error: boolean;
  type: string;
  value?: string;
  setValue: (value: string)=>void;
};

const StyledTextField = styled(TextField)(({ theme }) => ({

    '& label': {
      color: "black",
    },
    '& label.Mui-focused': {
      color: "black",
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: "black",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: "black",
      },
      '&:hover fieldset': {
        borderColor: "black",
      },
      '&.Mui-focused fieldset': {
        borderColor: "black",
      },
    },
}));
const CustomTextField = (props: CustomTextFieldProps) => {
  const {
    title,
    error,
    type,
    value,
    setValue
  } = props;
  const [errorField, setErrorField] = useState<boolean>(error);

  useEffect(()=>{
    value && type.toLowerCase() !== "password" && setValue(value);
  },[])

  return (
    <Box
      component="div"
    >
        {!error ? (
          <StyledTextField
          variant="outlined"
            label={title}
            size="small"
            type={type}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            value={value}
          />
        ) : (
          <StyledTextField
            className="text-field-red"
            label={title}
            size="small"
            type={type}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            value={value}
          />
        )}

    </Box>
  );
};

export default CustomTextField;
