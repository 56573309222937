import React, { ReactElement } from "react";
import { Card, CardContent, Box } from "@mui/material";

type ClearCardProps = {
  title: string;
  children: ReactElement | ReactElement[];
};

const ClearCard = (props: ClearCardProps) => {
  const { title, children } = props;

  return (
    <div className="shadow-2xl text-center bg-white">
        {title && <h1 className="text-black text-center font-bold text-4xl py-4">{title}</h1>}
        {children}
    </div>
  );
};

export default ClearCard;
