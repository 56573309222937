import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { login, refresh } from "../../models/auth";
import { authContext } from "../../context/auth";
import { getMe } from "../../models/user";
import { Box, Button, Container } from "@mui/material";
import ClearCard from "../../components/Card/ClearCard";
import CustomTextField from "../../components/TextField/CustomTextField";
import { RoleNames } from "../../utils/roles";
import { PATHS } from "../../utils/paths";
import api from "../../models/api";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:3001";

function Login() {
  const navigate = useNavigate();
  const { setToken, setRefresh, setUser, user, token } =
    useContext(authContext);

  const [errorLog, setErrorLog] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  useEffect(() => {
    if (token) {
      if (user?.role === RoleNames.ADMIN) {
        navigate(PATHS.admin + PATHS.home);
      } else if (user?.role === RoleNames.PROFESSOR) {
        navigate(PATHS.professor + PATHS.home);
      } else if (user?.role === RoleNames.STUDENT) {
        navigate(PATHS.student + PATHS.home);
      }
    }
  }, []);

  const handleLogin = async (user: string, pass: string) => {
    

      try{
        const res = await login({user, pass});

        if (res.status === 201) {
          const json = res.data;
          setToken(json.authorization);
          setRefresh(json.refresh);
          api.defaults.headers.common['Authorization'] = `Bearer ${json.authorization}`;
          getMe()
            .then((res) => {
              setUser(res.data);
              if (res.data?.role === RoleNames.ADMIN) {
                navigate(PATHS.admin + PATHS.home);
              } else if (res.data?.role === RoleNames.PROFESSOR) {
                navigate(PATHS.professor + PATHS.home);
              } else if (res.data?.role === RoleNames.STUDENT) {
                navigate(PATHS.student + PATHS.home);
              }
            });
        }
      }
      catch(e){
        setErrorLog(true);
      }
  };

  return (
    <div
      className="flex"
    >
      <Container className="w-[80%] sm:w-[400px] mt-[100px]"
      >
        <ClearCard title="Login">
          <form onSubmit={(e) => {
            e.preventDefault();
            handleLogin(username, password);
          }}>

            <div className="p-[40px]">
              <div className="mb-16">
                <CustomTextField
                  title={"Nome"}
                  error={errorLog}
                  type="username"
                  setValue={setUsername}
                />
              </div>
              <div className="">
                <CustomTextField
                  title={"Password"}
                  error={errorLog}
                  type="password"
                  setValue={setPassword}
                />
              </div>
            </div>
            <Box className="text-center p-4">
              <Button
                className="bg-gradient-to-r from-accademia-default via-tecnologia-default to-edilizia-default text-white font-bold disabled:from-accademia-disabled disabled:via-tecnologia-disabled disabled:to-edilizia-disabled"
                size="medium"
                color="info"
                variant="contained"
                disabled={!(username !== "" && password !== "")}
                type="submit"
                sx={{ borderRadius: 0 }}
              >
                ACCEDI
              </Button>
            </Box>

          </form>
        </ClearCard>
      </Container>
    </div>
  );
}

export default Login;
