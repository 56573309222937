import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Container, IconButton, InputBase } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ClearCard from '../../components/Card/ClearCard';
import CustomTextField from '../../components/TextField/CustomTextField';
import { createProfessor, deleteProfessor, listProfessors, updateProfessor } from '../../models/professor';
import { UserUpdateDTO } from '../../types/user';
import { PATHS } from '../../utils/paths';
import { RoleNames } from '../../utils/roles';

export interface Professor {
  id: string;
  name: string;
}

const AddProfessorForm: React.FC<{ onSubmit: () => void, professorToEdit?: Professor }> = ({ onSubmit, professorToEdit }) => {
  const [username, setUsername] = useState<string>(professorToEdit ? professorToEdit.name : '');
  const [password, setPassword] = useState<string>('');
  const [fakePassword, setFakePassword] = useState<boolean>(true);
  const [errorLog] = useState<boolean>(false);

  const handleAddProfessor = async () => {
    const newProfessor: UserUpdateDTO = {
      username,
      password,
      role: RoleNames.PROFESSOR,
    };
    try {
      await createProfessor(newProfessor);
      onSubmit();
    } catch (e) {
      console.log(e);
    }
  };
  const handleEditProfessor = async () => {
    if (!professorToEdit)
      return;
    const updatedProfessor: Partial<UserUpdateDTO> = password === "fakepassword" || password === "" ?
      { username }
      :
      { username, password }
    try {
      await updateProfessor({
        id: professorToEdit?.id,
        updatedProfessor
      });
      onSubmit();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container className="w-[80%] sm:w-[400px] relative">
      <ClearCard title={professorToEdit ? "Modifica Professore" : "Aggiungi Professore"}>
        <form onSubmit={(e) => {
          e.preventDefault();
          professorToEdit ? handleEditProfessor() : handleAddProfessor();
        }}>
          <div className='absolute top-[-40px] right-[-15px]'>
            <IconButton onClick={onSubmit}>
              <ClearIcon className='text-tecnologia-default' />
            </IconButton>
          </div>
          <div className="p-[40px]">
            <div className="mb-16">
              <CustomTextField
                title={"Nome"}
                error={errorLog}
                value={username}
                setValue={setUsername}
                type={'username'}
              />
            </div>
            <div>
              <CustomTextField
                title={"Password"}
                error={errorLog}
                type={'password'}
                setValue={(e) => {
                  if (fakePassword)
                    setPassword("");
                  else
                    setPassword(e);
                  setFakePassword(false);
                }}
                value={professorToEdit && fakePassword ? "fakepassword" : password}
              />
            </div>
          </div>
          <div className="text-center p-4">
            <Button
              className="bg-gradient-to-r from-accademia-default via-tecnologia-default to-edilizia-default text-white font-bold disabled:from-accademia-disabled disabled:via-tecnologia-disabled disabled:to-edilizia-disabled"
              size="medium"
              color="info"
              variant="contained"
              type="submit"
              disabled={!professorToEdit && !(username !== "" && password !== "")}
            >
              {professorToEdit ? "Modifica" : "Crea"}
            </Button>
          </div>
        </form>
      </ClearCard>
    </Container>
  );
};

const DeleteProfessor: React.FC<{ onSubmit: () => void, professorToDelete: Professor }> = ({ onSubmit, professorToDelete }) => {
  if (!professorToDelete)
    return;
  const handleDeleteProfessor = async () => {
    try {
      await deleteProfessor(professorToDelete?.id);
      onSubmit();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container className="w-[80%] sm:w-[400px] relative">
      <ClearCard title={`Eliminare il professore: ${professorToDelete.name}?`}>
        <div className='absolute top-[-40px] right-[-15px]'>
          <IconButton onClick={onSubmit}>
            <ClearIcon className='text-tecnologia-default' />
          </IconButton>
        </div>
        <div className="text-center p-4">
          <Button
            className="bg-gradient-to-r from-accademia-default via-tecnologia-default to-edilizia-default text-white font-bold disabled:from-accademia-disabled disabled:via-tecnologia-disabled disabled:to-edilizia-disabled"
            size="medium"
            color="info"
            variant="contained"
            disabled={false}
            type="submit"
          >
            {"Elimina"}
          </Button>
        </div>
      </ClearCard>
    </Container>
  );
};

const ProfessorsPage: React.FC = () => {
  const [professors, setProfessors] = useState<Professor[]>([]);
  const [filteredProfessors, setFilteredProfessors] = useState<Professor[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [addingProfessor, setAddingProfessor] = useState<boolean>(false);
  const [editingProfessor, setEditingProfessor] = useState<Professor | undefined>();
  const [deletingProfessor, setDeletingProfessor] = useState<Professor | undefined>();

  const navigateBack = useNavigate();
  const fetchProfessors = async () => {
    try {
      const response = await listProfessors();
      setProfessors(response.data.map((professor: any) => ({ id: professor.id, name: professor.username })));
    } catch (error) {
      console.error("Error fetching professors:", error);
    }
  };

  useEffect(() => {
    fetchProfessors();
  }, []);

  useEffect(() => {
    setFilteredProfessors(professors.filter(professor =>
      professor.name.toLowerCase().includes(searchTerm.toLowerCase())));
  }, [professors, searchTerm]);

  const handleEditProfessor = (professor: Professor) => {
    setEditingProfessor(professor);
    setAddingProfessor(true);
  };

  const handleDeleteProfessor = (professor: Professor) => {
    setDeletingProfessor(professor);
  };

  return (
    <>
      <IconButton className='text-edilizia-default' onClick={() => navigateBack(PATHS.admin + PATHS.home)}>
        <ArrowCircleLeftOutlinedIcon></ArrowCircleLeftOutlinedIcon>
      </IconButton>

      <div className={`m-auto text-center w-[100%] ${addingProfessor || deletingProfessor ? "opacity-[20%]" : ""}`}>
        <div className='text-2xl font-bold text-tecnologia-default p-8 m-auto'>
          LISTA DEI PROFESSORI
        </div>
        {/*
                <InputBase
                    className={`bg-edilizia-default my-4 w-[80%] text-white px-8 py-1 shadow-lg ${addingStudent || deletingStudent ? "disabled" : ""}`}
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search"
                    inputProps={{ 'aria-label': 'search' }}
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                />
                */}
        <InputBase
          className={`border-edilizia-default text-edilizia-default border-2 rounded-full my-4 w-[80%] px-8 py-1 shadow-lg ${addingProfessor || deletingProfessor ? "disabled" : ""}`}
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search"
          inputProps={{ 'aria-label': 'search' }}
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />

        <div className='m-auto text-center'>
          <div className='w-[40%] m-auto shadow-lg h-[50vh] overflow-scroll hide-scrollbar'>

            {filteredProfessors.map((professor, index) => (
              <div className={`py-4 flex justify-between items-center ${addingProfessor || deletingProfessor ? "" : "hover:cursor-pointer hover:bg-[rgba(0,0,0,0.1)]"} `} key={index}>
                <div className='sm:ml-10 text-edilizia-default inline-block'>
                  {professor.name}
                </div>
                <div className='flex items-center sm:mr-5'>
                  <IconButton className='sm:mr-5' onClick={() => handleEditProfessor(professor)}>
                    <EditIcon className='text-edilizia-default'></EditIcon>
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
        </div>

        <Button
          className={`my-8 bg-gradient-to-r from-accademia-default  ${addingProfessor || deletingProfessor ? " disabled" : ""} via-tecnologia-default to-edilizia-default text-white font-bold disabled:from-accademia-disabled disabled:via-tecnologia-disabled disabled:to-edilizia-disabled`}
          size="medium"
          color="info"
          variant="contained"
          sx={{ borderRadius: 0 }}
          onClick={() => setAddingProfessor(true)}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              setAddingProfessor(true);
            }
          }}
        >
          AGGIUNGI PROFESSORE
        </Button>

      </div>
      {addingProfessor && (
        <div className="p-4 absolute top-1/4 h-screen w-screen m-auto shadow-lg">
          <AddProfessorForm
            onSubmit={() => {
              fetchProfessors();
              setAddingProfessor(false);
              setEditingProfessor(undefined);
            }}
            professorToEdit={editingProfessor}
          />
        </div>
      )}
      {deletingProfessor && (
        <div className="p-4 absolute top-1/4 h-screen w-screen m-auto shadow-lg">
          <DeleteProfessor
            onSubmit={() => {
              fetchProfessors();
              setDeletingProfessor(undefined);
            }}
            professorToDelete={deletingProfessor} />
        </div>
      )}
    </>
  );

};

export default ProfessorsPage;
