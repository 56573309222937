import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  FilterData,
  GanttColumnProps,
  GanttEndImageProps,
  GanttExpandFiltersProps,
  GanttFilterProps,
  GanttNowProps,
  GanttStyleProps,
  GanttTasksProps,
  TaskDataProps,
  status,
} from "../types/types";
import React, { useRef } from "react";

/**
 * calcola l'altezza del testo della legenda del gantt
 */
export function getLegendTextHeight(
  legendText1Ref: React.MutableRefObject<HTMLDivElement | null>,
  legendText2Ref: React.MutableRefObject<HTMLDivElement | null>,
): number {
  const div1Height = legendText1Ref.current?.scrollHeight;
  const div2Height = legendText2Ref.current?.scrollHeight;
  return Math.max(div1Height || 0, div2Height || 0);
}

/**
 * scarica il gantt come png
 */
export function downloadAsPng(
  graphContainerRef: React.MutableRefObject<HTMLDivElement | null>,
  name?: string,
): void {
  // Capture the HTML content and convert it into a canvas
  html2canvas(graphContainerRef.current!).then((canvas) => {
    // Convert the canvas to a data URL
    const dataURL = canvas.toDataURL("image/png");

    // Create a link and trigger the download
    const linkRef = useRef<HTMLAnchorElement | null>(null);
    const link = React.createElement("a", {
      href: dataURL,
      rel: "noopener noreferrer",
      download: name ? name + ".png" : "monitor.png",
      ref: linkRef,
    });
    // Simulate a click event without appending to the document
    linkRef?.current?.click();
    // Clean up by revoking the data URL
    URL.revokeObjectURL(dataURL);
  });
}

/**
 * scarica il gantt come pdf
 */
export function downloadAsPDF(
  graphContainerRef: React.MutableRefObject<HTMLDivElement | null>,
  name?: string,
): void {
  // Capture the HTML content and convert it into a canvas
  html2canvas(graphContainerRef.current!).then((canvas) => {
    // Convert the canvas to a data URL
    const dataURL = canvas.toDataURL("image/png");

    // Create PDF instance
    var pdf = new jsPDF("l", "mm", "a4");

    // Calculate the aspect ratio to maintain the original proportions
    const aspectRatio = canvas.width / canvas.height;

    // Set the width and height of the image in the PDF
    const pdfWidth = 200; // Adjust this value according to your needs
    const pdfHeight = pdfWidth / aspectRatio;

    // Add the image to the PDF
    pdf.addImage(dataURL, "PNG", 10, 10, pdfWidth, pdfHeight);

    // Save the PDF
    name ? pdf.save(name + ".pdf") : pdf.save("download.pdf");
  });
}

/**
 * calcola il colore da utilizzare per lo status del task
 * @param { string } text - il testo dello stato del task
 * @param { {[key: string]: status} } status - array che associa ad ogni stato il suo colore e la sua icona
 */
export function calculateFillStyle(text: string, status: { [key: string]: status }): string {
  return status[text]?.color || "black";
  // code block
  //}
}

/**
 * ritorna un array associativo [id: task] generato dall'array di tasks
 * @param { TaskDataProps[] } tasks - array con tutti i tasks
 */
export function tasksToAssociativeArray(tasks: TaskDataProps[]): { [key: string]: TaskDataProps } {
  // Group tasks by runbookId
  let temp: { [key: string]: TaskDataProps } = {};
  tasks?.forEach((task: TaskDataProps) => {
    const { runbookId } = task;
    temp[runbookId] = task;
  });

  return temp;
}

/**
 * calcola i task attivi considerando i filtri: per data, per task, e per status
 * @return - { byIndex: TaskDataProps[], byRunbookId: { [key: string]: TaskDataProps }, filter: TaskDataProps[] }
 * - ritorna un json che contiene due array di task attivi incrociando i filtri, uno per indice ordinato({ [key: string]: TaskDataProps } byIndex) e uno associativo (TaskDataProps[] byRunbookId)
 * - inoltre nel json ritorna anche un array contenente i task filtrati solo per data
 * @param { TaskDataProps[] } tasks - array con tutti i tasks
 * @param { {[key: string]: boolean} } activeTasksFlags - array di di flags che indica se il task è stato filtrato dal filtro che si espande
 * @param { boolean[] } statusFlags - array con tutti i tasks
 * @param { { [key: string]: status } } status - array con tutti i tasks
 * @param { FilterData } filterData - dati attuali del filtro per data
 */
export function getActiveTasks(
  tasks: TaskDataProps[],
  activeTasksFlags: { [key: string]: boolean },
  statusFlags: boolean[],
  status: { [key: string]: status },
  filterData: FilterData,
): {
  byIndex: TaskDataProps[];
  byRunbookId: { [key: string]: TaskDataProps };
  filter: TaskDataProps[];
} {
  let aTask = 0;
  let aTaskF = 0;

  //array tastk by runbook id
  let atbyri: { [key: string]: TaskDataProps } = {};
  let at: TaskDataProps[] = [];
  let atf: TaskDataProps[] = [];

  for (let i = 0; i < tasks.length; i++) {
    if (
      Math.max(tasks[i].originalEnd.getTime(), tasks[i].effectiveEnd.getTime()) >=
        filterData.actualStart.getTime() &&
      Math.min(tasks[i].originalStart.getTime(), tasks[i].effectiveStart.getTime()) <=
        filterData.actualEnd.getTime()
    ) {
      atf[aTaskF] = tasks[i];
      aTaskF++;
      const taskStatusIndex = Object.keys(status).indexOf(tasks[i].status);
      if (activeTasksFlags[tasks[i].runbookId] && statusFlags[taskStatusIndex]) {
        atbyri[tasks[i].runbookId] = tasks[i];
        at[aTask] = tasks[i];
        aTask++;
      }
    }
  }
  return { byIndex: at, byRunbookId: atbyri, filter: atf };
}

/**
 * calcola l'indice del task che finisce per ultimo, a parità di fine verrà restituito il task che è iniziato dopo
 * @return {number} ritorna quell'indice
 * @param { boolean } effectiveTasks - valore che indica se sono attivi i task effettivi
 * @param { boolean } originalTasks - valore che indica se sono attivi i task virtuali
 * @param { TaskDataProps[] } tasks - array con tutti i tasks
 * @param {  { [key: string]: TaskDataProps } } tasksByRunbookId - array associativo [id: task] con tutti i tasks
 * @param { TaskDataProps[] } activeTasks - array con i tasks dopo essere stati filtrati
 */
export function getLastEndIndex(
  effectiveTasks: boolean,
  originalTasks: boolean,
  tasks: TaskDataProps[],
  tasksByRunbookId: { [key: string]: TaskDataProps },
  activeTasks: TaskDataProps[],
): number {
  let ret = -1;
  let actMax = Number.MIN_SAFE_INTEGER;
  let actStart = Number.MIN_SAFE_INTEGER;
  if (effectiveTasks && originalTasks)
    for (let i = 0; i < tasks.length && tasks[i]; i++) {
      let tempMax = Math.max(tasks[i].effectiveEnd.getTime(), tasks[i].originalEnd.getTime());
      //if same end take first occur of latest start
      let tempStart = Math.max(tasks[i].effectiveStart.getTime(), tasks[i].originalStart.getTime());
      if (tempMax > actMax || (tempMax === actMax && tempStart > actStart)) {
        ret = i;
        actMax = tempMax;
        actStart = tempStart;
      }
    }
  else if (effectiveTasks)
    for (let i = 0; i < tasks.length && tasks[i]; i++) {
      let tempMax = tasks[i].effectiveEnd.getTime();
      //if same end take first occur of latest start
      let tempStart = tasks[i].effectiveStart.getTime();
      if (tempMax > actMax || (tempMax === actMax && tempStart > actStart)) {
        ret = i;
        actMax = tempMax;
        actStart = tempStart;
      }
    }
  else if (originalTasks)
    for (let i = 0; i < tasks.length && tasks[i]; i++) {
      let tempMax = tasks[i].originalEnd.getTime();
      //if same end take first occur of latest start
      let tempStart = tasks[i].originalStart.getTime();
      if (tempMax > actMax || (tempMax === actMax && tempStart > actStart)) {
        ret = i;
        actMax = tempMax;
        actStart = tempStart;
      }
    }
  let endTak = tasksByRunbookId[tasks[ret].runbookId];
  if (!endTak) return -1;
  else return activeTasks.indexOf(endTak);
}
/**
 * calcola il più piccolo valore sensato che crea al più n intervalli nel filtro
 * @return {number} ritorna quel valore, se il range è troppo piccolo ritorna il più piccolo valore presente, se è troppo grande ritorna -1
 * @param { number[] } senseValues - array di numeri che rappresentano l'insieme di valori che la funzione può ritornare
 * @param { number } numLines - massimo numero di intervalli che può essere generato
 * @param { FilterData } filterData - dati attuali del filtro per data
 */
export function getSenseTiming(
  senseValues: number[],
  numLines: number,
  filterData: FilterData,
): number {
  let range = filterData.actualEnd.getTime() - filterData.actualStart.getTime();
  for (const e of senseValues) {
    if (e >= range / numLines) {
      return e; // Return the first value that meets the condition
    }
  }
  return 0;
}

/**
 * calcola l'altezza e la larghezza di un testo in un canvas
 * @return {number} ritorna un json {textWidth: number, textHeight: number} conetenetente rispettivamente larghezza e altezza del testo
 * @param { CanvasRenderingContext2D } ctx - context 2d del canvas
 * @param { string } text - stringa rappresentatnte il testo
 * @param { string } style - stile del testo
 */
export function getTextSize(
  ctx: CanvasRenderingContext2D,
  text: string,
  style?: string,
): { textWidth: number; textHeight: number } {
  if (style) ctx.font = style;
  let metrics = ctx.measureText(text);
  let textWidth = metrics.width;
  let textHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
  return { textWidth, textHeight };
}

/**
 * calcola la massima larghezza tra gli id dei tasks e l'header della colonna dei tasks
 * @return {number} ritorna questa larghezza
 * @param { CanvasRenderingContext2D } ctx - context 2d del canvas
 * @param { TaskDataProps[] } tasks - array con tutti i tasks
 * @param { number } columnHeaderFontSize - stringa rappresentante il font size del testo
 * @param { string } columnHeaderFontStyle - stringa rappresentante lo stile del testo
 * @param { number } expandFilterSize - larghezza del bottone del filtro che si espande
 * @param { number } expandFilterTextDistance - distanza tra il bottone del filtro che si espande e l'header della colonna
 * @param { number } fontSize - stringa rappresentante il font size del testo
 * @param { string } fontStyle - - stringa rappresentante lo stile del testo
 */
export function getMaxWidth(
  ctx: CanvasRenderingContext2D,
  tasks: TaskDataProps[],
  columnHeaderFontSize: number,
  columnHeaderFontStyle: string,
  expandFilterSize: number,
  expandFilterTextDistance: number,
  fontSize: number,
  fontStyle: string,
): number {
  if (!ctx) return 0;
  let text1 = "ID TASK";
  let maxWidth =
    getTextSize(ctx, text1, columnHeaderFontSize + "px " + columnHeaderFontStyle + " bold")
      .textWidth +
    expandFilterSize +
    expandFilterTextDistance;

  ctx.font = fontSize + "px " + fontStyle;

  for (let i = 0; i < tasks.length && tasks[i]; i++) {
    let temp = tasks[i].runbookId;
    let len = getTextSize(ctx, temp).textWidth;
    if (len > maxWidth) maxWidth = len;
  }
  return maxWidth;
}

/**
 * calcola la massima larghezza tra i nomi degli stati e l'header della colonna degli stati
 * @return {number} ritorna questa larghezza
 * @param { CanvasRenderingContext2D } ctx - context 2d del canvas
 * @param { TaskDataProps[] } tasks - array con tutti i tasks
 * @param { number } columnHeaderFontSize - stringa rappresentante il font size del testo
 * @param { string } columnHeaderFontStyle - stringa rappresentante lo stile del testo
 * @param { number } expandFilterSize - larghezza del bottone del filtro che si espande
 * @param { number } expandFilterTextDistance - distanza tra il bottone del filtro che si espande e l'header della colonna
 * @param { number } fontSize - stringa rappresentante il font size del testo
 * @param { string } fontStyle - - stringa rappresentante lo stile del testo
 * @param { {[key: string]: status} } status - array che associa ad ogni stato il suo colore e la sua icona
 */
export function getStatusWidth(
  ctx: CanvasRenderingContext2D,
  tasks: TaskDataProps[],
  columnHeaderFontSize: number,
  columnHeaderFontStyle: string,
  expandFilterSize: number,
  expandFilterTextDistance: number,
  fontSize: number,
  fontStyle: string,
  status: { [key: string]: status },
): number {
  if (!ctx) return 0;
  let text1 = "STATUS";
  let maxWidth =
    getTextSize(ctx, text1, columnHeaderFontSize + "px " + columnHeaderFontStyle + " bold")
      .textWidth +
    expandFilterSize +
    expandFilterTextDistance;

  ctx.font = fontSize + "px " + fontStyle + " bold";

  for (const key in status) {
    let len = getTextSize(ctx, key).textWidth;
    if (len > maxWidth) maxWidth = len;
  }
  for (let i = 0; i < tasks.length && tasks[i]; i++) {}
  return maxWidth;
}

/**
 * cambia  la risoluzione di un canvas di dpr volte
 * @return {number} ritorna la nuova larghezza
 * @param { HTMLCanvasElement } canvas - reference al canvas
 * @param { CanvasRenderingContext2D } ctx - context 2d del canvas
 * @param { number } dpr - numero che rappresenta di quanto scalare la risoluzione del canvas
 * @param { number } w - larghezza del xanvas prima di essere scalato
 */
export function changeResolution(
  canvas: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D,
  dpr: number,
  w: number,
): number {
  if (!ctx) return 0;

  let width = w * dpr;
  // Scale the canvas back down using CSS
  canvas.style.width = `${w}px`;

  canvas.width = width;

  // Scale the drawing context for high-resolution rendering
  ctx.scale(dpr, 1);

  // ... your other drawing code ...

  // Reset the scale to its original state if needed
  ctx.setTransform(1, 0, 0, 1, 0, 0);

  return width;
}

/**
 * rimuove un elemento da un array
 * @return {number} ritorna l'indice dell'elemento rimosso, oppure -1 se l'elemento non è presente
 * @param { any[] } array - array da cui rimuovere l'elemento
 * @param { any } elem - elemento da rimuovere
 */
export function removeFromArray(array: any[], elem: any) {
  const index = array.indexOf(elem);

  if (index !== -1) {
    array.splice(index, 1);
  }

  return index;
}

/**
 * trasforma il valore del thumb in una data
 * @return {string} ritorna una stringa che rappresenta il label del filtro per data
 * @param { number } value - valore attuale del filtro
 * @param { number } activeThumb - thumb selezionato
 * @param { FilterData } filterData - dati attuali del filtro per data
 * @param { GanttFilterProps } filterStyle - massimo valore che il filtro può assumere
 */
export function valueLabelFormat(
  value: number,
  activeThumb: number,
  filterData: FilterData,
  filterStyle: GanttFilterProps,
): string {
  const range = filterData.start.getTime() - filterData.end.getTime();
  if (activeThumb)
    return new Date(
      filterData.start.getTime() - value * (range / filterStyle.filterMax),
    ).toLocaleString("it-IT", { timeZone: "Europe/Rome" });
  else
    return new Date(
      filterData.end.getTime() + (filterStyle.filterMax - value) * (range / filterStyle.filterMax),
    ).toLocaleString("it-IT", { timeZone: "Europe/Rome" });
}

export function handleChangeFilter(
  event: Event,
  newValue: number | number[],
  activeThumb: number,
  filterData: FilterData,
  value1: number[],
): number[] {
  if (!Array.isArray(newValue)) {
    return [-1, -1];
  }

  if (activeThumb === 0) {
    //setValue1([Math.min(newValue[0], value1[1] - filterData.minRange), value1[1]]);
    return [Math.min(newValue[0], value1[1] - filterData.minRange), value1[1]];
  } else {
    // setValue1([value1[0], Math.max(newValue[1], value1[0] + filterData.minRange)]);
    return [value1[0], Math.max(newValue[1], value1[0] + filterData.minRange)];
  }
}

export function updateFilter(
  tasks: TaskDataProps[],
  filterData: FilterData,
  tasksStyle: GanttTasksProps,
  now: number,
  graphicWidth: number,
): FilterData {
  // Ensure filterData is not mutated directly
  let newFilterData = { ...filterData };

  console.log("graphicWidth", graphicWidth);
  // Check if graphicWidth is valid
  if (graphicWidth > 0) {
    // Initialize min and max values
    let minStart = Number.MAX_SAFE_INTEGER;
    let maxEnd = Number.MIN_SAFE_INTEGER;

    // Calculate minStart and maxEnd based on tasks
    tasks.forEach(task => {
      minStart = Math.min(minStart, task.effectiveStart.getTime(), task.originalStart.getTime());
      maxEnd = Math.max(maxEnd, task.effectiveEnd.getTime(), task.originalEnd.getTime());
    });

    // Adjust minStart and maxEnd if now is beyond them
    minStart = Math.min(minStart, now);
    maxEnd = Math.max(maxEnd, now);

    // Calculate range
    const range = maxEnd - minStart;

    // Update filterData based on conditions
    if (
      newFilterData.actualStart.getTime() === newFilterData.actualEnd.getTime() ||
      (newFilterData.actualStart.getTime() === newFilterData.start.getTime() &&
        newFilterData.actualEnd.getTime() === newFilterData.end.getTime())
    ) {
      newFilterData = {
        ...newFilterData,
        start: new Date(minStart - (tasksStyle.tasksHeight * range) / graphicWidth),
        end: new Date(maxEnd + (tasksStyle.tasksHeight * range) / graphicWidth),
        actualStart: new Date(minStart - (tasksStyle.tasksHeight * range) / graphicWidth),
        actualEnd: new Date(maxEnd + (tasksStyle.tasksHeight * range) / graphicWidth),
      };
    } else if (newFilterData.actualStart.getTime() === newFilterData.start.getTime()) {
      newFilterData = {
        ...newFilterData,
        start: new Date(minStart - (tasksStyle.tasksHeight * range) / graphicWidth),
        end: new Date(maxEnd + (tasksStyle.tasksHeight * range) / graphicWidth),
        actualStart: new Date(minStart - (tasksStyle.tasksHeight * range) / graphicWidth),
      };
    } else if (newFilterData.actualEnd.getTime() === newFilterData.end.getTime()) {
      newFilterData = {
        ...newFilterData,
        start: new Date(minStart - (tasksStyle.tasksHeight * range) / graphicWidth),
        end: new Date(maxEnd + (tasksStyle.tasksHeight * range) / graphicWidth),
        actualEnd: new Date(maxEnd + ((tasksStyle.tasksHeight / 2) * range) / graphicWidth),
      };
    } else if (now >= newFilterData.actualEnd.getTime()) {
      newFilterData = {
        ...newFilterData,
        start: new Date(minStart - (tasksStyle.tasksHeight * range) / graphicWidth),
        end: new Date(maxEnd + (tasksStyle.tasksHeight * range) / graphicWidth),
      };
    } else if (now <= newFilterData.actualStart.getTime()) {
      newFilterData = {
        ...newFilterData,
        start: new Date(minStart - (tasksStyle.tasksHeight * range) / graphicWidth),
        end: new Date(maxEnd + (tasksStyle.tasksHeight * range) / graphicWidth),
      };
    }
  }

  return newFilterData;
}


export function updateFw(
  canvas: HTMLCanvasElement,
  tasks: TaskDataProps[],
  acw: number,
  ganttStyle: GanttStyleProps,
  endImageStyle: GanttEndImageProps,
  columnStyle: GanttColumnProps,
  expandFiltersStyle: GanttExpandFiltersProps,
  nowStyle: GanttNowProps,
  nowTextWidth: number,
): { fw: string; fx: string } {
  if (!canvas) return { fw: "0px", fx: "0px" };
  const ctx = canvas.getContext("2d");
  if (!ctx) return { fw: "0px", fx: "0px" };
  ctx.font = ganttStyle.fontSize + "px " + ganttStyle.fontStyle;
  const statusWidth = getStatusWidth(
    ctx,
    tasks,
    columnStyle.columnHeaderFontSize,
    columnStyle.columnHeaderFontStyle,
    expandFiltersStyle.expandFilterSize,
    expandFiltersStyle.expandFilterTextDistance,
    ganttStyle.fontSize,
    ganttStyle.fontStyle,
    ganttStyle.status,
  );
  var maxWidth = getMaxWidth(
    ctx,
    tasks,
    columnStyle.columnHeaderFontSize,
    columnStyle.columnHeaderFontStyle,
    expandFiltersStyle.expandFilterSize,
    expandFiltersStyle.expandFilterTextDistance,
    ganttStyle.fontSize,
    ganttStyle.fontStyle,
  );
  // for increasing resolution (DPR)
  const dpr = 2;

  // Set the canvas resolution to match the screen's pixel density
  const width = acw * dpr;
  var graphicWidth =
    width -
    (maxWidth + 2 * columnStyle.distanceBetweenText + statusWidth) -
    Math.max(endImageStyle.endImageWidth, Math.max(nowStyle.nowTriangleWidth, nowTextWidth) / 2);

  let t1 =
    Math.max(endImageStyle.endImageWidth, Math.max(nowStyle.nowTriangleWidth, nowTextWidth) / 2) /
    2;
  return {
    fw: (graphicWidth / 2).toString() + "px",
    fx: `${t1.toString()}px`,
  };
}

export function calcRect(
  filterData: FilterData,
  activeTasks: TaskDataProps[],
  tasksStyle: GanttTasksProps,
  graphicWidth: number,
  ganttStyle: GanttStyleProps,
): {
  vRectangles: { id: string; x: number; y: number; width: number; height: number }[];
  rRectangles: { id: string; x: number; y: number; width: number; height: number }[];
} {
  let range = filterData.actualEnd.getTime() - filterData.actualStart.getTime();

  let rRectangles: { id: string; x: number; y: number; width: number; height: number }[] = [];
  let vRectangles: { id: string; x: number; y: number; width: number; height: number }[] = [];

  for (const task of activeTasks) {
    let activeOriginal = task.originalStart && task.originalEnd;
    let activeEffective = task.effectiveStart && task.effectiveEnd;

    let originalLength = activeOriginal
      ? task.originalEnd.getTime() - task.originalStart.getTime()
      : 0;
    let originalStart = activeOriginal
      ? task.originalStart.getTime() - filterData.actualStart.getTime()
      : 0;

    let effectiveLength = activeEffective
      ? task.effectiveEnd.getTime() - task.effectiveStart.getTime()
      : 0;
    let effectiveStart = activeEffective
      ? task.effectiveStart.getTime() - filterData.actualStart.getTime()
      : 0;

    let aTask = activeTasks.indexOf(task);
    //console.log(aTask)
    let rectangleY =
      -tasksStyle.tasksHeight / 2 + ganttStyle.lineHeight / 2 + ganttStyle.lineHeight * aTask;
    let milestoneY = ganttStyle.lineHeight / 2 + ganttStyle.lineHeight * aTask;
    //effective task
    let rectangleEffectiveX = (effectiveStart / range) * graphicWidth; //lineX2;
    let rectangleEffectiveWidth = (effectiveLength / range) * graphicWidth;

    //virtual task
    let rectangleVirtualX = (originalStart / range) * graphicWidth; //lineX2;
    let rectangleOriginalWidth = (originalLength / range) * graphicWidth;

    if (tasksStyle.originalTasks) {
      if (rectangleOriginalWidth > 0) {
        vRectangles.push({
          id: task.runbookId,
          x: rectangleVirtualX,
          y: rectangleY,
          width: rectangleOriginalWidth,
          height: tasksStyle.tasksHeight,
        });
      } else {
        // Draw a milestone (diamond shape)
        let size = tasksStyle.tasksHeight / 2;
        vRectangles.push({
          id: task.runbookId,
          x: rectangleVirtualX - size,
          y: milestoneY - size - 5,
          width: 2 * size,
          height: 2 * size + 10,
        });
      }
    }

    if (tasksStyle.effectiveTasks) {
      if (rectangleEffectiveWidth > 0) {
        rRectangles.push({
          id: task.runbookId,
          x: rectangleEffectiveX,
          y: rectangleY,
          width: rectangleEffectiveWidth,
          height: tasksStyle.tasksHeight,
        });
      } else {
        // Draw a milestone (diamond shape)
        let size = tasksStyle.tasksHeight / 2;
        rRectangles.push({
          id: task.runbookId,
          x: rectangleEffectiveX - size,
          y: milestoneY - size - 5,
          width: 2 * size,
          height: 2 * size + 10,
        });
      }
    }
  }

  return { vRectangles, rRectangles };
}
