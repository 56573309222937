import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  FilterData,
  GanttColumnProps,
  GanttEndImageProps,
  GanttExpandFiltersProps,
  GanttFilterProps,
  GanttFunctionalProps,
  GanttNowProps,
  GanttStyleProps,
  GanttTasksProps,
  TaskDataProps,
  status,
} from "../types/types";

export function handleHoverTaskColumn(
  event: any,
  canvas: HTMLCanvasElement,
  filterStyle: GanttFilterProps,
  columnTasks: { id: string; x: number; y: number; width: number; height: number }[],
  filterTextHeight: number,
  functional: GanttFunctionalProps,
  graphRef: React.MutableRefObject<HTMLDivElement | null>,
): number {
  if (!canvas) return 0;
  const mouseX = (event.clientX - canvas.getBoundingClientRect().left) * 2;
  const mouseY = (event.clientY - canvas.getBoundingClientRect().top) * 2;

  const index = handleHitTaskColumn(mouseX, mouseY, columnTasks);
  let yOff =
    filterStyle.filterSliderDistance / 2 +
    filterStyle.filterGanttDistance / 2 +
    filterStyle.filterDateDistance / 2 +
    filterTextHeight / 2 +
    filterStyle.filterHeight +
    50 +
    filterStyle.filterHeight * 4;
  let scrollPosition = 0;
  if (index != -1 && columnTasks[index].id != "") {
    scrollPosition = graphRef.current?.scrollTop || 0;
    functional.MouseInTooltip(
      columnTasks[index].id,
      columnTasks[index].x,
      columnTasks[index].y / 2,
      columnTasks[index].width / 2,
      columnTasks[index].height / 2,
      scrollPosition,
      yOff,
    );

    // style.hoverTooltip(columnTasks[index].id, columnTasks[index].x, columnTasks[index].y, 0, yOff);
    //canvas.style.cursor = 'pointer'; // Change the cursor to indicate a link
  } else {
    // style.hoverOutIdTask();
    //handleMouseOutTaskColumn(canvas, functional);
  }

  //setScrollPosition(scrollPosition);
  event.stopPropagation();
  return scrollPosition;
}

export function handleHitTaskColumn(
  x: number,
  y: number,
  columnTasks: { id: string; x: number; y: number; width: number; height: number }[],
): number {
  let indexSelectedShape = -1;

  for (const rect of columnTasks) {
    if (x >= rect.x && x <= rect.x + rect.width && y >= rect.y && y <= rect.y + rect.height) {
      indexSelectedShape = columnTasks.indexOf(rect);
    }
  }

  return indexSelectedShape;
}

export function handleTaskColumnClick(event: any, canvas: HTMLCanvasElement): void {
  /*   const canvas = canvasColumnsRef.current;
       if (!canvas)
           return;
       const mouseX = (event.clientX - canvas.getBoundingClientRect().left) * 2;
       const mouseY = (event.clientY - canvas.getBoundingClientRect().top) * 2;
 
       const index = handleHitTaskColumn(mouseX, mouseY);
 
       let yOff = filterStyle.filterSliderDistance / 2 + filterStyle.filterGanttDistance / 2 + filterStyle.filterDateDistance / 2 + filterTextHeight / 2 + filterStyle.filterHeight + 50 + filterStyle.filterHeight * 4;
 
       if (index != -1) {
 
           if (columnTasks[index].id != tooltipId) {
               let scrollPosition = document.getElementById("graph")?.scrollTop || 0;
               setTooltipId(columnTasks[index].id);
               setTooltipCoordinates({ x: columnTasks[index].x, y: columnTasks[index].y / 2, yStart: yOff });
               setScrollPosition(scrollPosition);
           }
       }
 
 
       event.stopPropagation();
*/
}

export function handleHoverTask(
  event: any,
  canvas: HTMLCanvasElement,
  tasksStyle: GanttTasksProps,
  effectiveRectangles: { id: string; x: number; y: number; width: number; height: number }[],
  originalRectangles: { id: string; x: number; y: number; width: number; height: number }[],
): void {
  if (!canvas) return;
  const mouseX = (event.clientX - canvas.getBoundingClientRect().left) * 2;
  const mouseY = (event.clientY - canvas.getBoundingClientRect().top) * 2;

  const index = handleHitTask(mouseX, mouseY, tasksStyle, effectiveRectangles, originalRectangles);

  if (index != -1) {
    canvas.style.cursor = "pointer"; // Change the cursor to indicate a link
  } else {
    canvas.style.cursor = "default"; // Reset the cursor
  }

  event.stopPropagation();
}

export function handleHitTask(
  x: number,
  y: number,
  tasksStyle: GanttTasksProps,
  effectiveRectangles: { id: string; x: number; y: number; width: number; height: number }[],
  originalRectangles: { id: string; x: number; y: number; width: number; height: number }[],
): number | string {
  let idSelectedShape: number | string = -1;
  if (tasksStyle.effectiveTasks)
    for (const rect of effectiveRectangles) {
      if (x >= rect.x && x <= rect.x + rect.width && y >= rect.y && y <= rect.y + rect.height) {
        idSelectedShape = rect.id;
      }
    }
  else
    for (const rect of originalRectangles) {
      if (x >= rect.x && x <= rect.x + rect.width && y >= rect.y && y <= rect.y + rect.height) {
        idSelectedShape = rect.id;
      }
    }

  return idSelectedShape;
}

export function handleTaskClick(
  event: any,
  canvas: HTMLCanvasElement,
  tasks: TaskDataProps[],
  tasksStyle: GanttTasksProps,
  effectiveRectangles: { id: string; x: number; y: number; width: number; height: number }[],
  originalRectangles: { id: string; x: number; y: number; width: number; height: number }[],
  clickedEffective: { [key: string]: boolean },
  clickedOriginal: { [key: string]: boolean },
): {
  clickedEffectiveNew: { [key: string]: boolean };
  clickedOriginalNew: { [key: string]: boolean };
} {
  const mouseX = (event.clientX - canvas.getBoundingClientRect().left) * 2;
  const mouseY = (event.clientY - canvas.getBoundingClientRect().top) * 2;

  let clickedOriginalNew: { [key: string]: boolean } = {};
  let clickedEffectiveNew: { [key: string]: boolean } = {};
  if (!canvas) return { clickedEffectiveNew, clickedOriginalNew };

  const temp: { [key: string]: boolean } = tasks.reduce(
    (acc: { [key: string]: boolean }, task: TaskDataProps) => {
      acc[task.runbookId] = false;
      return acc;
    },
    {},
  );
  const allFalse: { [key: string]: boolean } = temp;
  const id = handleHitTask(mouseX, mouseY, tasksStyle, effectiveRectangles, originalRectangles);
  if (tasksStyle.effectiveTasks) {
    if (id != -1) {
      if (!clickedEffective[id]) temp[id] = true;
    }

    clickedOriginalNew = allFalse;
    clickedEffectiveNew = temp;
  } else if (tasksStyle.originalTasks) {
    if (id !== -1) {
      if (!clickedOriginal[id]) temp[id] = true;
    }

    clickedEffectiveNew = allFalse;
    clickedOriginalNew = temp;
  }

  event.stopPropagation();

  return { clickedEffectiveNew, clickedOriginalNew };
}

export function handleMouseOutTask(canvas: HTMLCanvasElement): void {
  if (!canvas) return;
  canvas.style.cursor = "default"; // Reset cursor when mouse leaves canvas
  // You can reset the hover effect here (e.g., revert rectangle color)
}

export function handleMouseOutFilter(canvas: HTMLCanvasElement): void {
  //const canvas = canvasFilterRef.current;
  if (!canvas) return;
  canvas.style.cursor = "default"; // Reset cursor when mouse leaves canvas
  // You can reset the hover effect here (e.g., revert rectangle color)
}
export function handleMouseOutTaskColumn(
  canvas: HTMLCanvasElement,
  functional: GanttFunctionalProps,
): void {
  functional.MouseOutTooltip();
}

export function handleHoverExpandFilter(
  event: any,
  canvas: HTMLCanvasElement,
  expandFilterTask: { x: number; y: number; height: number },
  expandFilterStatus: { x: number; y: number; height: number },
  expandFiltersStyle: GanttExpandFiltersProps,
): void {
  //const canvas = canvasFilterRef.current;
  if (!canvas) return;
  const mouseX = (event.clientX - canvas.getBoundingClientRect().left) * 2;
  const mouseY = (event.clientY - canvas.getBoundingClientRect().top) * 2;

  const taskBoolean =
    mouseX >= expandFilterTask.x &&
    mouseX <= expandFilterTask.x + expandFiltersStyle.expandFilterSize &&
    mouseY >= expandFilterTask.y &&
    mouseY <= expandFilterTask.y + expandFilterTask.height;

  const statusBoolean =
    mouseX >= expandFilterStatus.x &&
    mouseX <= expandFilterStatus.x + expandFiltersStyle.expandFilterSize &&
    mouseY >= expandFilterStatus.y &&
    mouseY <= expandFilterStatus.y + expandFilterStatus.height;

  if (taskBoolean || statusBoolean) {
    canvas.style.cursor = "pointer"; // Change the cursor to indicate a link
  } else {
    canvas.style.cursor = "default"; // Reset the cursor
  }

  event.stopPropagation();
}

export function handleClickExpandFilter(
  event: any,
  canvas: HTMLCanvasElement,
  expandFilterTask: { x: number; y: number; height: number },
  expandFilterStatus: { x: number; y: number; height: number },
  expandFiltersStyle: GanttExpandFiltersProps,
  expandTaskFilterRef: React.MutableRefObject<HTMLDivElement | null>,
  expandStatusFilterRef: React.MutableRefObject<HTMLDivElement | null>,
): void {
  //const canvas = canvasFilterRef.current;
  if (!canvas) return;
  const mouseX = (event.clientX - canvas.getBoundingClientRect().left) * 2;
  const mouseY = (event.clientY - canvas.getBoundingClientRect().top) * 2;

  const taskBoolean =
    mouseX >= expandFilterTask.x &&
    mouseX <= expandFilterTask.x + expandFiltersStyle.expandFilterSize &&
    mouseY >= expandFilterTask.y &&
    mouseY <= expandFilterTask.y + expandFilterTask.height;

  const statusBoolean =
    mouseX >= expandFilterStatus.x &&
    mouseX <= expandFilterStatus.x + expandFiltersStyle.expandFilterSize &&
    mouseY >= expandFilterStatus.y &&
    mouseY <= expandFilterStatus.y + expandFilterStatus.height;

  const expandTaskFilter = expandTaskFilterRef.current;
  const expandStatusFilter = expandStatusFilterRef.current;

  if (!expandTaskFilter) return;
  if (!expandStatusFilter) return;

  if (taskBoolean) {
    expandTaskFilter.classList.toggle("startegois-gantt-hidden");
    expandTaskFilter.classList.toggle("startegois-gantt-visible");

    expandStatusFilter.classList.add("startegois-gantt-hidden");
    expandStatusFilter.classList.remove("startegois-gantt-visible");
  } else if (statusBoolean) {
    expandStatusFilter.classList.toggle("startegois-gantt-hidden");
    expandStatusFilter.classList.toggle("startegois-gantt-visible");

    expandTaskFilter.classList.add("startegois-gantt-hidden");
    expandTaskFilter.classList.remove("startegois-gantt-visible");
  }

  event.stopPropagation();
}
