import { useEffect, useRef, useState } from "react";
import {
  CalculatedStyleProps,
  GanttColumnProps,
  GanttDependenciesProps,
  GanttEndImageProps,
  GanttExpandFiltersProps,
  GanttFilterProps,
  GanttFunctionalProps,
  GanttLegendProps,
  GanttNowProps,
  GanttStyleProps,
  GanttTasksProps,
  TaskDataProps,
} from "../../utils/types/types";
import Tasks from "../Tasks/Tasks";
import { getLegendTextHeight } from "../../utils/functions/GeneralFunctions";

import "./gantt.css";

const Gantt = (props: {
  tasks: TaskDataProps[];
  ganttStyle: GanttStyleProps;
  tasksStyle: GanttTasksProps;
  filterStyle: GanttFilterProps;
  nowStyle: GanttNowProps;
  legendStyle: GanttLegendProps;
  endImageStyle: GanttEndImageProps;
  columnStyle: GanttColumnProps;
  dependenciesStyle: GanttDependenciesProps;
  expandFiltersStyle: GanttExpandFiltersProps;
  functional: GanttFunctionalProps;
}) => {
  const ganttOuterRef = useRef<HTMLDivElement | null>(null);
  const legendText1Ref = useRef<HTMLDivElement | null>(null);
  const legendText2Ref = useRef<HTMLDivElement | null>(null);

  const {
    tasks,
    ganttStyle,
    tasksStyle,
    filterStyle,
    nowStyle,
    legendStyle,
    endImageStyle,
    columnStyle,
    dependenciesStyle,
    expandFiltersStyle,
    functional,
  } = props;
  const [legendHeight, setLegendHeight] = useState<number>(
    Math.max(getLegendTextHeight(legendText1Ref, legendText2Ref), legendStyle.legendCircleRadius),
  );

  const calculatedStyle: CalculatedStyleProps = {
    legendHeight: legendHeight,
  };

  useEffect(() => {
    setLegendHeight(
      Math.max(getLegendTextHeight(legendText1Ref, legendText2Ref), legendStyle.legendCircleRadius),
    );
  }, []);

  return (
    <div
      ref={ganttOuterRef}
      style={{
        position: "relative",
        height: `${ganttStyle.height}`,
        width: ganttStyle.width,
        margin: "auto",
      }}
    >
      {tasks && tasks.length > 0 ? (
        <>
          <div
            id="Gantt"
            style={{
              width: "100%",
              textAlign: "center",
              position: "relative",
              height: `calc(100% - ${legendHeight}px)`,
            }}
          >
            <Tasks
              ganttStyle={ganttStyle}
              tasksStyle={tasksStyle}
              filterStyle={filterStyle}
              nowStyle={nowStyle}
              legendStyle={legendStyle}
              endImageStyle={endImageStyle}
              columnStyle={columnStyle}
              dependenciesStyle={dependenciesStyle}
              expandFiltersStyle={expandFiltersStyle}
              functional={functional}
              tasks={tasks}
              calculatedStyle={calculatedStyle}
              ganttOuterRef={ganttOuterRef}
            />
          </div>

          <div
            id="legend"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              right: 0,
              alignItems: "center",
              verticalAlign: "middle",
              position: "absolute",
              //visibility: "hidden"
            }}
          >
            {tasksStyle.originalTasks ? (
              <>
                <div
                  ref={legendText1Ref}
                  style={{
                    fontFamily: legendStyle.legendFontStyle,
                    fontSize: legendStyle.legendFontSize,
                    color: legendStyle.legendTextColor,
                  }}
                >
                  {legendStyle.legendText1}
                </div>
                <div
                  style={{
                    height: legendStyle.legendCircleRadius,
                    width: legendStyle.legendCircleRadius,
                    background: tasksStyle.tasksColor.originalTime,
                    border: `${legendStyle.legendCircleStrockeWidth}px solid ${legendStyle.legendCircleStrockeColor} `,
                    marginRight:
                      legendStyle.legendCircleRadius / 2 + legendStyle.legendFontSize / 2,
                    marginLeft: legendStyle.legendCircleRadius / 2 + legendStyle.legendFontSize / 2,
                    borderRadius: "50%",
                  }}
                ></div>
              </>
            ) : (
              <></>
            )}
            {tasksStyle.effectiveTasks ? (
              <>
                <div
                  ref={legendText2Ref}
                  style={{
                    fontFamily: legendStyle.legendFontStyle,
                    fontSize: legendStyle.legendFontSize,
                    color: legendStyle.legendTextColor,
                  }}
                >
                  {legendStyle.legendText2}
                </div>
                <div
                  style={{
                    height: legendStyle.legendCircleRadius,
                    width: legendStyle.legendCircleRadius,
                    background: tasksStyle.tasksColor.effectiveTime,
                    border: `${legendStyle.legendCircleStrockeWidth}px solid ${legendStyle.legendCircleStrockeColor} `,
                    marginRight:
                      legendStyle.legendCircleRadius / 2 + legendStyle.legendFontSize / 2,
                    marginLeft: legendStyle.legendCircleRadius / 2 + legendStyle.legendFontSize / 2,
                    borderRadius: "50%",
                  }}
                ></div>
              </>
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Gantt;
