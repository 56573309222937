import { PageOptions } from "../types/api";
import {
  AssignmentCompositionCreateDTO,
} from "../types/assignment";
import api from "./api";

// Lists the students per assignment
export const listStudentsPerAssignment = (
  assignmentId: string,
  pageOptions?: PageOptions
) => {
  return api.get("/assignment-composition/students", {
    params: {
      ...pageOptions,
      assignmentId,
    },
  });
};

// Gets a single student per assignment
export const getSingleStudentPerAssignment = ({
  assignmentId,
  studentId,
}: {
  assignmentId: string;
  studentId: string;
}) => {
  return api.get("/assignment-composition/one-student", {
    params: {
      assignmentId,
      studentId,
    },
  });
};

// Creates a new assignment
export const createAssignmentComposition = (
  data: AssignmentCompositionCreateDTO
) => {
  return api.post("/assignment-composition", data);
};


export const setDelivered = async (assignId: string, studentId: string) => {
  return await api.put(`/assignment-composition/${assignId}/${studentId}`);
}


// Deletes every student in an assignment
export const deleteWholeAssignment = (data: { assignmentId: string }) => {
  return api.delete(`/assignment-composition/${data.assignmentId}`);
};

// Deletes a specific student from an assignment
export const deleteStudentFromAssignment = ({
  assignmentId,
  studentId,
}: {
  assignmentId: string;
  studentId: string;
}) => {
  return api.delete(`/assignment-composition/${assignmentId}/${studentId}`);
};
