import React, { useEffect, useRef, useState } from "react";
import {
  TaskDataProps,
  GanttStyleProps,
  FilterData,
  CalculatedStyleProps,
  GanttColumnProps,
  GanttDependenciesProps,
  GanttEndImageProps,
  GanttExpandFiltersProps,
  GanttFilterProps,
  GanttFunctionalProps,
  GanttLegendProps,
  GanttNowProps,
  GanttTasksProps,
  line,
} from "../../utils/types/types";
import { Checkbox, FormControl, ListItemText, MenuItem, Slider, styled } from "@mui/material";
import {
  calcRect,
  changeResolution,
  getActiveTasks,
  getLastEndIndex,
  getMaxWidth,
  getSenseTiming,
  getStatusWidth,
  getTextSize,
  handleChangeFilter,
  tasksToAssociativeArray,
  updateFilter,
  updateFw,
  valueLabelFormat,
} from "../../utils/functions/GeneralFunctions";
import {
  handleTaskClick,
  handleHoverTask,
  handleMouseOutTask,
  handleTaskColumnClick,
  handleHoverTaskColumn,
  handleMouseOutTaskColumn,
  handleHoverExpandFilter,
  handleMouseOutFilter,
  handleClickExpandFilter,
} from "../../utils/functions/EventListenersFunctions";
import io from "socket.io-client";
import { useParams } from "react-router-dom";
import { drawColumns } from "../../utils/functions/DrawColumnFunctions";
import { drawFilterText, drawColumnsHeadersText } from "../../utils/functions/DrawFilterFunctions";
import { drawStraightLine } from "../../utils/functions/DrawGeneralFunctions";
import {
  drawBottomBorderTask,
  drawFilterLine,
  drawTasks,
  drawNowLine,
} from "../../utils/functions/DrawGraphicFunctions";
import { drawNowTriangle } from "../../utils/functions/DrawNowFunctions";

import "./tasks.css";

var clickedOriginal: { [key: string]: boolean } = {};
var clickedEffective: { [key: string]: boolean } = {};
let socket: any = null;
const CustomSlider = styled(Slider)(
  (props: {
    thumbcolor?: string;
    trackcolor?: string;
    railcolor?: string;
    railwidth: string;
    height: number;
    thumbsize: number;
  }) => ({
    height: props.height,
    "& .MuiSlider-thumb": {
      backgroundColor: props.thumbcolor || "blue",
      height: props.thumbsize,
      width: props.thumbsize,
    },
    "& .MuiSlider-track": {
      border: "none",
      backgroundColor: props.trackcolor || "blue",
    },
    "& .MuiSlider-rail": {
      opacity: 1,
      backgroundColor: props.railcolor || "blue",
      width: `calc(${props.railwidth} + 20px)`,
    },
  }),
);

const Tasks = (props: {
  tasks: TaskDataProps[];
  ganttStyle: GanttStyleProps;
  tasksStyle: GanttTasksProps;
  filterStyle: GanttFilterProps;
  nowStyle: GanttNowProps;
  legendStyle: GanttLegendProps;
  endImageStyle: GanttEndImageProps;
  columnStyle: GanttColumnProps;
  dependenciesStyle: GanttDependenciesProps;
  expandFiltersStyle: GanttExpandFiltersProps;
  functional: GanttFunctionalProps;
  calculatedStyle: CalculatedStyleProps;
  ganttOuterRef: React.MutableRefObject<HTMLDivElement | null>;
}) => {
  // Initialize an object to store tasks by runbookId

  const {
    tasks,
    ganttStyle,
    tasksStyle,
    filterStyle,
    nowStyle,
    endImageStyle,
    columnStyle,
    dependenciesStyle,
    expandFiltersStyle,
    functional,
    calculatedStyle,
    ganttOuterRef,
  } = props;

  const { idMonitor } = useParams();

  const canvasFilterRef = useRef<HTMLCanvasElement | null>(null);
  const canvasColumnsRef = useRef<HTMLCanvasElement | null>(null);
  const canvasGraphicRef = useRef<HTMLCanvasElement | null>(null);
  const canvasNowRef = useRef<HTMLCanvasElement | null>(null);

  const graphContainerRef = useRef<HTMLDivElement | null>(null);
  const graphRef = useRef<HTMLDivElement | null>(null);
  const borderTopRef = useRef<HTMLDivElement | null>(null);
  const borderBottomRef = useRef<HTMLDivElement | null>(null);
  const borderRightRef = useRef<HTMLDivElement | null>(null);
  const expandTaskFilterRef = useRef<HTMLDivElement | null>(null);
  const expandStatusFilterRef = useRef<HTMLDivElement | null>(null);

  const [maxWidth, setMaxWidth] = useState<number>(0);
  const [statusWidth, setStatusWidth] = useState<number>(0);

  const [graphicWidth, setGraphicWidth] = useState<number>(0);

  const [allTasks, setAllTasks] = useState<TaskDataProps[]>(tasks);

  const [nowTextWidth, setNowTextWidth] = useState<number>(nowStyle.nowTriangleWidth / 2);
  const [nowTextHeigth, setNowTextHeigth] = useState<number>(0);
  const [tasksByRunbookId, setTasksByRunbookId] = useState<{ [key: string]: TaskDataProps }>(
    tasksToAssociativeArray(tasks),
  );
  const [activeTasks, setActiveTasks] = useState<TaskDataProps[]>(tasks);
  //const [activeTasksFilter, setActiveTasksFilter] = useState<TaskDataProps[]>(tasks);
  const [activeTasksFlags, setActiveTasksFlags] = useState<{ [key: string]: boolean }>(
    tasks.reduce((acc: { [key: string]: boolean }, task: TaskDataProps) => {
      acc[task.runbookId] = true;
      return acc;
    }, {}),
  );
  const [statusFlags, setStatusFlags] = useState<boolean[]>(
    Array(Object.keys(ganttStyle.status).length).fill(true),
  );
  const [cw, setCw] = useState<number>(ganttOuterRef.current?.offsetWidth || 0);
  const [columnsW, setColumnsW] = useState<number>(0);
  const [ch, setCh] = useState<number>(0);
  const [fw, setFw] = useState<string>("0" + "px");
  const [fx, setFx] = useState<string>("0" + "px");
  const [value1, setValue1] = React.useState<number[]>([0, filterStyle.filterMax]);
  const [now, setNow] = React.useState<number>(Date.now());
  const [originalRectangles, setOriginalRectangles] = React.useState<
    { id: string; x: number; y: number; width: number; height: number }[]
  >([]);
  const [effectiveRectangles, setEffectiveRectangles] = React.useState<
    { id: string; x: number; y: number; width: number; height: number }[]
  >([]);

  const [columnTasks, setColumnTasks] = React.useState<
    { id: string; x: number; y: number; width: number; height: number }[]
  >([]);

  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [image, setImage] = useState<HTMLImageElement>(new Image());

  const [filterTextHeight, setFilterTextHeight] = useState<number>(0);

  const [expandFilterTask, setExpandFilterTask] = useState<{
    x: number;
    y: number;
    height: number;
  }>({ x: 0, y: 0, height: 0 });
  const [expandFilterStatus, setExpandFilterStatus] = useState<{
    x: number;
    y: number;
    height: number;
  }>({ x: 0, y: 0, height: 0 });

  const [render, setRender] = useState<boolean>(false);

  const [scrollPosition, setScrollPosition] = useState<number>(0);

  //const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [filterData, setFilterData] = useState<FilterData>({
    start: new Date(0),
    end: new Date(now),
    actualStart: new Date(0),
    actualEnd: new Date(now),
    minRange: 10,
  });

  const drawColumnsCanvas = () => {
    const canvas = canvasColumnsRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    // for increasing resolution (DPR)
    const dpr = 2;

    //const height = ch;
    changeResolution(canvas, ctx, dpr, columnsW);
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.fillStyle = ganttStyle.bgColor1;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    canvas.height = ch;
    canvas.style.height = (ch / 2).toString() + "px";
    //console.log("c", ch);

    ctx.font = ganttStyle.fontSize + "px " + ganttStyle.fontStyle;

    //const graphicWidth = width - (maxWidth + (2 * columnStyle.distanceBetweenText) + statusWidth) - Math.max(endImageStyle.endImageWidth, Math.max(nowStyle.nowTriangleWidth, nowTextWidth) / 2);
    //const range = filterData.actualEnd.getTime() - filterData.actualStart.getTime();
    const lineX1 = maxWidth + columnStyle.distanceBetweenText;
    const lineX2 = lineX1 + columnStyle.distanceBetweenText + statusWidth; //text2Width;
    const c: { id: string; x: number; y: number; width: number; height: number }[] = drawColumns(
      canvas,
      ctx,
      ganttStyle,
      columnStyle,
      activeTasks,
      maxWidth,
      statusWidth,
    );
    setColumnTasks(c);
    ctx.setLineDash([]);

    // Draw vertical lines
    drawStraightLine(
      ctx,
      lineX1,
      0,
      activeTasks.length * ganttStyle.lineHeight,
      line.vertical,
      ganttStyle.lineWidth,
      ganttStyle.strokeColor,
    );
    drawStraightLine(
      ctx,
      lineX2 - ganttStyle.lineWidth / 2,
      0,
      activeTasks.length * ganttStyle.lineHeight,
      line.vertical,
      ganttStyle.lineWidth,
      ganttStyle.strokeColor,
    );
  };
  const drawGraphicCanvas = () => {
    //height={ch - (filterStyle.filterSliderDistance / 2 + filterStyle.filterGanttDistance / 2 + filterStyle.filterDateDistance / 2 + nowTextHeigth + nowStyle.nowTriangleHeight / 2 + calculatedStyle.legendHeight)}
    const canvas = canvasGraphicRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    // Clear the entire canvas
    //ctx.clearRect(0, 0, cw - columnsW, ch);
    // for increasing resolution (DPR)
    const dpr = 2;

    //const height = ch;
    const width = changeResolution(canvas, ctx, dpr, cw - columnsW);
    //ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.fillStyle = ganttStyle.bgColor1;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    canvas.height = ch;
    canvas.style.height = (ch / 2).toString() + "px";
    //                                                                                                     ("g", ch);

    ctx.font = ganttStyle.fontSize + "px " + ganttStyle.fontStyle;

    const graphicWidth =
      width -
      Math.max(endImageStyle.endImageWidth, Math.max(nowStyle.nowTriangleWidth, nowTextWidth) / 2);
    //(max/timing)<6
    //range
    drawBottomBorderTask(
      canvas,
      ctx,
      activeTasks,
      ganttStyle,
      endImageStyle,
      graphicWidth,
      nowTextWidth,
      nowStyle,
    );
    const timing = getSenseTiming(
      filterStyle.filterLineValues,
      filterStyle.filterLineN,
      filterData,
    );

    if (timing > 0)
      drawFilterLine(
        ctx,
        filterData,
        timing,
        activeTasks.length,
        columnStyle,
        ganttStyle,
        graphicWidth,
        maxWidth,
        statusWidth,
      );
    //console.log(clickedEffective);
    drawTasks(
      canvas,
      ctx,
      allTasks,
      width,
      filterData,
      tasksByRunbookId,
      activeTasks,
      ganttStyle,
      graphicWidth,
      tasksStyle,
      dependenciesStyle,
      clickedEffective,
      clickedOriginal,
      functional,
      endImageStyle,
      image,
      imageLoaded,
    );
    //setOriginalRectangles(vRectangles);
    // setEffectiveRectangles(rRectangles);

    drawNowLine(ctx, filterData, nowStyle, now, graphicWidth, ch);

    setGraphicWidth(graphicWidth);
  };
  const drawFilterCanvas = () => {
    const canvas = canvasFilterRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;
    //ctx.clearRect(0, 0, canvas.width, canvas.height)

    // for increasing resolution (DPR)
    const dpr = 2;

    //const height = ch;
    const width = changeResolution(canvas, ctx, dpr, cw);
    //ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.fillStyle = ganttStyle.bgColor1;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    //const graphicWidth = width - Math.max(endImageStyle.endImageWidth, Math.max(nowStyle.nowTriangleWidth, nowTextWidth) / 2) - (maxWidth + (2 * columnStyle.distanceBetweenText) + statusWidth);

    let sampleDate = "00/00/00";
    let sampleHour = "00:00";
    let sampleDateSize = getTextSize(
      ctx,
      sampleDate,
      filterStyle.filterFontSize + "px " + filterStyle.filterFontStyle,
    );
    let sampleHourSize = getTextSize(ctx, sampleHour);

    let filterHeight =
      filterStyle.filterDateDistance + sampleDateSize.textHeight + sampleHourSize.textHeight;
    setFilterTextHeight(filterHeight);

    const timing2 = getSenseTiming(
      filterStyle.filterLineValues,
      filterStyle.filterTextN,
      filterData,
    );
    if (timing2 > 0)
      drawFilterText(
        ctx,
        filterData,
        timing2,
        filterStyle,
        graphicWidth,
        maxWidth,
        columnStyle,
        statusWidth,
      );

    const { expandFilterTask, expandFilterStatus } = drawColumnsHeadersText(
      ctx,
      columnStyle,
      expandFiltersStyle,
      filterStyle,
      maxWidth,
      statusWidth,
    );
    setExpandFilterTask(expandFilterTask);
    setExpandFilterStatus(expandFilterStatus);
  };
  const drawNowCanvas = () => {
    const canvas = canvasNowRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    // for increasing resolution (DPR)
    const dpr = 2;

    // for increasing resolution (DPR)

    //const height = ch;
    /* const width = changeResolution(canvas, ctx, dpr, cw - columnsW);
         
             ctx.font = style.fontSize + 'px ' + style.fontStyle;
         
             const graphicWidth = width - Math.max(endImageStyle.endImageWidth, Math.max(nowStyle.nowTriangleWidth, nowTextWidth) / 2);
         
        */

    changeResolution(
      canvas,
      ctx,
      dpr,
      graphicWidth / 2 + Math.max(nowStyle.nowTriangleWidth, nowTextWidth),
    );

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.fillStyle = ganttStyle.bgColor1;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    drawNowTriangle(ctx, filterData, now, graphicWidth, nowStyle, nowTextWidth, nowTextHeigth);
  };

  //WEBSOCKET
  useEffect(() => {
    if (functional.socket) {
      socket = io(process.env.REACT_APP_WS_BASE_URL || "ws://localhost:3000");
      socket.on("connect", function () {
        // La connessione è stata aperta
        console.log("CONNESSO");
        //entrare nella room per leggere i dati del monitor
        socket.emit("joinRoom", idMonitor);
      });

      socket.on("dataUpdate", updateData);
      socket.on("disconnect", () => {
        console.log("A client disconnected");
      });
      return () => {
        socket.disconnect();
        //serve per resettare il chart altrimenti cambia id
        window.location.reload();

        //reset variable
        socket = null;
        window.location.reload();
      };
    }
    if(nowStyle.nowTime)
      setNow(nowStyle.nowTime);
    else
      setNow(Date.now());
  }, []);

  function updateData(data: any) {
    const temp: TaskDataProps[] = [];
    //console.log(tasksByRunbookId)
    data.forEach((element: any) => {
      const id = element?.idRunbookSpecifico;
      if (tasksByRunbookId[id]) {
        //console.log(tasksByRunbookId);
        const previsioneInizio = tasksByRunbookId[id].originalStart;
        const previsioneFine = tasksByRunbookId[id].originalEnd;

        const virtualInizio = new Date(element?.virtualInizio);
        const virtualFine = new Date(element?.virtualFine);

        const status = element?.status;

        const vincoli: string[] = [];
        element?.vincoli?.forEach((e: any) => {
          if (e.vincolo.idRunbookSpecifico) vincoli.push(e.vincolo.idRunbookSpecifico);
        });

        const criticalPath = true;

        temp.push({
          runbookId: id,
          originalStart: previsioneInizio,
          originalEnd: previsioneFine,
          effectiveStart: virtualInizio,
          effectiveEnd: virtualFine,
          status: status,
          dependencies: vincoli,
          criticalPath: criticalPath,
        });
      }
    });
    //console.log(temp);
    setAllTasks(temp);
    //console.log(temp);
  }

  useEffect(() => {
    setTasksByRunbookId(tasksToAssociativeArray(tasks));

    const canvas = canvasGraphicRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        let maxWidth = getMaxWidth(
          ctx,
          tasks,
          columnStyle.columnHeaderFontSize,
          columnStyle.columnHeaderFontStyle,
          expandFiltersStyle.expandFilterSize,
          expandFiltersStyle.expandFilterTextDistance,
          ganttStyle.fontSize,
          ganttStyle.fontStyle,
        );
        setMaxWidth(maxWidth);
        let statusWidth = getStatusWidth(
          ctx,
          tasks,
          columnStyle.columnHeaderFontSize,
          columnStyle.columnHeaderFontStyle,
          expandFiltersStyle.expandFilterSize,
          expandFiltersStyle.expandFilterTextDistance,
          ganttStyle.fontSize,
          ganttStyle.fontStyle,
          ganttStyle.status,
        );
        setStatusWidth(statusWidth);

        let nowText = "Now";
        let sizeNowText = getTextSize(
          ctx,
          nowText,
          nowStyle.nowFontSize + "px " + nowStyle.nowFontStyle,
        );
        setNowTextWidth(sizeNowText.textWidth);
        setNowTextHeigth(sizeNowText.textHeight);

        const lineX1 = maxWidth + columnStyle.distanceBetweenText;
        const lineX2 = lineX1 + columnStyle.distanceBetweenText + statusWidth; //text2Width;
        setColumnsW(lineX2 / 2);

        let acw = ganttOuterRef.current?.offsetWidth || 0;
        const { fw, fx } = updateFw(
          canvas,
          tasks,
          acw,
          ganttStyle,
          endImageStyle,
          columnStyle,
          expandFiltersStyle,
          nowStyle,
          nowTextWidth,
        );
        setFw(fw);
        setFx(fx);

        //const graphicWidth = (cw*2) - Math.max(endImageStyle.endImageWidth, Math.max(nowStyle.nowTriangleWidth, nowTextWidth) / 2);
        setFilterData(updateFilter(tasks, filterData, tasksStyle, now, graphicWidth));
      }
    }

    image.src = endImageStyle.endImageURL;
    image.onload = function () {
      setImageLoaded(true);
    };

    let activeTasks = getActiveTasks(
      tasks,
      activeTasksFlags,
      statusFlags,
      ganttStyle.status,
      filterData,
    );
    setActiveTasks(activeTasks.byIndex);
    setTasksByRunbookId(activeTasks.byRunbookId);
    let y = borderRightRef?.current?.getBoundingClientRect();
    if (y) {
      let nch = activeTasks.byIndex.length * ganttStyle.lineHeight;
      let ach = nch > (y?.height || 0) * 2 ? nch : (y?.height || 0) * 2 - ganttStyle.lineWidth / 2;
      let ch = ach;
    }
    setCh(ch);

    window.addEventListener("resize", () => {
      let acw = ganttOuterRef.current?.offsetWidth || 0;

      const canvas = canvasGraphicRef.current;
      if (!canvas) return;
      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      const { fw, fx } = updateFw(
        canvas,
        tasks,
        acw,
        ganttStyle,
        endImageStyle,
        columnStyle,
        expandFiltersStyle,
        nowStyle,
        nowTextWidth,
      );
      setFw(fw);
      setFx(fx);
      setCw(acw);

      let maxWidth = getMaxWidth(
        ctx,
        tasks,
        columnStyle.columnHeaderFontSize,
        columnStyle.columnHeaderFontStyle,
        expandFiltersStyle.expandFilterSize,
        expandFiltersStyle.expandFilterTextDistance,
        ganttStyle.fontSize,
        ganttStyle.fontStyle,
      );
      setMaxWidth(maxWidth);
      let statusWidth = getStatusWidth(
        ctx,
        tasks,
        columnStyle.columnHeaderFontSize,
        columnStyle.columnHeaderFontStyle,
        expandFiltersStyle.expandFilterSize,
        expandFiltersStyle.expandFilterTextDistance,
        ganttStyle.fontSize,
        ganttStyle.fontStyle,
        ganttStyle.status,
      );
      setStatusWidth(statusWidth);

      const lineX1 = maxWidth + columnStyle.distanceBetweenText;
      const lineX2 = lineX1 + columnStyle.distanceBetweenText + statusWidth; //text2Width;
      setColumnsW(lineX2 / 2);
    });
  }, []);
  graphRef.current?.addEventListener("scroll", () => {
    let scrollPosition = graphRef?.current?.scrollTop || 0;
    setScrollPosition(scrollPosition);
  });
  useEffect(() => {
    if (nowStyle.nowTimeout){
      setTimeout(() => {
        setNow(Date.now());
      }, nowStyle.nowTimeout);
    }
  }, [now]);
  useEffect(() => {
    const range = filterData.start.getTime() - filterData.end.getTime();
    setFilterData({
      ...filterData,
      actualStart: new Date(
        filterData.start.getTime() - value1[0] * (range / filterStyle.filterMax),
      ),
      actualEnd: new Date(
        filterData.end.getTime() +
          (filterStyle.filterMax - value1[1]) * (range / filterStyle.filterMax),
      ),
    });
    //setFilterData({ ...filterData, actualEnd: new Date(filterData.end.getTime() - (value1[1] / range)) });
  }, [value1]);
  useEffect(() => {
    setCw(ganttOuterRef.current?.offsetWidth || 0);
    //setFilterData({ ...filterData, actualEnd: new Date(filterData.end.getTime() - (value1[1] / range)) });
  }, [nowTextWidth]);
  useEffect(() => {
    setFilterData(updateFilter(allTasks, filterData, tasksStyle, now, graphicWidth));
  }, [now]);
  useEffect(() => {
    clickedEffective = allTasks.reduce((acc: { [key: string]: boolean }, task: TaskDataProps) => {
      acc[task.runbookId] = false;
      return acc;
    }, {});
    clickedOriginal = allTasks.reduce((acc: { [key: string]: boolean }, task: TaskDataProps) => {
      acc[task.runbookId] = false;
      return acc;
    }, {});
  }, [tasksStyle.effectiveTasks, tasksStyle.originalTasks]);
  useEffect(() => {
    const canvas = canvasGraphicRef.current;
    if (!canvas) return;
    const handleTaskClickAux = (e: Event) => {
      const { clickedEffectiveNew, clickedOriginalNew } = handleTaskClick(
        e,
        canvas,
        allTasks,
        tasksStyle,
        effectiveRectangles,
        originalRectangles,
        clickedEffective,
        clickedOriginal,
      );
      clickedEffective = clickedEffectiveNew;
      clickedOriginal = clickedOriginalNew;
      //console.log(clickedEffective);
      setRender((oldRender) => !oldRender);
    };
    const handleHoverTaskAux = (e: Event) => {
      handleHoverTask(e, canvas, tasksStyle, effectiveRectangles, originalRectangles);
    };
    const handleMouseOutTaskAux = (e: Event) => {
      handleMouseOutTask(canvas);
    };

    canvas.addEventListener("click", handleTaskClickAux);
    canvas.addEventListener("mousemove", handleHoverTaskAux);
    canvas.addEventListener("mouseout", handleMouseOutTaskAux);

    return () => {
      canvas.removeEventListener("click", handleTaskClickAux);
      canvas.removeEventListener("mousemove", handleHoverTaskAux);
      canvas.removeEventListener("mouseout", handleMouseOutTaskAux);
    };
  }, [effectiveRectangles, originalRectangles]);
  useEffect(() => {
    const canvas = canvasColumnsRef.current;
    if (!canvas) return;

    const handleTaskColumnClickAux = (e: Event) => {
      handleTaskColumnClick(e, canvas);
    };
    const setScrollPositionAux = (e: Event) => {
      setScrollPosition(
        handleHoverTaskColumn(
          e,
          canvas,
          filterStyle,
          columnTasks,
          filterTextHeight,
          functional,
          graphRef,
        ),
      );
    };
    const handleMouseOutTaskColumnAux = (e: Event) => {
      handleMouseOutTaskColumn(canvas, functional);
    };

    let elem = graphRef.current;
    canvas.addEventListener("click", handleTaskColumnClickAux);
    canvas.addEventListener("mousemove", setScrollPositionAux);
    elem &&
      elem.addEventListener("mouseout", (e) => {
        handleMouseOutTaskColumn(canvas, functional);
      });
    return () => {
      let elem = graphRef.current;
      canvas.removeEventListener("click", handleTaskColumnClickAux);
      canvas.removeEventListener("mousemove", setScrollPositionAux);
      elem && elem.removeEventListener("mouseout", handleMouseOutTaskColumnAux);
    };
  }, [columnTasks]);
  useEffect(() => {
    const canvas = canvasFilterRef.current;
    if (!canvas) return;

    const handleExpandFilterClick = (e: Event) => {
      handleClickExpandFilter(
        e,
        canvas,
        expandFilterTask,
        expandFilterStatus,
        expandFiltersStyle,
        expandTaskFilterRef,
        expandStatusFilterRef,
      );
    };

    const handleHoverExpandFilterAux = (e: Event) => {
      handleHoverExpandFilter(e, canvas, expandFilterTask, expandFilterStatus, expandFiltersStyle);
    };
    const handleMouseOutFilterAux = (e: Event) => {
      handleMouseOutFilter(canvas);
    };

    canvas.addEventListener("mousemove", handleHoverExpandFilterAux);
    canvas.addEventListener("mouseout", handleMouseOutFilterAux);
    canvas.addEventListener("click", handleExpandFilterClick);

    return () => {
      canvas.removeEventListener("mousemove", handleHoverExpandFilterAux);
      canvas.removeEventListener("mouseout", handleMouseOutFilterAux);
      canvas.removeEventListener("click", handleExpandFilterClick);
    };
  }, [activeTasks]);
  useEffect(() => {
    let y = borderRightRef.current?.getBoundingClientRect();
    let nch = activeTasks.length * ganttStyle.lineHeight;
    let ach = nch > (y?.height || 0) * 2 ? nch : (y?.height || 0) * 2 - ganttStyle.lineWidth / 2;
    let ch = ach;
    setCh(ch);
  }, [calculatedStyle, activeTasks]);
  useEffect(() => {
    drawGraphicCanvas();
  }, [
    ch,
    cw,
    columnsW,
    nowTextWidth,
    activeTasks,
    render,
    filterData,
    tasksByRunbookId,
    imageLoaded,
  ]);
  useEffect(() => {
    drawFilterCanvas();
  }, [cw, nowTextWidth, maxWidth, statusWidth, filterData, graphicWidth]);
  useEffect(() => {
    drawColumnsCanvas();
  }, [ch, graphicWidth, maxWidth, activeTasks, statusWidth, columnsW]);
  useEffect(() => {
    drawNowCanvas();
  }, [graphicWidth, nowTextWidth, filterData, nowTextHeigth]);
  useEffect(() => {
    let activeTasks = getActiveTasks(
      allTasks,
      activeTasksFlags,
      statusFlags,
      ganttStyle.status,
      filterData,
    );
    setActiveTasks(activeTasks.byIndex);
    setTasksByRunbookId(activeTasks.byRunbookId);
    //console.log(activeTasksFlags)
    //console.log(tasks);
    //console.log(tasks[0])
  }, [activeTasksFlags, statusFlags, filterData]);
  useEffect(() => {
    let newActiveTasksFlags = allTasks.reduce(
      (acc: { [key: string]: boolean }, task: TaskDataProps) => {
        acc[task.runbookId] = true;
        return acc;
      },
      {},
    );
    let newStatusFlags = Array(Object.keys(ganttStyle.status).length).fill(true);
    let newFilterData = updateFilter(allTasks, filterData, tasksStyle, now, graphicWidth);
  
    setActiveTasksFlags(newActiveTasksFlags);
    setStatusFlags(newStatusFlags);
    setFilterData(newFilterData);
  }, [allTasks, graphicWidth]);

  console.log(filterData)
  /*
      useEffect(() => {
          let activeTasks = getActiveTasks(tasks, activeTasksFlags, statusFlags, ganttStyle.status, filterData);;
          setActiveTasksFilter(activeTasks.filter);
      }, [filterData])*/
  useEffect(() => {
    setAllTasks(tasks);
  }, [tasks]);
  useEffect(() => {
    getLastEndIndex(
      tasksStyle.effectiveTasks,
      tasksStyle.originalTasks,
      allTasks,
      tasksByRunbookId,
      activeTasks,
    );
  }, [activeTasks, tasksByRunbookId, allTasks]);

  useEffect(() => {
    const { vRectangles, rRectangles } = calcRect(
      filterData,
      activeTasks,
      tasksStyle,
      graphicWidth,
      ganttStyle,
    );
    setOriginalRectangles(vRectangles);
    setEffectiveRectangles(rRectangles);
    //console.log(filterData)
  }, [filterData, activeTasks, tasksStyle, graphicWidth, ganttStyle]);
  useEffect(() => { console.log(graphicWidth) }, [graphicWidth]);
  return (
    <>
      {tasksStyle.effectiveTasks || tasksStyle.originalTasks ? (
        <>
          <div
            style={{
              position: "absolute",
              width: `calc(${fw} + ${filterStyle.filterHeight * 3 + 15}px)`,
              top: `0px`,
              right: `calc(${fx} - ${filterStyle.filterHeight * 3 + 15}px)`,
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <CustomSlider
              style={{ position: "relative", width: fw, top: 0, margin: "auto" }}
              value={value1}
              onChange={(e, value, index) => {
                setValue1(handleChangeFilter(e, value, index, filterData, value1));
              }}
              valueLabelDisplay="auto"
              disableSwap
              getAriaValueText={(value, index) => {
                return valueLabelFormat(value, index, filterData, filterStyle);
              }}
              valueLabelFormat={(value, index) => {
                return valueLabelFormat(value, index, filterData, filterStyle);
              }}
              max={filterStyle.filterMax}
              marks={filterStyle.filterMarks}
              step={filterStyle.filterStep}
              thumbcolor={filterStyle.filterThumbColor}
              trackcolor={filterStyle.filterTrackColor}
              railcolor={filterStyle.filterRailColor}
              railwidth={fw}
              height={filterStyle.filterHeight}
              thumbsize={filterStyle.filterThumbSize}
            />
            <div
              style={{
                position: "relative",
                width: 0,
                height: 0,
                marginLeft: 15,
                borderLeft: `${filterStyle.filterHeight * 3}px solid ${
                  filterStyle.filterRailColor || "blue"
                }`,
                borderTop: `${filterStyle.filterHeight * 2}px solid transparent`,
                borderBottom: `${filterStyle.filterHeight * 2}px solid transparent`,
                display: "inline-block",
                //zIndex: /* You can add the zIndex value here */
              }}
            ></div>
          </div>

          <div
            ref={graphContainerRef}
            style={{
              position: "relative",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              height: `calc(100% - ${
                filterStyle.filterHeight + 50 + filterStyle.filterHeight * 4
              }px`,
              minHeight:
                filterStyle.filterSliderDistance / 2 +
                filterStyle.filterGanttDistance / 2 +
                ganttStyle.lineHeight / 2,
              top: `${filterStyle.filterHeight + 50 + filterStyle.filterHeight * 4}px`,
            }}
          >
            <div
              ref={borderTopRef}
              style={{
                position: "absolute",
                width:
                  cw -
                  Math.max(
                    endImageStyle.endImageWidth,
                    Math.max(nowStyle.nowTriangleWidth, nowTextWidth) / 2,
                  ) /
                    2,
                borderTop: `${ganttStyle.lineWidth / 2}px solid ${ganttStyle.strokeColor}`,
                top: `${
                  filterStyle.filterSliderDistance / 2 +
                  filterStyle.filterGanttDistance / 2 +
                  filterStyle.filterDateDistance / 2 +
                  filterTextHeight / 2 -
                  ganttStyle.lineWidth / 2
                }px`,
                zIndex: "2",
                right: `${
                  Math.max(
                    endImageStyle.endImageWidth,
                    Math.max(nowStyle.nowTriangleWidth, nowTextWidth) / 2,
                  ) / 2
                }px`,
              }}
            />

            <div
              ref={borderBottomRef}
              style={{
                position: "absolute",
                width: cw - endImageStyle.endImageWidth / 2,
                borderTop: `${ganttStyle.lineWidth / 2}px solid ${ganttStyle.strokeColor}`,
                top: `max(calc(100% - ${
                  nowStyle.nowTriangleHeight / 2 +
                  nowTextHeigth / 2 +
                  nowStyle.nowTextMarginTop / 2 +
                  nowStyle.nowTextMarginBottom / 2 +
                  ganttStyle.lineWidth / 2
                }px), ${
                  filterStyle.filterSliderDistance / 2 +
                  filterStyle.filterGanttDistance / 2 +
                  filterTextHeight / 2 +
                  filterStyle.filterDateDistance / 2 +
                  ganttStyle.lineHeight / 2 -
                  ganttStyle.lineWidth / 2
                }px)`,
                //bottom: nowStyle.nowTriangleHeight / 2 + nowTextHeigth / 2,
                zIndex: "2",
                right: `${
                  Math.max(
                    endImageStyle.endImageWidth,
                    Math.max(nowStyle.nowTriangleWidth, nowTextWidth) / 2,
                  ) / 2
                }px`,
              }}
            />

            <div
              ref={borderRightRef}
              style={{
                position: "absolute",
                height: `calc(100% - ${
                  filterStyle.filterSliderDistance / 2 +
                  filterStyle.filterGanttDistance / 2 +
                  filterStyle.filterDateDistance / 2 +
                  filterTextHeight / 2 +
                  nowTextHeigth / 2 +
                  nowStyle.nowTriangleHeight / 2 +
                  nowStyle.nowTextMarginTop / 2 +
                  nowStyle.nowTextMarginBottom / 2
                }px`,
                minHeight: ganttStyle.lineHeight / 2,
                borderRight: `${ganttStyle.lineWidth / 2}px solid ${ganttStyle.strokeColor}`,
                top: `${
                  filterStyle.filterSliderDistance / 2 +
                  filterStyle.filterGanttDistance / 2 +
                  filterStyle.filterDateDistance / 2 +
                  filterTextHeight / 2
                }px`,
                zIndex: "2",
                right: `${
                  Math.max(
                    endImageStyle.endImageWidth,
                    Math.max(nowStyle.nowTriangleWidth, nowTextWidth) / 2,
                  ) / 2
                }px`,
              }}
            />

            <div
              style={{
                height: `calc(100% - ${
                  filterStyle.filterSliderDistance / 2 +
                  filterStyle.filterGanttDistance / 2 +
                  filterStyle.filterDateDistance / 2 +
                  filterTextHeight / 2 +
                  nowTextHeigth / 2 +
                  nowStyle.nowTriangleHeight / 2 +
                  nowStyle.nowTextMarginTop / 2 +
                  nowStyle.nowTextMarginBottom / 2
                }px`,
                minHeight: ganttStyle.lineHeight / 2,
                overflow: "auto",
                position: "absolute",
                top: `${
                  filterStyle.filterSliderDistance / 2 +
                  filterStyle.filterGanttDistance / 2 +
                  filterStyle.filterDateDistance / 2 +
                  filterTextHeight / 2
                }px`,
                //right: `${(100 -ganttStyle.width) / 2}%`,
                overscrollBehaviorY: "none",
                left: 0,
              }}
            >
              <div
                ref={expandTaskFilterRef}
                className={"startegois-gantt-hidden startegois-hide-scrollbar"}
                style={{
                  position: "relative",
                  background: ganttStyle.bgColor2,
                  textAlign: "center",
                  zIndex: 1,
                  left: 0,
                }}
              >
                <FormControl fullWidth style={{ height: "100%" }}>
                  {getActiveTasks(
                    allTasks,
                    activeTasksFlags,
                    statusFlags,
                    ganttStyle.status,
                    filterData,
                  ).filter.map((task) => (
                    <MenuItem key={task.runbookId} value={task.runbookId}>
                      <div style={{ position: "relative", display: "inline-block" }}>
                        <ListItemText primary={task.runbookId} />
                      </div>
                      <Checkbox
                        checked={activeTasksFlags[task.runbookId]}
                        onChange={() => {
                          setActiveTasksFlags((prevFlags: { [key: string]: boolean }) => {
                            const temp: { [key: string]: boolean } = prevFlags;
                            temp[task.runbookId] = !temp[task.runbookId];
                            return temp;
                          });
                        }}
                        style={{ position: "relative" }}
                      />
                    </MenuItem>
                  ))}
                </FormControl>
              </div>
            </div>

            <div
              style={{
                height: `calc(100% - ${
                  filterStyle.filterSliderDistance / 2 +
                  filterStyle.filterGanttDistance / 2 +
                  filterStyle.filterDateDistance / 2 +
                  filterTextHeight / 2 +
                  nowTextHeigth / 2 +
                  nowStyle.nowTriangleHeight / 2 +
                  nowStyle.nowTextMarginTop / 2 +
                  nowStyle.nowTextMarginBottom / 2
                }px`,
                minHeight: ganttStyle.lineHeight / 2,
                overflow: "auto",
                position: "absolute",
                top: `${
                  filterStyle.filterSliderDistance / 2 +
                  filterStyle.filterGanttDistance / 2 +
                  filterStyle.filterDateDistance / 2 +
                  filterTextHeight / 2
                }px`,
                //right: `${(100 -ganttStyle.width) / 2}%`,
                overscrollBehaviorY: "none",
                left: columnStyle.distanceBetweenText / 2 + maxWidth / 2,
              }}
            >
              <div
                ref={expandStatusFilterRef}
                className={"startegois-gantt-hidden startegois-hide-scrollbar"}
                style={{
                  position: "relative",
                  background: ganttStyle.bgColor2,
                  textAlign: "center",
                  zIndex: 1,
                  left: 0,
                }}
              >
                <FormControl fullWidth style={{ height: "100%" }}>
                  {Object.entries(ganttStyle.status).map(([statusType, { color }], index) => (
                    <MenuItem key={statusType} value={statusType}>
                      <div style={{ position: "relative", display: "inline-block" }}>
                        <ListItemText primary={statusType} />
                      </div>
                      <Checkbox
                        checked={statusFlags[index]}
                        onChange={() => {
                          setStatusFlags((prevFlags) => {
                            const temp = [...prevFlags];
                            temp[index] = !temp[index];
                            return temp;
                          });
                        }}
                        style={{ position: "relative" }}
                      />
                    </MenuItem>
                  ))}
                </FormControl>
              </div>
            </div>
            <div
              style={{
                width: `${cw}px`,
                height: `${
                  filterStyle.filterSliderDistance / 2 +
                  filterStyle.filterGanttDistance / 2 +
                  filterTextHeight / 2 +
                  filterStyle.filterDateDistance / 2
                }px`,
                position: "absolute",
                top: 0,
              }}
            >
              <canvas
                ref={canvasFilterRef}
                width={`${cw}px`}
                height={
                  filterStyle.filterSliderDistance +
                  filterStyle.filterGanttDistance +
                  filterStyle.filterDateDistance +
                  filterTextHeight
                }
              ></canvas>
            </div>

            {functional.bloccoRiquadri ? (
              <>
                <div
                  className="startegois-hide-scrollbar"
                  style={{
                    width: `${cw - columnsW}px`,
                    height: `calc(100% - ${
                      filterStyle.filterSliderDistance / 2 +
                      filterStyle.filterGanttDistance / 2 +
                      filterStyle.filterDateDistance / 2 +
                      filterTextHeight / 2 +
                      nowTextHeigth / 2 +
                      nowStyle.nowTriangleHeight / 2 +
                      nowStyle.nowTextMarginTop / 2 +
                      nowStyle.nowTextMarginBottom / 2
                    }px`,
                    minHeight: ganttStyle.lineHeight / 2,
                    overflow: "auto",
                    position: "absolute",
                    top: `${
                      filterStyle.filterSliderDistance / 2 +
                      filterStyle.filterGanttDistance / 2 +
                      filterStyle.filterDateDistance / 2 +
                      filterTextHeight / 2
                    }px`,
                    //right: `${(100 -ganttStyle.width) / 2}%`,
                    overscrollBehaviorY: "none",
                    right: 0,

                  }}

                >
                  <canvas
                    ref={canvasGraphicRef}
                    width={`${cw - columnsW}px`}
                    style={{ position: "absolute", left: 0 }}
                  ></canvas>
                </div>
                <div
                  className="startegois-hide-scrollbar"
                  ref={graphRef}
                  style={{
                    width: `${columnsW}px`,
                    height: `calc(100% - ${
                      filterStyle.filterSliderDistance / 2 +
                      filterStyle.filterGanttDistance / 2 +
                      filterStyle.filterDateDistance / 2 +
                      filterTextHeight / 2 +
                      nowTextHeigth / 2 +
                      nowStyle.nowTriangleHeight / 2 +
                      nowStyle.nowTextMarginTop / 2 +
                      nowStyle.nowTextMarginBottom / 2
                    }px`,
                    minHeight: ganttStyle.lineHeight / 2,
                    overflow: "auto",
                    position: "absolute",
                    top: `${
                      filterStyle.filterSliderDistance / 2 +
                      filterStyle.filterGanttDistance / 2 +
                      filterStyle.filterDateDistance / 2 +
                      filterTextHeight / 2
                    }px`,
                    //right: `${(100 -ganttStyle.width) / 2}%`,
                    overscrollBehaviorY: "none",
                    left: 0,
                  }}
                >
                  <canvas
                    ref={canvasColumnsRef}
                    width={columnsW}
                    style={{ position: "absolute", left: 0 }}
                  ></canvas>
                </div>
              </>
            ) : (
              <>
                <div
                  className="startegois-hide-scrollbar"
                  ref={graphRef}
                  style={{
                    width: `${cw}px`,
                    height: `calc(100% - ${
                      filterStyle.filterSliderDistance / 2 +
                      filterStyle.filterGanttDistance / 2 +
                      filterStyle.filterDateDistance / 2 +
                      filterTextHeight / 2 +
                      nowTextHeigth / 2 +
                      nowStyle.nowTriangleHeight / 2 +
                      nowStyle.nowTextMarginTop / 2 +
                      nowStyle.nowTextMarginBottom / 2
                    }px`,
                    minHeight: ganttStyle.lineHeight / 2,
                    overflow: "auto",
                    position: "absolute",
                    top: `${
                      filterStyle.filterSliderDistance / 2 +
                      filterStyle.filterGanttDistance / 2 +
                      filterStyle.filterDateDistance / 2 +
                      filterTextHeight / 2
                    }px`,
                    //right: `${(100 -ganttStyle.width) / 2}%`,
                    overscrollBehaviorY: "none",
                  }}
                >
                  <canvas
                    ref={canvasGraphicRef}
                    width={`${cw - columnsW}px`}
                    style={{ position: "absolute", left: columnsW, top: 0 }}
                  ></canvas>

                  <canvas
                    ref={canvasColumnsRef}
                    width={columnsW}
                    style={{
                      position: "absolute",
                      right: `calc(${cw - columnsW}px)`,
                      top: 0,
                    }}
                  ></canvas>
                </div>
              </>
            )}
            <div
              style={{
                position: "absolute",
                top: `max(calc(100% - ${
                  nowStyle.nowTriangleHeight / 2 +
                  nowTextHeigth / 2 +
                  nowStyle.nowTextMarginTop / 2 +
                  nowStyle.nowTextMarginBottom / 2
                }px), ${
                  filterStyle.filterSliderDistance / 2 +
                  filterStyle.filterGanttDistance / 2 +
                  filterTextHeight / 2 +
                  filterStyle.filterDateDistance / 2 +
                  ganttStyle.lineHeight / 2
                }px)`,
                //bottom: nowStyle.nowTriangleHeight / 2 + nowTextHeigth / 2,
                left: columnsW - Math.max(nowStyle.nowTriangleWidth / 2, nowTextWidth / 2) / 2,
                height: `${
                  nowStyle.nowTriangleHeight / 2 +
                  nowTextHeigth / 2 +
                  nowStyle.nowTextMarginTop / 2 +
                  nowStyle.nowTextMarginBottom / 2
                }px`,
                //width: "100%"
              }}
            >
              <canvas
                ref={canvasNowRef}
                height={`${
                  nowStyle.nowTriangleHeight +
                  nowTextHeigth +
                  nowStyle.nowTextMarginTop +
                  nowStyle.nowTextMarginBottom
                }px`}
                style={{ width: "100%" }}
              ></canvas>
            </div>
          </div>

          {
            //<canvas ref={canvasFilterRef} width={cw} height={ch} style={{}}></canvas>
            //
          }
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Tasks;
