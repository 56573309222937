import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AxiosComponent } from "./models/api";
import RoutesCustom from "./components/Routes/Routes";

import { Box, Button } from "@mui/material";
import { AuthProvider } from "./context/auth";

import Login from "./pages/Auth";
import CustomNavbar from "./components/NavBar/CustomNavbar";

import { ErrorBoundary } from "react-error-boundary";
import "./index.css";

const queryClient = new QueryClient();

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert" style={{ marginTop: "60px", marginLeft: "20px" }}>
      <p>Qualcosa è andato storto</p>
      <Button onClick={resetErrorBoundary} variant="outlined">
        Torna indietro
      </Button>
      <small>
        <pre>{error.message}</pre>
        <pre>{error.stack}</pre>
      </small>
    </div>
  );
};

function App() {
  return (
    <div className="App ">
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthProvider>
            <AxiosComponent />
            <Box sx={{ display: "flex" }}>
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => (window.location.href = "/")}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    width: { sm: "100%" },
                  }}
                  style={{
                    minHeight: "100vh",
                    border: "none",
                  }}
                >
                  <RoutesCustom />
                </Box>
              </ErrorBoundary>
            </Box>
          </AuthProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
}

export default App;
