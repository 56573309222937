import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputBase,
  Pagination,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import AssignmentTable from '../../components/Assigment/AssignmentTableProfessor';
import { AssignmentCompositionCreateDTO, AssignmentCreateDTO, AssignmentDTO, AssignmentUpdateDTO } from '../../types/assignment';
import { useContext, useEffect, useState } from 'react';
import { createAssignment, deleteAssignment, listAssignments, updateAssignment } from '../../models/assignment';
import { authContext } from '../../context/auth';
import { PATHS } from '../../utils/paths';
import { RoleNames } from '../../utils/roles';
import { useNavigate } from 'react-router-dom';
import { ClearIcon, DateField, DatePicker, DateTimePicker, TimeField } from '@mui/x-date-pickers';
import ClearCard from '../../components/Card/ClearCard';
import CustomTextField from '../../components/TextField/CustomTextField';
import { createStudent, listStudents, updateStudent } from '../../models/student';
import { UserUpdateDTO } from '../../types/user';
import { Student } from '../Students';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { createAssignmentComposition, deleteStudentFromAssignment, listStudentsPerAssignment } from '../../models/assignment_composition';
import dayjs from 'dayjs';


const AssignmentForm: React.FC<{ onSubmit: () => void, assignmentToEdit?: AssignmentDTO, professorId: string }> = ({ onSubmit, assignmentToEdit, professorId }) => {
  const [name, setName] = useState<string>(assignmentToEdit ? assignmentToEdit.name : '');
  const [description, setDescription] = useState<string>(assignmentToEdit ? assignmentToEdit.description : '');
  const [expiration, setExpiration] = useState<Date | undefined>(assignmentToEdit && assignmentToEdit.expiration ? new Date(assignmentToEdit.expiration) : undefined);
  const [focus, setFocus] = useState<boolean>(false);


  const [assignedStudents, setAssignedStudents] = useState<Student[]>([]);
  const [notAssignedStudents, setNotAssignedStudents] = useState<Student[]>([]);
  const [filteredAssignedStudents, setFilteredAssignedStudents] = useState<Student[]>([]);
  const [filteredNotAssignedStudents, setFilteredNotAssignedStudents] = useState<Student[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [toAssign, setToAssign] = useState<Student[]>([]);


  const { user } = useContext(authContext);


  const navigate = useNavigate();


  const handleAddAssignment = async () => {
    const newAssignment: AssignmentCreateDTO = {
      name: name,
      description: description,
      expiration: expiration ? expiration : undefined,
    };

    createAssignment(newAssignment)
      .then((e) => {
        const assId = e.data.data.id;
        toAssign.forEach(async (student) => {
          try{
            await createAssignmentComposition({ assignmentId: assId, studentId: student.id})
          }catch(e){
            console.log(e)
          }
        })
        onSubmit();
      })
      .catch((e) => {
        console.log(e);
      })
      
  }; const handleMakeAddAssignment = async () => {
    const newAssignment: AssignmentCreateDTO = {
      name: name,
      description: description,
      expiration: expiration ? expiration : undefined,
    };

    createAssignment(newAssignment)
      .then((e) => {
        const assId = e.data.data.id;
        toAssign.forEach(async (student) => {
          try {
            await createAssignmentComposition({ assignmentId: assId, studentId: student.id })
          } catch (e) {
            console.log(e)
          }
        })
        onSubmit();
        const pathRole = user?.role === RoleNames.PROFESSOR ? PATHS.professor : PATHS.admin;
        navigate(pathRole + PATHS.makeAssignment + "/" + assId)
      })
      .catch((e) => {
        console.log(e);
      })

  };
  const handleEditAssignment = async () => {
    if (!assignmentToEdit)
      return;
    const updatedAssignment: Partial<AssignmentUpdateDTO> = {
      name,
      description,
      expiration: expiration ? expiration : undefined,
    }
    try {
      await updateAssignment({
        id: assignmentToEdit?.id,
        updatedAssignment
      });
      onSubmit();
    } catch (e) {
      console.log(e);
    }
  };
  const handleEditMakeAssignment = async () => {
    if (!assignmentToEdit)
      return;
    const updatedAssignment: Partial<AssignmentUpdateDTO> = {
      name,
      description,
      expiration: expiration ? expiration : undefined,
    }
    try {
      await updateAssignment({
        id: assignmentToEdit?.id,
        updatedAssignment
      });
      onSubmit();
      const pathRole = user?.role === RoleNames.PROFESSOR ? PATHS.professor : PATHS.admin;
      navigate(pathRole + PATHS.makeAssignment + "/" + assignmentToEdit?.id)
    } catch (e) {
      console.log(e);
    }
  };
  const handleDeleteAssignment = async () => {
    assignmentToEdit && deleteAssignment(assignmentToEdit?.id)
      .then((e)=>{
        onSubmit();
      })
      .catch((e) => {
          onSubmit();
        console.log(e);
      })
  }
  const handleLoadData = async () => {
    if (assignmentToEdit) {
      try {
        const response1 = await listStudents();
        const response2 = await listStudentsPerAssignment(assignmentToEdit.id);// Extract assigned students from response2
        const assigned = response2.data.data.map((student: any) => ({ id: student.id, name: student.username }));

        // Extract all students from response1
        const all = response1.data.data.map((student: any) => ({ id: student.id, name: student.username }));

        // Filter out assigned students from notAssigned array
        const notAssigned = all.filter((student: any) => {
          // Check if the student is not present in the assigned array
          return !assigned.some((assignedStudent: any) => assignedStudent.id === student.id);
        });
        setAssignedStudents(assigned);
        setNotAssignedStudents(notAssigned);
      } catch (error) {
        console.error("Error fetching students in assignment add:", error);
      }
      
    }else{
      try {
        const response = await listStudents();
        setNotAssignedStudents(response.data.data.map((student: any) => ({ id: student.id, name: student.username })));
      } catch (error) {
        console.error("Error fetching students in assignment add:", error);
      }
    }
  };
  const addToAssigned = async (studentId: string, assignmentId: string) => {
    const assignmentComp: AssignmentCompositionCreateDTO = {
      studentId,
      assignmentId
    }
    createAssignmentComposition(assignmentComp)
    .then((response)=>{
    })
    .catch((e)=>{
      console.log(e);
    })
  }
  const removeStudentFromArray = (arr: Student[], element: Student) => {
    return arr.filter(item => item !== element);
  };
  
  useEffect(()=>{
    handleLoadData();
  }, []);

  useEffect(() => {
    if (assignmentToEdit && toAssign.length>0){
      addToAssigned(toAssign[toAssign.length - 1].id, assignmentToEdit.id)
    }
   }, [toAssign])
  useEffect(() => {
    setFilteredAssignedStudents(assignedStudents.filter(student =>
      student.name.toLowerCase().includes(searchTerm.toLowerCase())));
  }, [assignedStudents, searchTerm]);


  useEffect(() => {
    setFilteredNotAssignedStudents(notAssignedStudents.filter(student =>
      student.name.toLowerCase().includes(searchTerm.toLowerCase())));
  }, [notAssignedStudents, searchTerm]);

  return (
    <div className="w-[100%] relative text-center">
      <div className='absolute right-0'>
          <IconButton onClick={onSubmit}>
            <ClearIcon className='text-tecnologia-default' />
          </IconButton>
        </div>

        <div className="p-[40px]">
          <div className="mb-16">
            <input
              spellCheck="false"
              type="text"
              className={`text-edilizia-default font-bold border-b-2 text-center`}
              style={{
                width: "60%",
                fontSize: "30px",
                padding: "5px",
                outline: "2px solid transparent",
              }}
              onFocus={(e) => {
                e.target.style.outline = "hidden";
                e.target.style.borderColor = "black";
                setFocus(true);
              }}
              onBlur={(e) => {
                e.target.style.borderColor = "";
              }}
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
              placeholder={!focus ? "Inserisci il nome" : ""}
            />
          </div>
          <div>
            <label htmlFor="custeomTextArea" className='text-edilizia-default block'>
              Descrizione:
            </label>
            <textarea
              id="custeomTextArea"
              className='text-black outline-none bg-[rgba(0,0,0,0.05)] block m-auto'
              style={{
                width: "80%",
                fontSize: "16px",
                borderRadius: "5px",
                padding: "10px",
                height: "200px",
                resize: "none",
              }}
              onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                setDescription(e.target.value);
              }}
              value={description}
            />
          </div>
          <div>
            <div className='text-edilizia-default font-bold text-3xl mt-[100px] mb-[30px]'>
              Scadenza della consegna
          </div>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <DateTimePicker
              label="Data"
              defaultValue={expiration?dayjs(expiration):undefined} // Make sure expiration is a dayjs object
              views={['year', 'month', 'day', 'hours', 'minutes']}
              format="DD/MM/YYYY HH:mm" // Use formatString prop for custom formatting
              onChange={(e) => {
                e && setExpiration(new Date(e.toDate()));
              }}
            />
          </LocalizationProvider>
          </div>
          <div className='w-full my-[100px]'>
            <div className='text-edilizia-default font-bold text-3xl'>
              Assegna studenti
            </div>
            <InputBase
              className={`border-edilizia-default text-edilizia-default border-2 rounded-full my-4 w-[80%] m-auto px-8 py-1 shadow-lg block`}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Cerca"
              inputProps={{ 'aria-label': 'search' }}
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
          />
          </div>
          <div className='flex'>
            <div className=' sm:w-[100%] w-[100%]'>
              <div className='text-md font-bold text-tecnologia-default m-auto'>
                STUDENTI ASSEGNATI
              </div>
              <div className='m-auto text-center'>
                <div className='w-[60%] m-auto shadow-lg h-[50vh] overflow-scroll hide-scrollbar'>
                  {filteredAssignedStudents.map((student, index) => (
                    <div onClick={async () => {
                      if(assignmentToEdit){
                        try{
                          await deleteStudentFromAssignment({ assignmentId: assignmentToEdit.id, studentId: student.id });
                          setAssignedStudents(removeStudentFromArray(assignedStudents, student));
                          setNotAssignedStudents((oldNotAssign) => [...oldNotAssign, student]);
                        } catch (e) {
                          console.log(e)
                        }
                      }
                      else{
                        setAssignedStudents(removeStudentFromArray(assignedStudents, student));
                        setNotAssignedStudents((oldNotAssign) => [...oldNotAssign, student]);
                        setToAssign(removeStudentFromArray(toAssign, student));
                      }
                    }} className={`py-4 flex items-center hover:cursor-pointer hover:bg-[rgba(0,0,0,0.1)] text-center`} key={index}>
                        <div className='text-edilizia-default inline-block m-auto'>
                          {student.name}
                        </div>
                        <div className='flex items-center sm:mr-5'>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className='sm:w-[100%] w-[100%]'>
              <div className='text-md font-bold text-tecnologia-default m-auto'>
                STUDENTI NON ASSEGNATI
              </div>
              <div className='m-auto text-center'>
                <div className='w-[60%] m-auto shadow-lg h-[50vh] overflow-scroll hide-scrollbar'>

                  {filteredNotAssignedStudents.map((student, index) => (
                    <div onClick={async () => {
                      if (assignmentToEdit) {
                        try {
                          await createAssignmentComposition({ assignmentId: assignmentToEdit.id, studentId: student.id });
                          setNotAssignedStudents(removeStudentFromArray(notAssignedStudents, student));
                          setAssignedStudents((oldAssign) => [...oldAssign, student]);

                        } catch (e) {
                          console.log(e)
                        }
                      }else{
                        setNotAssignedStudents(removeStudentFromArray(notAssignedStudents, student));
                        setAssignedStudents((oldAssign) => [...oldAssign, student]);
                        setToAssign((oldToAssign) => [...oldToAssign, student]);
                      }
                    }} className={`py-4 flex items-center hover:cursor-pointer hover:bg-[rgba(0,0,0,0.1)] text-center`} key={index}>
                      <div className='text-edilizia-default inline-block m-auto'>
                        {student.name}
                      </div>
                      <div className='flex items-center sm:mr-5'>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
      </div>
      <div className="text-center p-4">
        <Button
          className="mx-2 bg-gradient-to-r from-accademia-default via-tecnologia-default to-edilizia-default text-white font-bold disabled:from-accademia-disabled disabled:via-tecnologia-disabled disabled:to-edilizia-disabled"
          size="medium"
          color="info"
          variant="contained"
          disabled={!assignmentToEdit && !(name !== "" && description !== "")}
          onClick={() => { assignmentToEdit ? handleEditMakeAssignment() : handleMakeAddAssignment() }}
        >
          {assignmentToEdit ? "Modifica e svolgi l'esercitazione" :"Crea e svolgi l'esercitazione"}
        </Button>
      </div>
        <div className="text-center p-4">
          <Button
            className="mx-2 bg-gradient-to-r from-accademia-default via-tecnologia-default to-edilizia-default text-white font-bold disabled:from-accademia-disabled disabled:via-tecnologia-disabled disabled:to-edilizia-disabled"
            size="medium"
            color="info"
            variant="contained"
            disabled={!assignmentToEdit && !(name !== "" && description !== "")}
            onClick={() => { assignmentToEdit ? handleEditAssignment() : handleAddAssignment() }}
          >
            {assignmentToEdit ? "Modifica" : "Crea"}
          </Button>
        {assignmentToEdit &&
          <Button
          className="mx-2 bg-[#ff1100] text-white font-bold disabled:from-accademia-disabled disabled:via-tecnologia-disabled disabled:to-edilizia-disabled"
            size="medium"
            color="info"
            variant="contained"
            disabled={!assignmentToEdit && !(name !== "" && description !== "")}
            onClick={() => { assignmentToEdit && handleDeleteAssignment() }}
          >
            Elimina
          </Button>}
        </div>
    </div>
  );
};

const HomePageProfessor = () => {
  const [assignments, setAssignments] = useState<AssignmentDTO[]>([]);
  const [addingAssignment, setAddingAssignment] = useState<boolean>(false);
  const [editingAssignment, setEditingAssignment] = useState<AssignmentDTO | undefined>();
  const [deletingAssignment, setDeletingAssignment] = useState<AssignmentDTO | undefined>();
  const { user } = useContext(authContext);
  const navigate = useNavigate();


  


  async function getAssignments(){
    try {
      const ass: any = await listAssignments();
      const assDTO: AssignmentDTO[] = [];
      ass.data.data.map((e: any)=>{
        assDTO.push({
          id: e.id,
          name: e.name,
          description: e.description,
          expiration: e.expiration,
          professorId: e.professorId,
          createdAt: e.createdAt,
        });
      });
      setAssignments(assDTO);
  }
  catch(e){
    console.log(e);
  }
  }

  useEffect(()=>{
    if(!user) {
      navigate(PATHS.login);
    } else if(user?.role === RoleNames.STUDENT) {
      navigate(PATHS.student + PATHS.home)
    }
    getAssignments();
  },[navigate, user]); //TODO: potrebbe creare danni
  return (
    <>
    
      {!user || !addingAssignment && <div>
        <div className='text-2xl font-bold text-tecnologia-default p-8 m-auto w-full'> 
          LISTA ESERCITAZIONI
        </div>
        <div className='mb-4'>
          <AssignmentTable assignments={assignments} setAssignment={setEditingAssignment} setEditing={setAddingAssignment} />
        </div>
        <Box className="text-center p-4">
          <Button
            className="bg-gradient-to-r from-accademia-default via-tecnologia-default to-edilizia-default text-white font-bold disabled:from-accademia-disabled disabled:via-tecnologia-disabled disabled:to-edilizia-disabled"
            onClick={()=>{
              setAddingAssignment(true);
            }}
            size="medium"
            color="info"
            variant="contained"
            sx={{ borderRadius: 0 }}
          >
            NUOVA ESERCITAZIONE
          </Button>
        </Box>

      </div>}


      {user && addingAssignment && (
          <AssignmentForm
            onSubmit={() => {
              getAssignments();
              setAddingAssignment(false);
              setEditingAssignment(undefined);
            }}
            assignmentToEdit={editingAssignment}
            professorId={user?.id}
          />
      )}
    </>
  );
};

export default HomePageProfessor;
