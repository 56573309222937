import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { updateTask } from '../../models/task';
import { TaskUpdateDTO } from '../../types/task';

interface TaxBoxProps {
  id: string;
  name: string;
  note?: string;
  show: boolean;
  x: number;
  y: number;
  allDataTasks: any;
  updateAllTasks: Function;
  edit: boolean;
}

const TooltipInformationGantt: React.FC<TaxBoxProps> = ({ id, name, note, show, x, y, allDataTasks, updateAllTasks, edit }) => {
  const [hovered, setHovered] = useState(false);
  const [editedNote, setEditedNote] = useState<string>(note || ""); // Initialize with note prop

  useEffect(() => {
    // Update editedNote only when note prop changes
    setEditedNote(note || "");
  }, [note]);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  const handleUploadNoteEdit = () => {
    const updatedTask: TaskUpdateDTO = { note: editedNote }
    updateTask(id, updatedTask)
      .then((e) => {
        // Find the index of the task with the specified taskId
        const taskIndex = allDataTasks.findIndex((task: any) => task.id === id);

        // If taskIndex is -1, the task with the specified id was not found
        if (taskIndex === -1) {
          console.error(`Task with id ${id} not found.`);
          return;
        }

        // Create a copy of the original tasks array
        const updatedTasks = [...allDataTasks];

        // Update the specified field of the task at the found index
        updatedTasks[taskIndex] = {
          ...updatedTasks[taskIndex],
          note: editedNote
        };

        // Update the state with the new array of tasks
        updateAllTasks(updatedTasks);
      })
      .catch((e)=>{
      })
  };

  const boxStyle: React.CSSProperties = {
    position: 'absolute',
    top: `${y}px`,
    left: `${x}px`,
    width: '250px', // Increased to accommodate the triangle
    height: edit?'200px':'150px',
    display: show || hovered ? 'block' : 'none',
    zIndex: 3,
  };

  return (
    <div
      style={boxStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className='bg-white border border-2 border-edilizia-default rounded-lg relative' // Add relative positioning
    >
      <div className='text-center text-tecnologia-default text-lg pb-1 font-bold'>{name}</div>
      <div className='pl-2 pb-1'>
        Note:
      </div>

      <textarea
        disabled={!edit}
        id="custeomTextArea"
        className='text-black outline-none bg-[rgba(0,0,0,0.05)] block m-auto'
        style={{
          width: "80%",
          fontSize: "16px",
          borderRadius: "5px",
          padding: "10px",
          resize: "none",
        }}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setEditedNote(e.target.value);
        }}
        value={editedNote}
      />

     {edit && 
     <div className='flex w-[100%]'>
        <Button
          className="top-4 m-auto text-sm bg-gradient-to-r from-accademia-default via-tecnologia-default to-edilizia-default text-white font-bold disabled:from-accademia-disabled disabled:via-tecnologia-disabled disabled:to-edilizia-disable"
          size="medium"
          color="info"
          variant="contained"
          onClick={() => {
            handleUploadNoteEdit();
          }}
          sx={{ borderRadius: 0 }}
        >
          Aggiorna Note
        </Button>
      </div>
      }
    </div>
  );
};

export default TooltipInformationGantt;
