import { image } from "html2canvas/dist/types/css/types/image";
import {
  FilterData,
  GanttColumnProps,
  GanttDependenciesProps,
  GanttEndImageProps,
  GanttExpandFiltersProps,
  GanttFilterProps,
  GanttFunctionalProps,
  GanttNowProps,
  GanttStyleProps,
  GanttTasksProps,
  TaskDataProps,
  status,
} from "../types/types";
import {
  getLastEndIndex,
  getTextSize,
  removeFromArray,
  calculateFillStyle,
  changeResolution,
  getSenseTiming,
} from "./GeneralFunctions";
import { Height, Task } from "@mui/icons-material";
import { drawText } from "./DrawGeneralFunctions";

/**
 * disegna il triangolo indica la data attuale
 * @return {void}
 * @param { CanvasRenderingContext2D } ctx - context 2d del canvas
 * @param { FilterData } filterData - dati attuali del filtro per data
 * @param { number } now - numero che indica in millisecondi la data attuale
 * @param { number } graphicWidth - larghezza del grafico
 * @param { GanttNowProps } nowStyle - stile della linea e del triangolo che indica la data attuale
 * @param { number } nowTextWidth - larghezza del testo "now"
 * @param { number } nowTextHeigth - altezza del testo "now"
 */
export function drawNowTriangle(
  ctx: CanvasRenderingContext2D,
  filterData: FilterData,
  now: number,
  graphicWidth: number,
  nowStyle: GanttNowProps,
  nowTextWidth: number,
  nowTextHeigth: number,
): void {
  let range = filterData.actualEnd.getTime() - filterData.actualStart.getTime();
  //ctx.lineWidth = ganttStyle.lineWidth; // Set line width
  if (
    (now - filterData.actualEnd.getTime()) / range <= 0 &&
    (now - filterData.actualStart.getTime()) / range >= 0
  ) {
    //&& (filterData.actualEnd.getTime() - now) > -300) {
    let x =
      ((now - filterData.actualStart.getTime()) / range) * graphicWidth +
      Math.max(nowStyle.nowTriangleWidth / 2, nowTextWidth / 2); //lineX2;
    ctx.setLineDash([]);
    //draw triangle/*
    ctx.beginPath();
    ctx.fillStyle = nowStyle.nowLineColor; // Set line color
    ctx.moveTo(x - nowStyle.nowTriangleWidth / 2, nowStyle.nowTriangleHeight);
    ctx.lineTo(x + nowStyle.nowTriangleWidth / 2, nowStyle.nowTriangleHeight);
    ctx.lineTo(x + nowStyle.nowLineWidth / 2, 0);
    ctx.lineTo(x - nowStyle.nowLineWidth / 2, 0);
    //ctx.lineTo(x, 0);
    ctx.fill();

    // Draw text fields

    drawText(
      ctx,
      "Now",
      x - nowTextWidth / 2,
      nowStyle.nowTriangleHeight + nowTextHeigth + nowStyle.nowTextMarginTop,
      nowStyle.nowFontSize + "px " + nowStyle.nowFontStyle,
      nowStyle.nowLineColor,
    );
  }
}
