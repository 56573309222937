import React, { useState, ChangeEvent, useEffect } from "react";
import { ExcellTaskDataProps } from "../../utils/types/types";
import * as XLSX from "xlsx";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { IconButton } from "@mui/material";

const ImportExcel = (props: { setReturn: Function, color: string }) => {
  const { setReturn, color } = props;
  const [excelData, setExcelData] = useState<any[] | null>(null);
  const [error, setErrorState] = useState(false);

  useEffect(() => {
    if (error) {
      // Show error popup
      alert("An error occurred while processing the file. Please try again.");
      setErrorState(false); // Reset the error state
    }
  }, [error]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorState(false); // Reset the error state on file change
    const file = e.target.files?.[0];

    var retAll: any;

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const data = new Uint8Array(event.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });

        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const jsonData: any[] = XLSX.utils.sheet_to_json(sheet);

        // Check if the file contains any data
        if (jsonData.length === 0 || !Object.values(jsonData[0]).some(value => value !== "")) {
          // Handle the case where the file is empty or all columns are empty strings
          setErrorState(true); // Set error state
          e.target.value = ''; // Reset the input element value
          return; // Exit the function
        }
        const ret: any[] = [];
        const runbookIds: Set<string> = new Set();
        const macroTasks: Set<string> = new Set();

        for (const data of jsonData) {
          const formatError = (cause: string) => {
            setErrorState(true); // Set error state
            console.error("format error: " + cause);
          };

          let temp: any = {
            runbookId: "",
            originalStart: new Date(0),
            originalEnd: new Date(0),
            effectiveStart: new Date(0),
            effectiveEnd: new Date(0),
            status: "",
            dependencies: "",
            note: "",
            macroTask: "",
          };

          if (data["runbookId"]) {
            temp.runbookId = data["runbookId"];
            if (runbookIds.has(temp.runbookId)) {
              setErrorState(true); // Set error state
              console.error("Duplicate runbookId found: " + temp.runbookId);
            }
            runbookIds.add(temp.runbookId);
          } else {
            //formatError("column runbookId not found");
          }

          if (data["macroTask"]) {
            temp.macroTask = data["macroTask"];
            macroTasks.add(temp.macroTask);
            if (runbookIds.has(temp.macroTask)) {
              setErrorState(true); // Set error state
              console.error("runbookId cannot be the same as macroTask: " + temp.macroTask);
            }
          }
          if (data["originalStart"])
            temp.originalStart = convertExcelDateToJSDate(data["originalStart"]);
          //else formatError("column originalStart not found");

          if (data["originalEnd"]) 
            temp.originalEnd = convertExcelDateToJSDate(data["originalEnd"]);
          //else formatError("column originalEnd not found");

          if (data["effectiveStart"])
            temp.effectiveStart = convertExcelDateToJSDate(data["effectiveStart"]);
          //else formatError("column effectiveStart not found");

          if (data["effectiveEnd"])
            temp.effectiveEnd = convertExcelDateToJSDate(data["effectiveEnd"]);
          //else formatError("column effectiveEnd not found");

          if (data["status"]) temp.status = data["status"];
          //else formatError("column status not found");

          if (data["dependencies"]) temp.dependencies = data["dependencies"];
          ////else formatError("column dependencies not found");

          if (data["note"]) temp.note = data["note"];
          ////else formatError("column note not found");

          let dependencies = temp?.dependencies ? temp?.dependencies?.split(",").map((dep: string) => dep.trim()) : [];


          let jsonTasks: any = {
            runbookId: temp.runbookId,
            originalStart: temp.originalStart,
            originalEnd: temp.originalEnd,
            effectiveStart: temp.effectiveStart,
            effectiveEnd: temp.effectiveEnd,
            status: temp.status,
            dependencies: dependencies,
            note: temp.note,
            macroTask: temp.macroTask,
          };
          ret.push(jsonTasks);

        }

        retAll = ret;
        setExcelData(ret); // Set excelData state
        setReturn(ret); // Set return state
      };

      reader.readAsArrayBuffer(file);
    }
    return retAll;
  };

  const convertExcelDateToJSDate = (excelDate: number): Date => {
    // Excel date serial number represents the number of days since January 1, 1900
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const excelStartDate = new Date(1899, 11, 30); // Adjusted start date
    const offsetDays = excelDate; // No need to subtract 1

    return new Date(excelStartDate.getTime() + offsetDays * millisecondsPerDay);
  };

  return (
    <div>
      <label className="" htmlFor="fileInput">
        <DriveFolderUploadOutlinedIcon style={{ cursor: "pointer", color: color }} />
      </label>

      <input
        style={{ display: "none" }}
        id="fileInput"
        type="file"
        accept=".xls, .xlsx"
        onChange={(e) => {
          try {
            const ret = handleFileChange(e);
            if (ret) {
              setExcelData(ret);
              setReturn(ret);
            }
          }
          catch (e) {
            setErrorState(true); // Set error state
          }
        }}
      />
    </div>
  );
};

export default ImportExcel;
